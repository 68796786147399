import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { PricesheetType } from "src/app/shared/Models/pricesheetType";

@Component({
    selector: 'edit-pricesheet',
    templateUrl: 'edit-pricesheet.dialog.html',
    styleUrls: ['edit-pricesheet.dialog.scss']
})
export class EditPricesheetDialog {
    name: string = '';
    saveBtnDisabled = true;
    dialogData: any;
    selectedPricesheet: any;
    pricesheetTypes: PricesheetType[] = [];
    chosenPricesheetType: any;
    constructor(@Inject(MAT_DIALOG_DATA) public dt: any, public dialogRef: MatDialogRef<EditPricesheetDialog>, private data: DataShareService) {
        this.selectedPricesheet = this.dt.pricesheet;
        this.pricesheetTypes = this.dt.pricesheetTypes;
        if (this.selectedPricesheet.fk_pricesheetTypeId) {
            this.chosenPricesheetType = this.pricesheetTypes.find(x => x.id == this.selectedPricesheet.fk_pricesheetTypeId);
        }

    }

    save() {
        if (this.chosenPricesheetType) {
            this.selectedPricesheet.fk_pricesheetTypeId = this.chosenPricesheetType.id;
        }
        this.dialogRef.close(this.selectedPricesheet);
    }

    close() {
        this.dialogRef.close();
    }


}
