import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ColliTypeService } from 'src/app/shared/api/collitype.service';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { ColliType, ColliTypeTranslate } from 'src/app/shared/Models/collitype';
import { Customer } from 'src/app/shared/Models/customer';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewColliTypeTranslateDialog } from './new-colli-type-translate-dialog/new-colli-type-translate.dialog';

@Component({
  selector: 'app-colli-type-translate',
  templateUrl: './colli-type-translate.component.html',
  styleUrls: ['./colli-type-translate.component.scss']
})
export class ColliTypeTranslateComponent implements OnInit {
  @Input() selectedCustomer?: Customer;
  collitypeTranslates: any[] = [];
  collitypeTranslatesDataSource!: MatTableDataSource<any>;
  colliTypes: ColliType[] = [];
  customers: Customer[] = [];
  loading: any;
  displayedColumns: string[] = ["collitype", "translate", "customer", "action"];
  selectedRow?: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private collitypeService: ColliTypeService, 
    private data: DataShareService, 
    private customerService: CustomerService, 
    private _snackBar: MatSnackBar, 
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCustomers();
    this.getAllColliTypes();
  }

  getAllColliTypeTranslates() {
    this.loading = true;
    let collitypeTranslate: ColliTypeTranslate = {
      licenseRef: this.data.LicenseRef,
      name: " ",
      includeColliType: true
    };

    if (this.selectedCustomer) {
      collitypeTranslate.customerRef = this.selectedCustomer.customerNr != null ? this.selectedCustomer.customerNr : this.selectedCustomer.customerexternRef;
      this.collitypeService.getAllColliTypeTranslateByCustomer(collitypeTranslate).then((res: any) => {
        if (res) {
          if (res.modelObject) {
            this.collitypeTranslates = res.modelObject;
          }
          else {
            this.collitypeTranslates = [];
          }
          this.modelCollitypeTranslate().then(() => {
            this.collitypeTranslates.unshift({ licenseRef: this.data.License, name: "", customerRef: this.selectedCustomer?.customerexternRef });
            this.collitypeTranslatesDataSource = new MatTableDataSource(this.collitypeTranslates);
            this.collitypeTranslatesDataSource.paginator = this.paginator;
          });
        }
      }).finally(() => this.loading = false);
    }
    else {
      this.collitypeService.getAllColliTypeTranslate(collitypeTranslate).then((res: any) => {
        if (res) {
          if (res.modelObject) {
            this.collitypeTranslates = res.modelObject;
          }
          else {
            this.collitypeTranslates = [];
          }
          this.modelCollitypeTranslate().then(() => {
            // this.collitypeTranslates.unshift({ licenseRef: this.data.License, name: "", customerRef: undefined });
            this.collitypeTranslatesDataSource = new MatTableDataSource(this.collitypeTranslates);
            this.collitypeTranslatesDataSource.paginator = this.paginator;
          });
        }
      }).finally(() => this.loading = false);
    }
  }

  async modelCollitypeTranslate() {
    if (this.collitypeTranslates && this.collitypeTranslates.length > 0) {
      await this.doDataModeling();
    }
  }
  doDataModeling() {
    this.collitypeTranslates.forEach(trans => {
      let fromType: ColliType = this.colliTypes.filter(x => x.ref == trans.colliTypeRef)[0];
      let toType: ColliType = this.colliTypes.filter(x => x.ref == trans.translateRef)[0];
      trans.remove = false;
      trans.editable = false;
      trans.editReady = false;
      trans.customerName = "";
      if (fromType) {
        trans.fromText = fromType.name;        
      }else{
        trans.remove = true;
      }
      if (toType) {
        trans.toText = toType.name;        
      }else{
        trans.remove = true;
      }
      if (trans.customerRef) {
        let cust: Customer = this.customers.filter(x => x.externalRef == trans.customerRef)[0];
        if (cust && cust.clientObject) {
          trans.customerName = cust.clientObject?.name;
        }
      };
    });
    let removes = this.collitypeTranslates.filter(x => x.remove == true);
    if (removes && removes.length > 0) {
      removes.forEach(rem => {
        let index = this.collitypeTranslates.indexOf(rem);
        if (index > -1) {
          this.collitypeTranslates.splice(index, 1);
        }
      });
    }
    if (this.collitypeTranslates && this.collitypeTranslates.length > 0) {
      this.collitypeTranslates.sort((a, b) => a.fromText! > b.fromText! ? 1 : a.fromText == b.fromText ? 0 : -1);
    }
  }
  newColliTypeTranslate() {

    const dialogRef = this.dialog.open(NewColliTypeTranslateDialog, { disableClose: true });

    dialogRef.afterClosed().subscribe((colliTypeTranslate: any) => {
      if (colliTypeTranslate) {
        colliTypeTranslate.licenseRef = this.data.LicenseRef;
        colliTypeTranslate.name = ' ';
        this.collitypeService.saveOrUpdate(colliTypeTranslate).then((res: any) => {
          if (res) {

          }
        }).finally(() => {
          this._snackBar.open("Gods Type Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
          this.getAllColliTypeTranslates();
        });
      }
    });
  }

  updateColliType(collitype: any) {
    if (collitype) {
      collitype.licenseRef = this.data.LicenseRef;
      if (collitype.colliTypeRef) {
        collitype.name = this.colliTypes.find(x => x.ref == collitype.colliTypeRef)?.name;
      }
      this.collitypeService.saveOrUpdate(collitype).then((res: any) => {
        if (res) {
          collitype.id = res.modelObject.id;
          this.getAllColliTypeTranslates();
        }
      }).finally(() => {
        this._snackBar.open("Colli Type Opdateret", "Success", { panelClass: ['snack-bar'] });
      });
    }
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate
        message: "Du er ved at gemme :  <b>Alle Colli Typer</b>" // Translate
      }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.collitypeTranslatesDataSource.data.length; i++) {
          if (!this.collitypeTranslatesDataSource.data[i].colliTypeRef && !this.collitypeTranslatesDataSource.data[i].translateRef) {
            if (!this.collitypeTranslatesDataSource.data[i].id) {
              this.collitypeTranslatesDataSource.data.splice(i, 1); --i;
            }
            continue;
          }

          if (this.collitypeTranslatesDataSource.data[i].colliTypeRef) {
            this.collitypeTranslatesDataSource.data[i].name = this.colliTypes.find(x => x.ref == this.collitypeTranslatesDataSource.data[i].colliTypeRef)?.name;
          }

          if (!this.collitypeTranslatesDataSource.data[i].id && this.collitypeTranslates[i].colliTypeRef && this.collitypeTranslates[i].translateRef) {
            this.updateColliType(this.collitypeTranslates[i]);
          }
        }
        this.collitypeService.bulkSaveOrUpdate(this.collitypeTranslatesDataSource.data).then((saved: any) => {
          this.getAllColliTypeTranslates();
        }).finally(() => {
          this.loading = false;
          this._snackBar.open("Alle Colli Oversættelser Gemt", "Success", { panelClass: ['snack-bar'] });
        });
      }

    });
  }

  checkData(colliType: any) {
    if (colliType.id > 0) {
      colliType.editable = true;
    }
  }


  getAllColliTypes() {
    let col: ColliType = {
      licenseRef: this.data.LicenseRef,
      name: ' '
    };
    this.collitypeService.getAllColliTypes(col).then((res: any) => {
      this.colliTypes = res.modelObject;
      if (this.colliTypes){
        this.colliTypes.sort((a, b) => a.name! > b.name! ? 1 : a.name == b.name ? 0 : -1);
      }
      this.getAllColliTypeTranslates();

    });
  }

  deleteColliType(collitype: any) {
    if (collitype) {
      this.collitypeService.delete(collitype).then((res: any) => {
        this.getAllColliTypeTranslates();
      })
    }
  }

  compareByOptionId(idFirst: any, idSecond: any) {
    return idFirst && idSecond && idFirst.id == idSecond.id;
  }

  compareByOptionRef(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst == refSecond;
  }

  getCustomers(): void {
    let customer: Customer;
    customer = {
      licenseRef: this.data.LicenseRef,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.customers = this.customers.filter((x: any) => x.name);
      this.customers.unshift({ name: 'Ingen valgt' }); // Translate
    });
  }

  rowClicked(row?: any) {
    if (row) {
      this.collitypeTranslates.forEach(trans => {
        trans.editReady = false;
      });
      row.editReady = true;
    }
  }

  handlePage(event?: any) {
    if (event) {
      this.collitypeTranslates.forEach(trans => {
        trans.editReady = false;
      });
    }
  }
}
