import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { ProductService } from "src/app/shared/api/product.service";
import { TerminalService } from "src/app/shared/api/terminal.service";
import { Omex, OmexProduct } from "src/app/shared/Models/omex";
import { Product } from "src/app/shared/Models/product";
import { Terminal } from "src/app/shared/Models/terminal";
import { ConfirmDialogComponent } from "src/app/shared/util/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'new-terminal-dialog',
    templateUrl: './new-omex.dialog.html',
    styleUrls: ['./new-omex.dialog.scss'],
})
export class NewOmexDialog {
    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <Omex>{};
    terminals: any;
    products: any;
    omexProducts: OmexProduct[] = [];
    saveResult: any;
    constructor(public dialogRef: MatDialogRef<NewOmexDialog>, private dialog: MatDialog, private _snackBar: MatSnackBar, private translateService: TranslateService, private productService: ProductService, private data: DataShareService, private terminalService: TerminalService) {
        this.getAllTerminals();
        this.getAllProducts();
        dialogRef.backdropClick().subscribe(() => {
            this.confirmDialog();
        });
    }

    save() {
        if (this.omexProducts.length > 0) {
            if (this.dialogData.terminal) {
                this.saveResult = this.dialogData;
                this.saveResult.omexProducts = this.omexProducts;
                if (!this.saveResult.pickup) this.saveResult.pickup = "";
                if (!this.saveResult.via) this.saveResult.via = "";
                if (!this.saveResult.delivery) this.saveResult.delivery = "";
                if (this.saveResult.pickup || this.saveResult.via || this.saveResult.delivery) {
                    this.dialogRef.close(this.saveResult);                    
                }else{
                    this._snackBar.open("Omex mangler enten Afhentning, Via eller Aflevering!", "Error", {duration: 1500, panelClass: ['snack-bar'] })
                }                
            }
        }
        else {
            if (!this.dialogData.pickup) this.dialogData.pickup = "";
            if (!this.dialogData.via) this.dialogData.via = "";
            if (!this.dialogData.delivery) this.dialogData.delivery = "";
            if (this.dialogData.pickup || this.dialogData.via || this.dialogData.delivery){
                this.dialogRef.close(this.dialogData);
            }else{
                this._snackBar.open("Omex mangler enten Afhentning, Via eller Aflevering!", "Error", {duration: 1500, panelClass: ['snack-bar'] })
            }
        }
    }
    selectProduct(product: Product) {
        if (!this.omexProducts?.find((x: any) => x.product == product) && !this.omexProducts?.find((x: any) => x.fk_productId == product.id)) {
            this.omexProducts?.push({
                fk_productId: product.id,
                licenseRef: this.data.LicenseRef,
                product: product,
            } as OmexProduct);
        }
        else {
            this._snackBar.open("Produktet er tilføjet", "Fejl", { duration: 1500, panelClass: ['snack-bar'] });
        }
    }

    compareByOptionId(idFirst: any, idSecond: any) {
        return idFirst && idSecond && idFirst.id == idSecond.id;
    }

    getAllProducts() {
        let product: Product = {
            licenseRef: this.data.LicenseRef,
        };
        this.productService.getAll(product).then((res: any) => {
            if (res) {
                this.products = res.modelObject;
            }
        });
    }

    getAllTerminals() {
        let terminal: Terminal = {
            licenseRef: this.data.LicenseRef
        };
        this.terminalService.getAll(terminal).then((res) => {
            if (res) {
                this.terminals = res.modelObject;
            }
        });
    }

    private confirmDialog() {
        if (this.dialogData.terminal || this.dialogData.pickup) {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant('CLOSEOMEXDIALOGTITLE'),
                    message: this.translateService.instant('FOUNDDATAERRORMESSAGE')
                }
            });

            confirmDialog.afterClosed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.dialogRef.close();
                }
            });
        }
        else { // Nothing Filled
            this.dialogRef.close();
        }
    }

    close() {
        this.confirmDialog();
    }

}
