import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Customer } from 'src/app/shared/Models/customer';
import { Rule } from 'src/app/shared/Models/rule';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { RulesService } from 'src/app/shared/api/rules.service';

@Component({
  selector: 'app-customerconversionrules',
  templateUrl: './customerconversionrules.component.html',
  styleUrls: ['./customerconversionrules.component.scss']
})
export class CustomerconversionrulesComponent implements OnInit {

  @Input() selectedCustomer?: Customer;

  constructor(
    public dataShare: DataShareService,
    private ruleService: RulesService,
    private snackBar: MatSnackBar,

  ) { }

  rule: Rule = {};

  ngOnInit(): void {
    if (this.selectedCustomer) {
      let dummy: Rule =  {licenseRef: this.dataShare.LicenseRef, fk_customerId: this.selectedCustomer.id};
      this.ruleService.getByCustomer(dummy).then(result => {
        if (result && result.modelObject) {
          let rules: Rule[] = result.modelObject;
          this.rule={licenseRef: this.dataShare.LicenseRef, fk_customerId: this.selectedCustomer?.id};
          if(rules.filter(x => x.fk_pricesheetId == null).length>0){
          this.rule = rules.filter(x => x.fk_pricesheetId == null)[0];
          }
        }
      });
    }
  }

  saveRule() {
    this.ruleService.saveOrUpdate(this.rule).then(result => {
      this.snackBar.open("Regelen er gemt", "Success", { duration: 1000 });
    });
  }
}
