<div style="width: 100%; margin:1%; display: flex; flex-direction: column; align-items: center;">
    <div fxLayout="row" style="max-width: 70vw;">
        <div style="display:flex; justify-content: space-between; margin:1%; align-items: center;">
            <h2 style="margin:0px;" class="header">{{'COLLITYPE' | translate}}</h2>
            <button mat-raised-button *ngIf="!selectedCustomer" style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newColliTypeTranslate()">Ny Gods type</button>
            <button mat-raised-button (click)="saveAll()"
                style="border-radius: 10px; background-color: var(--primary-blue); color: white;">
                {{'SAVEALL' | translate}}
            </button>
        </div>
        <div style="width: 100%;">
            <div style="border-radius: 6px;" class="mat-elevation-z2 table-responsive">
                <table style="width: 100%;" mat-table [dataSource]="collitypeTranslatesDataSource">

                    <ng-container matColumnDef="collitype">
                        <th class="centText" mat-header-cell *matHeaderCellDef>{{'COLLITYPE'|translate}}</th>
                        <td class="centText" mat-cell *matCellDef="let collitype">
                            <mat-form-field class="centText" style="width: 200px; margin-right: 50px;" floatLabel='never' *ngIf="collitype.editReady">
                                <mat-label>{{'COLLITYPE' | translate}}</mat-label>
                                <mat-select (ngModelChange)="checkData(collitype)" [(ngModel)]="collitype.colliTypeRef"
                                    name="collitype" [compareWith]="compareByOptionRef">
                                    <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                                        {{ct.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div style="width: 200px; margin-right: 50px;" *ngIf="!collitype.editReady">{{collitype.fromText}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="translate">
                        <th class="centText" mat-header-cell *matHeaderCellDef>{{'TRANSLATETO'|translate}}</th>
                        <td class="centText" mat-cell *matCellDef="let collitype">
                            <mat-form-field class="centText" style="width: 200px; margin-right: 50px;" floatLabel='never' *ngIf="collitype.editReady">
                                <mat-label>{{'TRANSLATESTO' | translate}}</mat-label>
                                <mat-select (ngModelChange)="checkData(collitype)" [(ngModel)]="collitype.translateRef"
                                    name="translate" [compareWith]="compareByOptionRef">
                                    <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                                        {{ct.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div style="width: 200px; margin-right: 50px;" *ngIf="!collitype.editReady">{{collitype.toText}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customer">
                        <th class="centText" mat-header-cell *matHeaderCellDef>{{'CUSTOMER'|translate}}
                        </th>
                        <td class="centText" mat-cell *matCellDef="let collitype">
                            <mat-form-field class="centText" style="width: 200px; margin-right: 50px;" *ngIf="collitype.editReady" floatLabel='never'>
                                <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                                <mat-select [disabled]="selectedCustomer != null" (ngModelChange)="checkData(collitype)" [(ngModel)]="collitype.customerRef"
                                    name="customer" [compareWith]="compareByOptionRef">
                                    <mat-option *ngFor="let cust of customers" [value]="cust.customerexternRef">
                                        {{cust.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div style="width: 200px; margin-right: 50px;" *ngIf="!collitype.editReady">{{collitype.customerName}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="centText" mat-header-cell *matHeaderCellDef></th>
                        <td class="centText" mat-cell *matCellDef="let collitype">
                            <div *ngIf="!collitype.id || collitype.editable">
                                <button matTooltip="Gem Gods type"
                                    style="background-color: var(--primary-blue); color:white;"
                                    mat-mini-fab (click)="updateColliType(collitype)">
                                    <mat-icon style="font-size: 22px;">save</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="collitype.id && !collitype.editable">
                                <button matTooltip="Slet Gods Type"  mat-mini-fab color="warn"
                                    (click)="deleteColliType(collitype)">
                                    <mat-icon style="font-size: 22px;">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr style="background-color:var(--primary-blue);" mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
                    <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;" (click)="rowClicked(row)"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 15, 20, 25]" (page)="handlePage($event)" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
            </div>
        </div>
    </div>
</div>
<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>