import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from '../shared/Models/client';
import { priceadjustment } from '../shared/Models/priceadjustment';
import { Pricesheet } from '../shared/Models/pricesheet';
import { PricesheetpricesService } from '../shared/api/pricesheetprices.service';
import { PricesheetPrice } from '../shared/Models/pricesheetPrice';
import { LicenseService } from '../shared/api/license.service';
import { DataShareService } from '../shared/api/datashare.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PricesheetzonesService } from '../shared/api/pricesheetzones.service';
import { PricesheetZone } from '../shared/Models/pricesheetZone';
import { PricesheetService } from '../shared/api/pricesheet.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductPrice } from '../shared/Models/productPrice';
import { ProductPriceService } from '../shared/api/productprice.service';

@Component({
  selector: 'app-postadjustment',
  templateUrl: './postadjustment.component.html',
  styleUrls: ['./postadjustment.component.scss']
})
export class PostadjustmentComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: priceadjustment[],
    public dialogRef: MatDialogRef<PostadjustmentComponent>,
    private snackbar: MatSnackBar,
    private pricesheetService: PricesheetService,
    public dataShare: DataShareService,
    private pricesheetPrice: PricesheetpricesService,
    private pricesheetZone: PricesheetzonesService,
    private productpriceService: ProductPriceService,
    private datePipe: DatePipe,
    private translate: TranslateService,

  ) { this.priceAdjustments = data; }

  priceAdjustments?: priceadjustment[];
  usingPricesheets: Pricesheet[] = [];
  allPriceshets: Pricesheet[] = [];
  allProductPrices: ProductPrice[] = [];
  hasClickedAdjust = false;

  ngOnInit() {
    this.dialogRef.updateSize('1600px', '900px');    
    if (this.priceAdjustments) {
      let customerIds: number[] = [];
      this.priceAdjustments.forEach(adjust => {
        if (adjust.clientEntity && adjust.clientEntity.peId){
          customerIds.push(adjust.clientEntity.peId);
        }
      });
      let promises: any[] = [];
      customerIds.forEach(customerId => {
        promises.push(
          this.productpriceService.getProductPricesByCustomerId({fk_customerId: customerId, licenseRef: this.dataShare.License.licenseRef}).then(response => {
            if (response && response.modelObject) {
              let prices: ProductPrice[] = response.modelObject;              
              let customerPrices = prices.filter(x => x.fk_customerId == customerId);
              if (customerPrices) {
                customerPrices.forEach(price => {
                  this.allProductPrices.push(price);
                });
              }
            }
          })
        );        
      });
      Promise.all(promises).finally(() => {
        this.gatherStatusInfo();
      });
    }
  }

  gatherStatusInfo() {
    if (this.priceAdjustments) {
      this.priceAdjustments.forEach(item => {
        item.errorMessage = [];
        item.adjustMessages = [];
      });
      this.priceAdjustments.forEach(item => {
        if (item.sheetsAffected && item.sheetsAffected.length > 0) {
          item.sheetsAffected.forEach(sheet => {
            let dmy: PricesheetPrice = {licenseRef: this.dataShare.License.licenseRef}
            this.pricesheetPrice.getPricesheetPrices(sheet.id!, dmy).toPromise().then(response => {
              if (response && response.modelObject) {
                this.controlValidDate(sheet, response.modelObject, item);
              }
            });
          });
        }
        if (item.hasOwnPricedProducts && item && item.clientEntity && item.clientEntity.adjustDate) {
          let hasPricesOnDate = this.allProductPrices.filter(x => this.datePipe.transform(x.validFrom, 'yyyy-MM-dd')! == this.datePipe.transform( item.clientEntity!.adjustDate, 'yyyy-MM-dd')!);
          if (hasPricesOnDate && hasPricesOnDate.length > 0){
            item.adjustMessages?.push({text: 'Produkter: ' + this.translate.instant('ADJUSTMENTOVERWRITTEN'), type: 0});
          }
        }
        if (item.clientEntity && item.clientEntity.peId) {
          let productPrices = this.allProductPrices.filter(x => x.fk_customerId);
          if (productPrices && productPrices.length > 0){
            item.hasOwnPricedProducts = true;
          }else{
            item.hasOwnPricedProducts = false;
          }
        }
      });
    }
  }

  getOKStatus(): boolean{
    let hasErrors = false;
    if (this.priceAdjustments) {
      this.priceAdjustments.forEach(item => {
        if (item.adjustMessages && item.adjustMessages.length > 0) {
          let withErrors = item.adjustMessages.filter(x => x.type == 1);
          if (withErrors && withErrors.length > 0) {
            hasErrors = true;            
          }
        }
      });
      return !hasErrors;
    }
    return false;
  }
  controlValidDate(sheet?: Pricesheet, prices?: PricesheetPrice[], adjustmentItem?: priceadjustment) {
    if (sheet && prices && sheet.doAdjust && adjustmentItem) {
      prices.sort((a, b) => a.validFrom! > b.validFrom! ? -1 : a.validFrom == b.validFrom ? 0 : 1);      
      if (prices.length == 0) {
        adjustmentItem.errorMessage?.push(sheet.name + ': ' + this.translate.instant('PRICESHEETHASNOPRICES'))
        adjustmentItem.adjustMessages?.push({text: sheet.name + ': ' +  this.translate.instant('PRICESHEETHASNOPRICES'), type: 1});
      }else{
        if ( this.datePipe.transform(prices[0].validFrom, 'yyyy-MM-dd')! > this.datePipe.transform(adjustmentItem.adjustmentDate, 'yyyy-MM-dd')!){
          adjustmentItem.errorMessage?.push(sheet.name + ': ' + this.translate.instant('ADJUSTMENTDATETOOLOW'))
          adjustmentItem.adjustMessages?.push({text: sheet.name + ': ' + this.translate.instant('ADJUSTMENTDATETOOLOW'), type: 1});
        }
        let pricesHasDate = prices.filter(x => this.datePipe.transform(x.validFrom, 'yyyy-MM-dd') == this.datePipe.transform(adjustmentItem.adjustmentDate, 'yyyy-MM-dd'));
        if (pricesHasDate && pricesHasDate.length > 0) {
          adjustmentItem.errorMessage?.push(sheet.name + ': ' + this.translate.instant('ADJUSTMENTDATEEQUALTOPRICEDATE'))
          adjustmentItem.adjustMessages?.push({text: sheet.name + ': ' + this.translate.instant('ADJUSTMENTOVERWRITTEN'), type: 0});
        }  

      }      
    }
  }

  clickClosePricesheet(adjustment?: priceadjustment, sheet?: Pricesheet) {
    if (sheet && adjustment) {
      sheet.doAdjust = false;
    }
    this.gatherStatusInfo();
  }

  clickOpenPricesheet(adjustment?: priceadjustment, sheet?: Pricesheet) {
    if (sheet && adjustment) {
      sheet.doAdjust = true;
    }
    this.gatherStatusInfo();
  }

  clickCancel() {
    this.dialogRef.close();
  }

  async clickJustify() {
    this.hasClickedAdjust = true;
    if (this.priceAdjustments) {
      this.dataShare.showSpinner = true;
      this.pricesheetService.savePriceAjustments(this.priceAdjustments).then((response: any) => {
        if (response && response.innerResults) {
          console.log("Result: ", response);
          let innerResults: any[] = response.innerResults;
          innerResults.forEach(result => {
            if (result.modelObject) {
              this.priceAdjustments!.forEach(adjustment => {
                let sheet = adjustment.sheetsAffected?.filter(x => x.id == result.modelObject.id)[0];
                if (sheet) {
                  if (result.status == 1) {
                    adjustment.saveStatus?.push({text: 'OK', type: 0});
                  }else{
                    adjustment.saveStatus?.push({text: 'FAIL', type: 1});
                  }
                }
                if (result && result.messageLines && result.messageLines.length > 0) {
                  if (result.messageLines[0] === 'PRODUCTPRICES'){
                    if (result.status == 1 ){
                      adjustment.saveStatus?.push({text: 'OK', type: 0});  
                    }else{
                      adjustment.saveStatus?.push({text: 'FAIL', type: 1});    
                    }
                  }
                }
              });
            }
          });
          this.dataShare.showSpinner = false;
        }
      });
    }
  }

  async saveAdjustPricesheets(sheets: Pricesheet[]) {
    sheets.forEach(sheet => {
      this.pricesheetService.savePricesheet(sheet).then(() => {

      });
    });
  }

  findActualDate(pricesheet: Pricesheet): string{
    pricesheet.pricesheetZones?.sort((a, b) => a.validFrom! > b.validFrom! ? -1 : a.validFrom == b.validFrom ? 0 : 1);
    let activeZones = pricesheet.pricesheetZones?.filter(x => this.datePipe.transform(x.validFrom, 'yyyy-MM-dd')! <= this.datePipe.transform(new Date, 'yyyy-MM-dd')!);
    if (activeZones && activeZones.length > 0) {
      return this.datePipe.transform(activeZones[0].validFrom,'yyyy-MM-dd')!.toString()
    }
    return '01-01-2000'
  }

  clickCloseProduct(adjustment?: priceadjustment) {
    if (adjustment) {
      adjustment.adjustPricedProducts = false;
      if (adjustment.adjustMessages) {
        let item = adjustment.adjustMessages.filter(x => x.text.includes('Produkt'))[0];
        if (item) {
          let idx = adjustment.adjustMessages.indexOf(item);
          if (idx || idx == 0) {
            adjustment.adjustMessages.splice(idx,1);
          }
        }          
      }
    }
  }

  clickOpenProduct(adjustment?: priceadjustment) {
    if (adjustment) {
      adjustment.adjustPricedProducts = true;
      this.gatherStatusInfo();
    }
  }
}
