import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { roadtax } from '../Models/roadtax';

@Injectable({
  providedIn: 'root'
})
export class RoadtaxapiService {

  constructor(
    private http: HttpClient,

  ) { }
  private API = environment.api_url;

  getAll(roadtax: roadtax): Promise<any>{
    return this.http.post(this.API + "/roadtax/getall", roadtax, this.getOptions()).toPromise();
  }

  delete(roadtax: roadtax): Promise<any>{
    return this.http.post(this.API + "/roadtax/delete", roadtax, this.getOptions()).toPromise();
  }

  saveorupdate(roadtax: roadtax): Promise<any>{
    return this.http.post(this.API + "/roadtax/saveorupdate", roadtax, this.getOptions()).toPromise();
  }

  saveOrUpdateAll(roadtaxes: roadtax[]): Promise<any>{
    return this.http.post(this.API + "/roadtax/saveorupdateall", roadtaxes, this.getOptions()).toPromise();
  }

  getOptions() {
    let httpOptions = {
      headers: new HttpHeaders({Accept: 'application/json', "Content-Type": "application/json", "Authorization": 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0'}), // Remove hardcoded license...
    };
    return httpOptions;
  }

}
