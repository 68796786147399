import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { PricesheetTypeService } from "src/app/shared/api/pricesheettype.service";
import { PricesheetType } from "src/app/shared/Models/pricesheetType";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'standard-pricesheet-dialog.html'
})
export class StandardPricesheetDialog implements OnInit {

    constructor(
        private translate: TranslateService,
        private datePipe: DatePipe, 
        public dialogRef: MatDialogRef<any>, 
        private pricesheetTypeService: PricesheetTypeService, 
        private data: DataShareService) {
        this.date = new Date();
        this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    }

    name: string = '';
    date: any;
    types: PricesheetType[] = [];
    selectedType?: PricesheetType;
    dialogData = <any>{};

    ngOnInit(): void {
        this.pricesheetTypeService.getAll({licenseRef: this.data.License.licenseRef}).then(response => {
            if (response && response.modelObject) {
                this.types = response.modelObject;
                this.types.forEach(type => {
                    type.name = this.translate.instant(type.name!.toUpperCase());
                });
            }
        });
    }

    save() {
        this.dialogData = { name: this.name, date: this.date, sheettype: this.selectedType };
        this.dialogRef.close(this.dialogData);
    }

    close() {
        this.dialogRef.close();
    }


}

export interface DialogDataNew {
    name: string;
    date: Date;
    type: PricesheetType
}