import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Product } from '../shared/Models/product';
import { DataShareService } from '../shared/api/datashare.service';
import { ProductService } from '../shared/api/product.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProductUnit } from '../shared/Models/productUnit';
import { ProductUnitService } from '../shared/api/productunit.service';
import { ProductPriceService } from '../shared/api/productprice.service';
import { ProductPrice } from '../shared/Models/productPrice';
import { CustomerService } from '../shared/api/customer.service';
import { Customer } from '../shared/Models/customer';
import { DatePipe } from '@angular/common';
import { ClientshareService } from '../shared/api/clientshare.service';
import { Client } from '../shared/Models/client';
import { CurrencyService } from '../shared/api/currency.service';
import { Currency } from '../shared/Models/currency';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToolsutilService } from '../shared/util/toolsutil.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-standardproductsnew',
  templateUrl: './standardproductsnew.component.html',
  styleUrls: ['./standardproductsnew.component.scss']
})
export class StandardproductsnewComponent implements OnInit {
  @ViewChild('tableProduct') tableProduct!: MatTable<any>;
  @ViewChild('tablePrice') tablePrice!: MatTable<any>;
  @ViewChild('paginatorProduct') paginatorProduct!: MatPaginator;
  @ViewChild('paginatorPrice') paginatorPrice!: MatPaginator;
  @ViewChild("name") nameControl!: ElementRef;
  @ViewChild("number") numberControl!: ElementRef;

  constructor(
    public clientShare: ClientshareService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dataShare: DataShareService,
    private productApi: ProductService,
    private productunitApi: ProductUnitService,
    private productpriceApi: ProductPriceService,
    private customerApi: CustomerService,
    private currencyApi: CurrencyService,
    private tools: ToolsutilService,

  ) { }

  SNACK_BAR_DURATION: number = 2000;
  UNDEFINED_ACTION: undefined;
  productDataSource!: MatTableDataSource<Product>;
  productPriceDataSource!: MatTableDataSource<ProductPrice>;
  productColumns: string[] = ["name", "number", "productunit", "oil", "enviroment", "capacity", "freightLine", "copyalways","recalconrerun", "action"];
  priceColumns: string[] = ["customernumber", "customer", "intervalfrom", "intervalto", "valuta", "price", "percent", "minprice", "validfrom", "validto", "action"];
  selectedDate: any;
  pricesheetDates: string[] = [];
  products: Product[] = [];
  loading: boolean = false;
  productUnits: ProductUnit[] = [];
  productPrices: ProductPrice[] = [];
  customers: Customer[] = [];
  currencies: Currency[] = [];
  customControl = new FormControl();
  selectedProduct?: Product;
  autoSetDates = true;
  actualPriceView: ProductPrice[] = [];
  filterValue = "";
  inputFilter = "";
  inputFilterPrice = "";

  ngOnInit(): void {
    this.clientShare.initialize().then(() => {
      this.getAllProductUnits();
      this.getAllClients();
      this.getAllCurrencies();
      this.getAllProducts();
    });
  }

  compareByOptionId(idFist: any, idSecond: any) {
    return idFist && idSecond && idFist.id == idSecond.id;
  }

  getPriceHeader(std: string): string{
    if (this.selectedProduct) {
      return std + " - (" + this.selectedProduct.name + ")";
    }
    return std;
  }
  openSnackBar(name: string) {
    this.snackBar.open("Du skal udfylde " + name + "!", this.UNDEFINED_ACTION, { duration: this.SNACK_BAR_DURATION, panelClass: ['snack-bar'] }); // Translate
  }

  filteredCustomers(): Customer[]{
    let result: Customer[] = [];
    if (this.customers && this.customers.length > 0) {
      let search = this.customControl.value;
      if (search && search.length>0) {
        search = search.toLowerCase();
        this.customers.forEach(customer => {
          if (customer.clientObject) {
            let ok = false;
            if (customer.clientObject.number && customer.clientObject.number.includes(search)) {
              ok = true;
            }
            if (customer.clientObject.name && customer.clientObject.name.toLowerCase().includes(search)) {
              ok = true;
            }
            if (customer.clientObject.nickname && customer.clientObject.nickname.toLowerCase().includes(search)) {
              ok = true;
            }
            if (ok) {
              result.push(customer);
            }
          }
        });
      }
    }
    return result;
  }
  getAllProductUnits() {
    let dummy: ProductUnit = {licenseRef: this.dataShare.LicenseRef};
    this.productunitApi.getAll(dummy).then((res: any) => {
      if (res && res.modelObject) {
        this.productUnits = res.modelObject;
        this.productUnits.sort((a, b) => a.name! > b.name! ? 1 : a.name == b.name ? 0 : -1);
      }
    });
  }

  getAllClients() {
    this.loading = true;
    let dummy: Customer = {licenseRef: this.dataShare.LicenseRef};
    this.customerApi.getCustomers(dummy).then((res: any) => {
      if (res && res.modelObject) {
        this.customers = res.modelObject;
        this.customers.sort((a, b) => (a.clientObject!.name+"").toLowerCase()! >  (b.clientObject!.name+"").toLowerCase()! ? 1 
        : (a.clientObject!.name+"").toLowerCase() == (b.clientObject!.name+"").toLowerCase() ? 0 : -1);
      }
      this.loading = false;
    });
  }

  getAllCurrencies() {
    let dummy: Currency = {licenseRef: this.dataShare.LicenseRef};
    this.currencyApi.getCurrencies(dummy).then((res: any) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a, b) => a.isocode! > b.isocode! ? 1 : a.isocode == b.isocode ? 0 : -1);
    });
  }

  chooseDate(date: any) {
    this.autoSetDates = false;
    this.selectedDate = date;
    if (this.selectedProduct) {
      this.buildPriceDataSource(this.selectedProduct.id!);
    }
  }

  selectCustomer(productPrice?: ProductPrice, customer?: Customer): void{
    if (productPrice && customer) {
      productPrice.customer = customer;
      productPrice.fk_customerId = customer.id;
    }
  }

  getAllProducts() {
    this.loading = true;
    let dummy: Product = {licenseRef: this.dataShare.LicenseRef, includeProductUnit: true }
    this.productApi.getAll(dummy).then((res: any) => {
      if (res && res.modelObject){
        this.products = res.modelObject;
        this.products.sort((a, b) => a.name! > b.name! ? 1 : a.name == b.name ? 0 : -1 );
        this.buildProductDataSource();
      }
      this.loading = false;
    });
  }

  buildProductDataSource() {
    this.productDataSource = new MatTableDataSource(undefined);
    this.products.forEach(product => {
      let ok = false;
      if (this.inputFilter.length > 0) {
        let s = this.inputFilter.toLowerCase();
        if (product.name && product.name.toLowerCase().includes(s)) {
          ok = true;
        }
      }else{
        ok = true;
      }
      if (ok) {
        this.productDataSource.data.push({
          id: product.id,
          name: product.name,
          licenseRef: this.dataShare.LicenseRef,
          productUnit: product.productUnit,
          erpCode: product.erpCode,
          excludeCapacityTariff: product.excludeCapacityTariff,
          excludeEnvironmentalTariff: product.excludeEnvironmentalTariff,
          excludeOilTariff: product.excludeOilTariff,
          calcFromFreightLine: product.calcFromFreightLine,
          copyAlways: product.copyAlways,
          recalcOnRerun: product.recalcOnRerun,
        })  
      }
    });
    this.productDataSource.data.unshift({licenseRef: this.dataShare.LicenseRef});
    this.productDataSource.paginator = this.paginatorProduct;
  }

  async getAllProductPrices(id?: number) {
    if (id) {
      let dummy: ProductPrice = {fk_productId: id, licenseRef: this.dataShare.LicenseRef};
      await this.productpriceApi.getProductPricesByProductId(dummy).then((res: any) => {
        if (res && res.modelObject) {
          this.productPrices = res.modelObject;
          this.productPrices.sort((a, b) => a.validFrom! > b.validFrom! ? -1 : a.validFrom == b.validFrom ? 0 : 1);
          if (this.autoSetDates) {
            this.pricesheetDates = [];
            this.productPrices.forEach(price => {
              if (!this.pricesheetDates.includes(this.datePipe.transform(price.validFrom!, 'yyyy/MM/dd')!)) {
                this.pricesheetDates.push(this.datePipe.transform(price.validFrom!, 'yyyy/MM/dd')!);
              }
            });              
            if (this.pricesheetDates && this.pricesheetDates.length > 0) {
              let today = this.datePipe.transform(new Date(), 'yyyy/MM/dd');
              for (let i = 0; i < this.pricesheetDates.length; i++) {
                const dat = this.datePipe.transform(this.pricesheetDates[i], 'yyyy/MM/dd');
                if ( dat! <= today!){
                  this.selectedDate = this.pricesheetDates[i];
                  break;
                }
              }
            }  
          }
        }else{
          this.productPrices = [];
          this.pricesheetDates = [];
        }
      });
    }
  }

  checkData(product?: Product) {
    if (product && product.id) {
      if (product.id > 0) {
        product.editable = true;
      }
    }
  }

  checkPriceData(productPrice?: ProductPrice) {
    if (productPrice && productPrice.id) {
      if (productPrice.id > 0) {
        productPrice.editable = true;
      }
    }
  }
  checkPriceDateData(productPrice?: ProductPrice, event?: any) {
    if (productPrice && productPrice.id) {
      if (productPrice.id > 0) {
        if (event) {
          let date: Date = new Date(event);
          if (date instanceof Date) {
            productPrice.validFrom = date;
          }
        }
        productPrice.editable = true;
      }
    }
  }
  checkPriceDateData2(productPrice?: ProductPrice, event?: any) {
    if (productPrice && productPrice.id) {
      if (productPrice.id > 0) {
        if (event) {
          let date: Date = new Date(event);
          if (date instanceof Date) {
            productPrice.validTo = date;
          }
        }
        productPrice.editable = true;
      }
    }
  }
  buildPriceViewDataSource() {
    if (this.actualPriceView && this.actualPriceView.length > 0) {
      this.productPriceDataSource = new MatTableDataSource(undefined);
      this.loading = true;
      this.actualPriceView.forEach(price => {
        let ok = false;
        if (this.inputFilterPrice.length > 0) {
          let s = this.inputFilterPrice.toLowerCase();
          if (price.fk_customerId) {
            let cust = this.GetCustomer(price.fk_customerId);
            if (cust) {
              if (cust.clientObject){
                if (cust.clientObject.number && cust.clientObject.number.toLowerCase().includes(s)) {
                  ok = true;
                }
                if (cust.clientObject.name && cust.clientObject.name.toLowerCase().includes(s)){
                  ok = true;
                }
                if (cust.clientObject.nickname && cust.clientObject.nickname.toLowerCase().includes(s)){
                  ok = true;
                }
              }
            }
          }
        }else{
          ok = true;
        }
        if (ok) {
          this.productPriceDataSource.data.push({
            amount: price.amount,
            customer: this.GetCustomer(price.fk_customerId!),
            currency: this.GetCurrency(price.fk_currencyId!),
            fk_productId: price.fk_productId,
            fk_currencyId: price.fk_currencyId,
            fk_customerId: price.fk_customerId,
            id: price.id,
            intervalfrom: price.intervalfrom,
            intervalto: price.intervalto,
            licenseRef: price.licenseRef,
            minPrice: price.minPrice,
            percent: price.percent,
            price: price.price,
            validFrom: price.validFrom,
            validTo: price.validTo,
            editable: false,
          })
        }
      });
      this.productPriceDataSource.data.unshift({licenseRef: this.dataShare.LicenseRef, 
        customer: {name: "", number: "", externalRef: "", 
        clientObject: {name: "", number: "", externalRef: "", address: "", apiActive: false, apiKey: "", calcColliesIfZeroldm: false, calcColliesIfZeroVol: false,
        calcLdm: false, calcVolumePKK: false, city: "", collicorrectionMail: "", country: "", created: "", damageMail: "", ddCorrections: false,
        deleted: false, deliveryMail: false, deliveryName: "", deliverySMS: false, doNotCalcCollies: false, doNotChangeColliType: false,
        doNotSendSMSEmail: false, doNotUsePriceCode: false, emailTemplate: "", enternalDatakey: "", erpId: 0,exportDistance: false,
        fk_addressId: 0, fk_customerparentId: 0, fk_deliveryzoneId: 0, fk_pickupzoneId: 0, id: 0, internalNote: "", jointTarrifs: false, 
        licenseRef:  this.dataShare.LicenseRef, locked: false, lockedEDI: false, logo: "", mainCustomer: false, nickname: "", obCustomerNumber: "",
        palletexchangeMail: "", remarks: "", serialNumber: 0, smsStartDate: "", smsTemplate: "", taxNumber: "", updated: "", 
        useCustomeraddressForPickups: false, zip: ""} }
      });
      this.productPriceDataSource.paginator = this.paginatorPrice;
      this.loading = false;
    }
  }
  buildPriceDataSource(id?: number) {
    this.loading = true;
    if (!id) return;
    this.getAllProductPrices(id).then(() => {
      if (this.productPrices ) {
        this.productPriceDataSource = new MatTableDataSource(undefined);
        this.actualPriceView = this.productPrices.filter(x => this.datePipe.transform( x.validFrom!,'yyyy/MM/dd') == this.selectedDate);        
        
        this.actualPriceView.forEach(price => {
          this.productPriceDataSource.data.push({
            amount: price.amount,
            customer: this.GetCustomer(price.fk_customerId!),
            currency: this.GetCurrency(price.fk_currencyId!),
            fk_productId: price.fk_productId,
            fk_currencyId: price.fk_currencyId,
            fk_customerId: price.fk_customerId,
            id: price.id,
            intervalfrom: price.intervalfrom,
            intervalto: price.intervalto,
            licenseRef: price.licenseRef,
            minPrice: price.minPrice,
            percent: price.percent,
            price: price.price,
            validFrom: price.validFrom,
            validTo: price.validTo,
            editable: false,
          })
        });
        this.productPriceDataSource.data.unshift({licenseRef: this.dataShare.LicenseRef, 
          customer: {name: "", number: "", externalRef: "", 
          clientObject: {name: "", number: "", externalRef: "", address: "", apiActive: false, apiKey: "", calcColliesIfZeroldm: false, calcColliesIfZeroVol: false,
          calcLdm: false, calcVolumePKK: false, city: "", collicorrectionMail: "", country: "", created: "", damageMail: "", ddCorrections: false,
          deleted: false, deliveryMail: false, deliveryName: "", deliverySMS: false, doNotCalcCollies: false, doNotChangeColliType: false,
          doNotSendSMSEmail: false, doNotUsePriceCode: false, emailTemplate: "", enternalDatakey: "", erpId: 0,exportDistance: false,
          fk_addressId: 0, fk_customerparentId: 0, fk_deliveryzoneId: 0, fk_pickupzoneId: 0, id: 0, internalNote: "", jointTarrifs: false, 
          licenseRef:  this.dataShare.LicenseRef, locked: false, lockedEDI: false, logo: "", mainCustomer: false, nickname: "", obCustomerNumber: "",
          palletexchangeMail: "", remarks: "", serialNumber: 0, smsStartDate: "", smsTemplate: "", taxNumber: "", updated: "", 
          useCustomeraddressForPickups: false, zip: ""} }
        });
        this.productPriceDataSource.paginator = this.paginatorPrice;
        
        //this.tablePrice.renderRows();
      }
    }).finally(() => {
      this.loading = false;
    });

  }

  productRowClicked(row?: any) {
    this.autoSetDates = true;
    this.actualPriceView = [];
    if (row) {
      if (row.id) {
        // See product details.
        let product = this.products.filter(x => x.id == row.id)[0];
        if (product){
          this.selectedProduct = product;
        }
        this.inputFilterPrice = "";
        this.buildPriceDataSource(row.id);
      }else{
        // Attempting on creating a new product
      }
    }
  }

  priceRowClicked(row?: any){
    if (row) {
      if (!row.id) {
        if (!row.validFrom) {
          row.validFrom = this.selectedDate;
        }
      }
    }
  }

  GetCustomer(id?: number): Customer{
    if (id) {
      let customer = this.customers.filter(x => x.id == id)[0];
      if (customer) {
        return customer;
      }
    }
    return{
      clientObject: {name: "", number: "", externalRef: "", address: "", apiActive: false, apiKey: "00000000-0000-0000-0000-000000000000", calcColliesIfZeroldm: false, calcColliesIfZeroVol: false,
      calcLdm: false, calcVolumePKK: false, city: "", collicorrectionMail: "", country: "", created: "", damageMail: "", ddCorrections: false,
      deleted: false, deliveryMail: false, deliveryName: "", deliverySMS: false, doNotCalcCollies: false, doNotChangeColliType: false,
      doNotSendSMSEmail: false, doNotUsePriceCode: false, emailTemplate: "", enternalDatakey: "", erpId: 0,exportDistance: false,
      fk_addressId: 0, fk_customerparentId: 0, fk_deliveryzoneId: 0, fk_pickupzoneId: 0, id: 0, internalNote: "", jointTarrifs: false, 
      licenseRef:  this.dataShare.LicenseRef, locked: false, lockedEDI: false, logo: "", mainCustomer: false, nickname: "", obCustomerNumber: "",
      palletexchangeMail: "", remarks: "", serialNumber: 0, smsStartDate: "", smsTemplate: "", taxNumber: "", updated: "", 
      useCustomeraddressForPickups: false, zip: ""}
    }
  }
  
  GetCurrency(id?: number): Currency{
    if (id) {
      let cur = this.currencies.filter(x => x.id == id)[0];
      if (cur) {
        return cur;
      }
    }
    return {name: ""}
  }

  hasMultiplePriceUpdates(): boolean{
    if (this.productPriceDataSource && this.productPriceDataSource.data && this.productPriceDataSource.data.length > 0) {
      let edited = this.productPriceDataSource.data.filter(x => x.editable == true);
      if (edited && edited.length > 1) {
        return true;
      }
    }
    return false;
  }

  getCustomerId(externRef?: string): number{
    let c = this.customers.filter(x => x.customerexternRef == externRef)[0];
    if (c) {
      return c.id!;
    }
    return -1;
  }
  GetCustomerClient(id?: number): Client{
    if (id) {
      let item = this.customers.filter(x => x.id == id)[0];
      if (item && item.clientObject) {
        let client = this.clientShare.clients.filter(x => x.id === item.clientObject?.id)[0];
        if (client) {
          return client;
        }
      }
    }
    return {name: "", number: "", externalRef: "", address: "", apiActive: false, apiKey: "", calcColliesIfZeroldm: false, calcColliesIfZeroVol: false,
            calcLdm: false, calcVolumePKK: false, city: "", collicorrectionMail: "", country: "", created: "", damageMail: "", ddCorrections: false,
            deleted: false, deliveryMail: false, deliveryName: "", deliverySMS: false, doNotCalcCollies: false, doNotChangeColliType: false,
            doNotSendSMSEmail: false, doNotUsePriceCode: false, emailTemplate: "", enternalDatakey: "", erpId: 0,exportDistance: false,
            fk_addressId: 0, fk_customerparentId: 0, fk_deliveryzoneId: 0, fk_pickupzoneId: 0, id: 0, internalNote: "", jointTarrifs: false, 
            licenseRef:  this.dataShare.LicenseRef, locked: false, lockedEDI: false, logo: "", mainCustomer: false, nickname: "", obCustomerNumber: "",
            palletexchangeMail: "", remarks: "", serialNumber: 0, smsStartDate: "", smsTemplate: "", taxNumber: "", updated: "", 
            useCustomeraddressForPickups: false, zip: ""};
  }

  updateProduct(product?: Product) {
    if (product) {
      this.productApi.update(product).then(() => {
        this.getAllProducts();
      });
    }
  }
  updateProductPrice(productPrice: ProductPrice) {
    this.productpriceApi.save(productPrice).then(() => {
      this.autoSetDates = true;
      this.buildPriceDataSource(productPrice.fk_productId);
    });
  }
  createProduct(product?: Product) {
    if (product) {
      this.productApi.create(product).then(() => {
        this.getAllProducts();
      });
    }
  }
  createProductPrice(productPrice: ProductPrice) {
    if (productPrice.customer && productPrice.customer.clientObject) {
      let p: ProductPrice = { fk_currencyId: productPrice.currency?.id, fk_customerId: this.getCustomerId(productPrice.customer.clientObject.externalRef!),
            fk_productId: productPrice.fk_productId, intervalfrom: productPrice.intervalfrom, intervalto: productPrice.intervalto, licenseRef: productPrice.licenseRef,
            minPrice: productPrice.minPrice, percent: productPrice.percent, price: productPrice.price, validFrom: productPrice.validFrom, 
            validTo: productPrice.validTo}
      if (p.fk_customerId == -1) p.fk_customerId = null;
      this.productpriceApi.create(p).then(() => {
        this.autoSetDates = true;
        this.buildPriceDataSource(productPrice.fk_productId);
      });
    }else{

    }
  }
  deleteProduct(product?: Product) {
    // This is only marking the product as deleted.
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {width: '280px', height: '170px', data: {title: 'Er du sikker?', message: 'Du er ved at slette et produkt.'}});
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        if (product && product.id && product.licenseRef) {
          this.productApi.markDeleted(product.id, product.licenseRef).then(() => {
            this.getAllProducts();
            this.productPriceDataSource = new MatTableDataSource(undefined);
            this.pricesheetDates = [];
            this.selectedDate = "";
            this.selectedProduct = undefined;
          });
        }            
      }
    });
  }

  deletePrice(productPrice?: ProductPrice) {
    // This is only marking the productprice as deleted.
    if (productPrice && productPrice.id && productPrice.licenseRef) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {width: "280px",height: "170px",data: {title: "Er du sikker?", message: "Du er ved at slette et produkts pris."}});
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.productpriceApi.markDeleted(productPrice.id!, this.dataShare.LicenseRef).then(() => {
            this.autoSetDates = true;
            this.buildPriceDataSource(productPrice.fk_productId);      
          });
        }
      });
    }
  }
  applyProduct(product?: Product) {
        // Check Fields ↓
        if (!product?.name) {
          this.openSnackBar("et Navn"); // Translate
          this.nameControl.nativeElement.focus();
          return;
        }
        // if (!productPrice.product?.erpCode) {
        //   this.openSnackBar("ERP Kode"); // Translate
        //   this.numberControl.nativeElement.focus();
        //   return;
        // }
        if (!product?.productUnit) {
          this.openSnackBar("Produkt Enhed"); // Translate
          return;
        }    
        if (product.productUnit){
          product.fk_productUnitID = product.productUnit.id!;
        }
        if (!product.id) {
          this.createProduct(product);
        }
        else if (product.id) {
          this.updateProduct(product);
        }    
  }
  applyPrice(productPrice?: ProductPrice) {
    if (!productPrice || !this.selectedProduct) return;
    productPrice.fk_productId = this.selectedProduct.id!;
    // Check for valid data.
    if (!productPrice.currency) {
      this.openSnackBar("Vælg Valuta"); // Translate
      return
    }else{
      productPrice.fk_currencyId = productPrice.currency.id;
    }
    if (productPrice.id && productPrice.id > 0) {
      this.updateProductPrice(productPrice);
    }else if (!productPrice.id){
      this.createProductPrice(productPrice);
    }
  }
  saveAllPrices() {
    if (this.productPriceDataSource && this.productPriceDataSource.data) {
      let rows = this.productPriceDataSource.data.filter(x => x.editable == true);
      if (rows && rows.length > 0) {
        let prices: ProductPrice[] = rows;
        this.productpriceApi.saveAll(prices).then(() => {
          this.autoSetDates = true;
          this.buildPriceDataSource(prices[0].fk_productId);    
        });
      }
    }
  }

  filterChanged() {    
    this.buildProductDataSource();
  }
  filterPriceChanged() {
    if (this.inputFilterPrice.length == 0 || this.inputFilterPrice.length > 2)
    this.buildPriceViewDataSource();
  }
  clearFilter() {
    this.inputFilter = "";
    this.buildProductDataSource();
  }
  clearFilterPrice() {
    this.inputFilterPrice = "";
    this.buildPriceViewDataSource();
  }
}
