import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "src/app/shared/api/customer.service";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { Customer } from "src/app/shared/Models/customer";
import { ConfirmDialogComponent } from "src/app/shared/util/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'new-colli-type-dialog',
    templateUrl: './new-environmental-tax.dialog.html',
    styleUrls: ['./new-environmental-tax.dialog.scss'],
})
export class NewEnvironmentalTaxDialog {
    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <any>{};
    colliTypes: any;
    customers: any;
    constructor(
        public dialogRef: MatDialogRef<NewEnvironmentalTaxDialog>, 
        private customerService: CustomerService, 
        private translateService: TranslateService, 
        private dialog: MatDialog, 
        private data: DataShareService
    ) 
    {
        this.getCustomers();
        dialogRef.backdropClick().subscribe(() => {
            this.confirmDialog();
        });
    }

    save() {
        if (this.dialogData.colliType) {
            this.dialogData.name = this.dialogData.colliType.name;
        }
        this.dialogRef.close(this.dialogData);
    }

    compareByOptionRef(refirst: any, refSecond: any) {
        return refirst && refSecond && refirst == refSecond;
    }

    compareByOptionId(refirst: any, refSecond: any) {
        return refirst && refSecond && refirst.id == refSecond.id;
    }


    confirmDialog() {
        if (this.dialogData.fk_customerId || this.dialogData.date || this.dialogData.procent) {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant('CLOSEENVIRONMENTALDIALOGTITLE'),
                    message: this.translateService.instant('FOUNDDATAERRORMESSAGE')
                }
            });

            confirmDialog.afterClosed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.dialogRef.close();
                }
            });
        }
        else {
            this.dialogRef.close();
        }
    }

    getCustomers(): void {
        let customer: Customer;
        customer = {
            licenseRef: this.data.LicenseRef,
            includeReturnCalcMethods: true,
            includeCustomerRules: true,
            includeCustomerPricesheets: true
        };

        this.customerService.getCustomers(customer).then((res: any) => {
            let customers = res.modelObject;
            customers.sort((a: Customer, b: Customer) => {
                if (a.clientObject == undefined && b.clientObject == undefined) return 0;
                if (a.clientObject == undefined) return -1;
                if (b.clientObject == undefined) return 1;
                if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
                    (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
                if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
                if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
                if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
                    return -1;
                }
                if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            for (let i = 0; i < customers.length; i++) {
                if (customers[i].clientObject?.name) {
                    customers[i].name = customers[i].clientObject.name;
                }
            }
            this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
            this.customers = this.customers.filter((x: any) => x.name);
            this.customers.unshift({ name: 'Ingen valgt' });
        });
    }


    close() {
        this.confirmDialog();
    }

}
