import { Injectable } from "@angular/core";
import { ProductPrice } from "../Models/productPrice";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class ProductPriceService {
    constructor(private api: ApiService) {}

    async getProductPricesByCustomerId(productPrice: ProductPrice){
        return await this.api.post("/productprice/GetByCustomerId", productPrice).toPromise();
    }    

    getProductPricesByProductId(item: ProductPrice): Promise<any>{
        return this.api.post("/productprice/getbyproductid", item).toPromise();
    }
    async save(productPrice: ProductPrice){
            return await this.api.post("/productprice/saveorupdate", productPrice).toPromise();
    }
    
    async create(productPrice: ProductPrice){
        return await this.api.post("/productprice/create", productPrice).toPromise();
    }

    async saveAll(productPrices: any[]){
        return await this.api.post("/productprice/saveorupdateAll", productPrices).toPromise();
    }

    async saveOrUpdateCustomerProducts(productPrices: any[]){
        return await this.api.post("/productprice/saveOrUpdateCustomerProducts", productPrices).toPromise();
    }

    async markDeleted(id: number, licenseRef: string) {
        return await this.api.get("/productprice/markdeleted?id=" + id + "&licenseRef=" + licenseRef).toPromise();
    }

    async delete(id?: number, licenseRef?: string) {
        if (id && licenseRef) {
            return await this.api.delete("/productprice/delete?id=" + id + "&licenseRef=" + licenseRef).toPromise();
        }
    }

}