<div fxLayout="row" style="width:80%; margin:1%;">
    <div style="display:flex; justify-content: space-between; margin: 1%; align-items: center;">
      <h2 style="margin:0px;" class="header">{{'ROADTAX'|translate}}</h2>    
        <div>
            <mat-form-field>
                <input placeholder="Kunde" type="text" matInput [(ngModel)]="this.filterValue" (ngModelChange)="this.updateFilter.next($event)">
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newRoadTax()">Ny vejskat</button>
            <button mat-raised-button (click)="saveAll()"style="border-radius: 10px;background-color: var(--primary-blue); color:white;">{{'SAVEALL' | translate}}</button>
        </div>
    </div>
    <div style="border-radius: 6px;" class="mat-elevation-z20 table-responsive">
        <table style="width:100%;" mat-table matSort (matSortChange)="sortData($event)" [dataSource]="roadtaxesDatasource">
            <!-- ↓ CUSTOMER ↓ -->
            <ng-container matColumnDef="customer">
                <th class="centText" mat-header-cell mat-sort-header="customer" *matHeaderCellDef>{{'CUSTOMER'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let environmentalTax">
                    <mat-form-field class="centText" floatLabel='never'>
                        <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                        <mat-select (ngModelChange)="checkData(environmentalTax)" [(ngModel)]="environmentalTax.fk_customerId" name="customer" [compareWith]="compareByOptionId">
                            <mat-option *ngFor="let cust of customers" [value]="cust.id">
                                <span>{{cust.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>
            <!-- ↓ DATE  ↓ -->
            <ng-container matColumnDef="date">
                <th class="centText" mat-header-cell mat-sort-header="date" *matHeaderCellDef>{{'DATE'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let environmentalTax">
                    <input [(ngModel)]="environmentalTax.date" matInput type="date" />
                </td>
            </ng-container>
            <!-- ↓ PERCENT  ↓ -->
            <ng-container matColumnDef="procent">
                <th class="centText" mat-header-cell mat-sort-header="percent" *matHeaderCellDef>{{'PROCENT'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let environmentalTax">
                    <input #procent placeholder="Procent" type="text" matInput (ngModelChange)="checkData(environmentalTax)" [(ngModel)]="environmentalTax.procent">
                </td>
            </ng-container>
            <!-- ↓ ERPCODE  ↓ -->
            <ng-container matColumnDef="erpCode">
                <th class="centText" mat-header-cell mat-sort-header="erpcode" *matHeaderCellDef>{{'ERPCODE'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let environmentalTax">
                    <input #erpCode placeholder="ERP Kode" type="text" matInput (ngModelChange)="checkData(environmentalTax)" [(ngModel)]="environmentalTax!.erpCode">
                </td>
            </ng-container>
            <!-- DELETE/ACTION ↓ -->
            <ng-container matColumnDef="action">
                <th class="centText" mat-header-cell *matHeaderCellDef></th>
                <td class="centText" mat-cell *matCellDef="let environmentalTax">
                <div *ngIf="!environmentalTax.id || environmentalTax.editable">
                    <button matTooltip="Gem Miljø Tillæg"
                    style="border-radius: 10px;background-color: var(--primary-blue); color:white;" mat-raised-button
                    (click)="updateRoadTax(environmentalTax)">
                    <mat-icon>save</mat-icon>
                    </button>
                </div>
                <div *ngIf="environmentalTax.id && !environmentalTax.editable">
                    <button matTooltip="Slet Miljø Tillæg" style="border-radius: 10px;" mat-raised-button color="warn"
                    (click)="deleteRoadTax(environmentalTax)">
                    <mat-icon>delete</mat-icon>
                    </button>
                </div>
                </td>
            </ng-container>
            <tr style="background-color:var(--primary-blue);" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
            <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
    </div>
</div>  
<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>
