import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { TerminalService } from 'src/app/shared/api/terminal.service';
import { Omex } from 'src/app/shared/Models/omex';
import { PricesheetZone } from 'src/app/shared/Models/pricesheetZone';
import { Terminal } from 'src/app/shared/Models/terminal';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewTerminalDialog } from './new-terminal-dialog/new-terminal.dialog';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit {
  terminals: Terminal[] = [];
  terminalsDataSource!: MatTableDataSource<Terminal>;
  omices: Omex[] = [];
  pricesheetZones: PricesheetZone[] = [];
  loading: any;
  displayedColumns: string[] = ["name", "action"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private terminalService: TerminalService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private data: DataShareService) { }

  ngOnInit(): void {
    this.getAllTerminals();
  }

  checkData(terminal: any) {
    if (terminal.id > 0) {
      terminal.editable = true;
    }
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate 
        message: "Du er ved at gemme :  <b>Alle Terminaler</b>" // Translate
      }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.terminalsDataSource.data.length; i++) {
          if (this.terminalsDataSource.data[i].name!.length <= 0) {
            this.terminalsDataSource.data.splice(i, 1);
          }
          else {
            if (!this.terminalsDataSource.data[i].id) {

              this.updateTerminal(this.terminalsDataSource.data[i]);
            }
          }
        }
        this.terminalService.bulkSaveOrUpdate(this.terminalsDataSource.data).then((res: any) => {
          if (res) {
            this.getAllTerminals();
          }
        }).finally(() => {
          this.loading = false;
          this._snackBar.open("Terminaler Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
        });
      }
    });

  }

  newTerminal() {
    const dialogRef = this.dialog.open(NewTerminalDialog, {disableClose: true});

    dialogRef.afterClosed().subscribe((terminal: any) => {
      if (terminal) {
        // Save Terminals Yes yes 
        terminal.licenseRef = this.data.LicenseRef;
        this.terminalService.saveOrUpdate(terminal).then((res: any) => {
        }).finally(() => {
          this._snackBar.open("Terminal Gemt", "Success", { duration: 1500, panelClass: ['snack-bar'] });
          this.getAllTerminals();
        });
      }
    });
  }

  getAllTerminals() {
    this.loading = true;
    let terminal: Terminal = {
      licenseRef: this.data.LicenseRef,
      name: '',
      includeOmices: true,
    };
    this.terminalService.getAll(terminal).then((res) => {
      if (res && res.modelObject) {
        this.terminals = res.modelObject;
        this.terminals.sort((a, b) => a.name! > b.name! ? 1 : a.name == b.name ? 0 : -1);
        // this.terminals.unshift({ licenseRef: this.data.License, name: "" });
        this.terminalsDataSource = new MatTableDataSource(this.terminals);
        this.terminalsDataSource.paginator = this.paginator;
      }
    }).finally(() => this.loading = false);
  }

  deleteTerminal(terminal: any) {
    this.terminalService.delete(terminal).then((res: any) => {
      this.getAllTerminals();
    }).finally(() => {
      this._snackBar.open("Terminal Slettet", "Success", { duration: 1000, panelClass: ['snack-bar'] });
    });
  }

  updateTerminal(terminal: Terminal) {
    this.loading = true;
    if (terminal) {
      this.terminalService.saveOrUpdate(terminal).then((res: any) => {
        this.getAllTerminals();
      }).finally(() => {
        this.loading = false;
        this._snackBar.open("Terminal Opdateret", "Success", { duration: 1000, panelClass: ['snack-bar'] });
      });
    }
  }

}
