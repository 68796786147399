import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { CapacityTaxService } from '../shared/api/capacity-tax.service';
import { CurrencyService } from '../shared/api/currency.service';
import { CustomerService } from '../shared/api/customer.service';
import { DataShareService } from '../shared/api/datashare.service';
import { EnvironmentalTaxService } from '../shared/api/environmental-tax.service';
import { OilService } from '../shared/api/oil.service';
import { PricesheetService } from '../shared/api/pricesheet.service';
import { CapacityTax } from '../shared/Models/capacityTax';
import { Currency } from '../shared/Models/currency';
import { Customer } from '../shared/Models/customer';
import { CustomerPriceSheet } from '../shared/Models/customerPriceSheet';
import { EnvironmentalTax } from '../shared/Models/enviromentalTax';
import { OilGroup } from '../shared/Models/oilGroup';
import { Pricesheet } from '../shared/Models/pricesheet';

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit {

  filteredOptions?: Observable<any>;
  customerControl = new FormControl();
  formGroup?: FormGroup;
  options: any;
  selectedCustomer?: Customer;
  pricesheets: any;
  oilGroups: any;
  selectedOilGroup: any;
  selectedCurrency: any;
  capacityTaxes: any;
  capacityProcent?: number;
  environmentalTax?: number;

  searchCustomer: Customer = {};
  customers: Customer[] = [];
  selectedCapacityTax: CapacityTax = {};
  customers$?: Observable<Customer[]>;
  creditors$?: Observable<Customer[]>;
  selectedPricesheets: Pricesheet[] = [];
  currencies: Currency[] = [];

/*   customerPrices:boolean=false;
  creditorPrices:boolean=false;
 */
  constructor(private customerService: CustomerService, public data: DataShareService, private fb: FormBuilder, private pricesheetService: PricesheetService,
    private oilService: OilService,
    private currencyService: CurrencyService,
    private capacityTaxService: CapacityTaxService,
    private environmentalTaxService: EnvironmentalTaxService,
    private _snackBar: MatSnackBar) {
    this.searchCustomer.licenseRef = this.data.LicenseRef;
    this.formGroup = this.fb.group({
      customer: [null],
      creditor: [null]
    })
    this.customers$ = this.formGroup.get('customer')?.valueChanges.pipe(
      distinctUntilChanged(), // Has to be new value
      debounceTime(500), // To make sure it does not search on each key input ( Waits half a second to search)
      filter((val: any) => !!val), // (double) Checks if it is a new value - Fuck yeah!
      switchMap(name => this.customerService.searchClientCustomersNotInPE(this.searchCustomer, name)) // switch and get result from client search. 
    );
    this.creditors$ = this.formGroup.get('creditor')?.valueChanges.pipe(
      distinctUntilChanged(), // Has to be new value
      debounceTime(500), // To make sure it does not search on each key input ( Waits half a second to search)
      filter((val: any) => !!val), // (double) Checks if it is a new value - Fuck yeah!
      switchMap(name => this.customerService.searchClientCreditorsNotInPE(this.searchCustomer, name)) // switch and get result from client search. 
    );
  }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.getStandardPricesheets();
    this.getOilGroups();
    this.getCurrencies();
    this.getAllCapacityTaxes();
  }

  // Check/uncheck pricesheet
  togglePricesheet(pricesheet: any) {
    pricesheet.checked = !pricesheet.checked;
  }


  getStandardPricesheets() {
    let pricesheet: Pricesheet = {
      licenseRef: this.data.LicenseRef
    };
    this.pricesheetService.getAllDefaultPricesheets(pricesheet).subscribe((res) => {
      if (res) {
        this.pricesheets = res.modelObject;
        for (let i = 0; i < this.pricesheets.length; i++) {
          this.pricesheets[i].checked = true;
        }
      }
    });
  }

  checkedPricesheets(): any{
    for (let i = 0; i < this.pricesheets.length; i++) {
      if(this.pricesheets[i].checked){
        return true;
      }
    }
    return false;
  }

  toggleAllOff(){
    for (let i = 0; i < this.pricesheets.length; i++) {
      this.pricesheets[i].checked = false;
    }
  }

  toggleAllOn(){
    for (let i = 0; i < this.pricesheets.length; i++) {
      this.pricesheets[i].checked = true;
    }
  }


  getOilGroups() {
    let oilGroup: OilGroup = {
      licenseRef: this.data.LicenseRef
    };
    this.oilService.getAllOilGroup(oilGroup).then((res) => {
      this.oilGroups = res.modelObject;
    });
  }

  selectOilGroup(oilgroup: any) {
    this.selectedOilGroup = oilgroup;
  }
  selectCurrency(curr: any) {
    this.selectedCurrency = curr;
  }
  selectCustomer(cust: any) {
    this.selectedCustomer = cust;
  }
  selectCapacityTax(capaTax: any) {
    this.selectedCapacityTax = capaTax;
  }

  saveAll() {
    if (this.selectedCustomer) {
      if (this.selectedOilGroup) {
        this.selectedCustomer.fk_oilGroupId = this.selectedOilGroup.id;
      }
      if (this.selectedCapacityTax.procent != null) {
        this.selectedCapacityTax.licenseRef = this.data.LicenseRef;
      }
      this.saveCustomer();
    }
  }

  validData(): boolean {
    if (this.selectedCurrency && this.selectedCustomer) {
      return true;
    }
    return false;
  }
  saveCustomer(): any {
    if (this.selectedCustomer) {
      const customerRequest = this.mapCustomer(this.selectedCustomer)
      this.customerService.saveOrUpdate(customerRequest).then((res) => {
        if (res) {
          this._snackBar.open("Kunde Oprettet", "Success", { duration: 1000, panelClass: ['snack-bar'] }); // Translate
          this.selectedCustomer = undefined;
          this.formGroup?.setValue({
            customer: ' '
          });
          var custId = res.modelObject.id;
          if (custId != null) {
            if (this.selectedCapacityTax.procent) {
              //this.selectedCapacityTax.fk_customerId = custId;
              this.selectedCapacityTax.licenseRef = this.data.LicenseRef;
              this.capacityTaxService.saveOrUpdate(this.selectedCapacityTax).then((res: any) => {
                if(res){
                  // Saved
                }
              }).finally(() => {
                this._snackBar.open("Kapacitets Tillæg Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] }); // Translate

              });
            }
            if (this.environmentalTax) {
              let envTax: EnvironmentalTax = {
                licenseRef: this.data.LicenseRef,
                fk_customerId: custId,
                procent: this.environmentalTax,
              };
              this.environmentalTaxService.saveOrUpdate(envTax).then((res: any) => {
                if(res){
                  // Saved
                }
              }).finally(() => {
                this._snackBar.open("Miljø Tillæg Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] }); // Translate

              });
            }
          }
        }
      });
    }
  }

  getAllCapacityTaxes() {
    this.capacityTaxService.getAll({
      licenseRef: this.data.LicenseRef,
      procent: 0,
      erpCode: ""
    }).then((res) => {
      if (res) {
        if (res.modelObject) {
          this.capacityTaxes = res.modelObject;
        }
        this.capacityTaxes.unshift({ licenseRef: this.data.LicenseRef });
      }
    });
  }

  mapCustomerPricesheet() {
    const tmpArray = [];
    for (let i = 0; i < this.pricesheets.length; i++) {
      if (this.pricesheets[i].checked) {
        const newCustPricesheet: CustomerPriceSheet = {
          //fk_customerId: this.selectedCustomer?.id,
          fk_pricesheetId: this.pricesheets[i].id,
          pricesheet: this.pricesheets[i],
          licenseRef: this.data.LicenseRef,
        };
        tmpArray.push(newCustPricesheet);
      }
    }
    return tmpArray;
  }


  getCurrencies() {
    let currency: Currency = {
      licenseRef: this.data.LicenseRef,

    };
    this.currencyService.getCurrencies(currency).then((res) => {
      if (res) {
        this.currencies = res.modelObject;
        this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
        this.selectedCurrency = this.currencies.find(x => x.isocode == 'DKK');
      }
    });
  }

  mapCustomer(cust: any) {
    const newCust: Customer = {
      licenseRef: this.data.LicenseRef,
      fk_oilGroupId: cust.fk_oilGroupId != null ? cust.fk_oilGroupId : undefined,
      customerexternRef: cust.externalRef,
      adviceOnNoPrice: false,
      currencyISO: "DKK", // Hardcoded
      fk_currencyId: this.selectedCurrency.id,
      countryNo: 1,
      country: {
        name: cust.country
      }
    };
    if (this.pricesheets.length > 0) {
      newCust.customerPricesheets = this.mapCustomerPricesheet();
    }
    return newCust;
  }

}
