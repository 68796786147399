<div class="container">
  <!-- <mat-form-field style="font-size:14px;width:12%; min-width:220px;" appearance="outline">
    <mat-label>{{'STANDARDCURRENCY' | translate}}</mat-label>
    <mat-select [(value)]="chosenCurrency">
      <mat-option *ngFor="let curr of currencies" [value]="curr.name" (click)="selectCurrency(curr)">
        {{curr.isocode}}
      </mat-option>
    </mat-select>
    <mat-icon style="color: var(--primary-blue);" matSuffix>price_change</mat-icon>
  </mat-form-field>
  <mat-form-field style="font-size:14px;width:12%; min-width:220px; margin-left:6px;" appearance="outline">
    <mat-label>{{'MEASUREUNIT' | translate}}</mat-label>
    <mat-select [(value)]="chosenMeasureunit">
      <mat-option *ngFor="let mu of measureUnits" [value]="mu.name" (click)="selectMeasureUnit(mu)">
        {{mu.name}}
      </mat-option>
    </mat-select>
    <mat-icon style="color: var(--primary-blue);" matSuffix>request_quote</mat-icon>
  </mat-form-field> -->
  <h3 class="header">{{'PRICES'|translate}}</h3>
</div>
<div style="width:82% !important; padding-bottom:50px;">
  <!-- ↓ Ignore Errors Below ↓ - It works -->
  <ejs-spreadsheet (dataSourceChanged)='dataSourceChanged($event)' (created)="created()" (cellEdit)="cellEdit($event)"
    #spreadsheet [showFormulaBar]='false' [showRibbon]='false' [showSheetTabs]="false" [allowConditionalFormat]="false"
    [allowNumberFormatting]="false" [scrollSettings]="{ enableVirtualization: false}">
    <e-sheets>
      <e-sheet name="Priser">
        <e-ranges>
          <e-range [dataSource]="prices">
          </e-range>
        </e-ranges>
        <e-rows>
          <e-row>
            <e-cells>
            </e-cells>
          </e-row>
        </e-rows>
        <e-columns>
          <e-column [width]=1></e-column> <!-- ID -->
          <e-column [width]=120></e-column> <!-- Product -->
          <e-column [width]=120></e-column> <!-- Colli Type -->
          <e-column [width]=60></e-column> <!-- PriceCode -->
          <e-column [width]=80></e-column> <!-- IntervalFrom -->
          <e-column [width]=80></e-column> <!-- IntervalTo -->
          <e-column [width]=120></e-column> <!-- MeasureUnit -->
          <e-column [width]=110></e-column> <!-- PriceCalcUnit -->
          <e-column [width]=80></e-column> <!-- Price -->
          <e-column [width]=80></e-column> <!-- Currency -->
          <e-column [width]=50></e-column> <!-- ZoneNo -->
          <e-column [width]=120></e-column> <!-- Text -->
          <e-column [width]=120></e-column> <!-- Address -->
          <e-column [width]=120></e-column> <!-- Zipcode -->
          <e-column [width]=120></e-column> <!-- Countrycode -->
        </e-columns>
      </e-sheet>
      <e-sheet name="currencies" state="VeryHidden">
        <e-ranges>
          <e-range [dataSource]="currencies"></e-range>
        </e-ranges>
      </e-sheet>
      <e-sheet name="colliTypes" state="VeryHidden">
        <e-ranges>
          <e-range [dataSource]="colliTypes"></e-range>
        </e-ranges>
      </e-sheet>
      <e-sheet name="measureUnit" state="VeryHidden">
        <e-ranges>
          <e-range [dataSource]="measureUnits">
          </e-range>
        </e-ranges>
      </e-sheet>
      <e-sheet name="products" state="VeryHidden">
        <e-ranges>
          <e-range [dataSource]="products">
          </e-range>
        </e-ranges>
      </e-sheet>
      <e-sheet name="priceCalcUnit" state="VeryHidden">
        <e-ranges>
          <e-range [dataSource]="priceCalcUnits">
          </e-range>
        </e-ranges>
      </e-sheet>
    </e-sheets>
  </ejs-spreadsheet>
</div>