import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { EnvironmentalTaxService } from 'src/app/shared/api/environmental-tax.service';
import { Customer } from 'src/app/shared/Models/customer';
import { EnvironmentalTax } from 'src/app/shared/Models/enviromentalTax';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewEnvironmentalTaxDialog } from './new-environmental-tax-dialog/new-environmental-tax.dialog';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-environmental-tax',
  templateUrl: './environmental-tax.component.html',
  styleUrls: ['./environmental-tax.component.scss']
})
export class EnvironmentalTaxComponent implements OnInit {
  
  displayedColumns: string[] = ["customer", "date", "procent", "erpCode", "action"]; // currency
  environmentalTaxes: EnvironmentalTax[] = [];
  environmentalTaxesFiltered: EnvironmentalTax[] = [];
  environmentalTaxesDataSource!: MatTableDataSource<EnvironmentalTax>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customers: Customer[] = [];
  loading: any;
  filterValue: string = "";
  updateFilter = new Subject<string>();

  constructor(private environmentalTaxService: EnvironmentalTaxService,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private data: DataShareService,
    private customerService: CustomerService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllEnvironmentalTaxes();
    this.getCustomers();
    this.updateFilter.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe(value=>{
      this.environmentalTaxesFiltered = this.environmentalTaxes.filter((element) => {
        if (value == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      this.environmentalTaxesDataSource = new MatTableDataSource(this.environmentalTaxesFiltered);
      this.environmentalTaxesDataSource.paginator = this.paginator;
    });
    
  }

  sortData(sort: Sort) {
    const data = this.environmentalTaxesFiltered.slice();
    if (!sort.active || sort.direction === '') {
      this.environmentalTaxesFiltered = data;
      return;
    }

    this.environmentalTaxesFiltered = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'customer':
          let customerA = this.customers.find(element=> element.id == a.fk_customerId);
          let customerB = this.customers.find(element=> element.id == b.fk_customerId);
          return this.compare(customerA!.name, customerB!.name, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'percent':
          return this.compare(a.procent, b.procent, isAsc);
        default:
          return 0;
      }
    });
    this.environmentalTaxesDataSource = new MatTableDataSource(this.environmentalTaxesFiltered);
    this.environmentalTaxesDataSource.paginator = this.paginator;
  }  

  compare(a: any, b: any, isAsc: boolean) {
    if(a==b)return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getAllEnvironmentalTaxes() {
    this.loading = true;
    let envTax: EnvironmentalTax = {
      licenseRef: this.data.LicenseRef
    };
    this.environmentalTaxService.getAll(envTax).then((res: any) => {
      this.environmentalTaxes = res.modelObject;
      for (let i = 0; i < this.environmentalTaxes.length; i++) {
        if (this.environmentalTaxes[i].date) {
          this.environmentalTaxes[i].date = this.datePipe.transform(this.environmentalTaxes[i].date, 'yyyy-MM-dd')!;
        }
      }
      this.environmentalTaxesFiltered = this.environmentalTaxes.filter((element) => {
        if (this.filterValue == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
      });
      // this.environmentalTaxes.unshift({ licenseRef: this.data.License });
      this.environmentalTaxesDataSource = new MatTableDataSource(this.environmentalTaxesFiltered);
      this.environmentalTaxesDataSource.paginator = this.paginator;
    }).finally(() => this.loading = false);
  }

  compareByOptionId(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst == refSecond;
  }

  newEnvironmentalTax() {
    // Open Dialog 
    const dialogRef = this.dialog.open(NewEnvironmentalTaxDialog, { disableClose: true });


    dialogRef.afterClosed().subscribe((envTax: EnvironmentalTax) => {
      if (envTax) {
        envTax.licenseRef = this.data.LicenseRef;
        this.environmentalTaxService.saveOrUpdate(envTax).then((res: any) => {
          if (res) {

          }
        }).finally(() => {
          this._snackBar.open("Miljø Tillæg Gemt", "Success", { duration: 1500, panelClass: ['snack-bar'] });
          this.getAllEnvironmentalTaxes();
        });
      }
    })
    // Save Env Tax
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {width: "16%", height: "18%", data: {
        title: "Er du sikker?", // Translate 
        message: "Du er ved at gemme :  <b>Alle Miljø Tillæg</b>" // Translate
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.environmentalTaxesDataSource.data.length; i++) {
          if (this.environmentalTaxesDataSource.data[i].date) {
            this.environmentalTaxesDataSource.data[i].date = this.datePipe.transform(this.environmentalTaxesDataSource.data[i].date, 'yyyy-MM-dd')!;
          }
          if (!this.environmentalTaxesDataSource.data[i].fk_customerId) {
            this.environmentalTaxesDataSource.data.splice(i, 1);
          }
          else {
            if (!this.environmentalTaxesDataSource.data[i].id) {

              this.updateEnvironmentalTax(this.environmentalTaxesDataSource.data[i]);
            }
          }
        }
        this.environmentalTaxService.bulkSaveOrUpdate(this.environmentalTaxesDataSource.data).then((res: any) => {
          if (res) {
            this.getAllEnvironmentalTaxes();
          }
        }).finally(() => {
          this.loading = false;
          this._snackBar.open("Alle Miljø Tillæg Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
        });
      }
    });
  }

  checkData(enviromentalTax: any) {
    if (enviromentalTax.id > 0) {
      enviromentalTax.editable = true;
    }
  }


  deleteEnvironmentalTax(environmentalTax: EnvironmentalTax) {
    if (environmentalTax) {
      if (!environmentalTax.licenseRef) {
        environmentalTax.licenseRef = this.data.LicenseRef;
      }
      this.environmentalTaxService.delete(environmentalTax).then((res: any) => {
        if (res) {
          this.getAllEnvironmentalTaxes();
        }
      }).finally(() => {
        this._snackBar.open("Miljø Tillæg Slettet", "Success", { duration: 1000, panelClass: ['snack-bar'] });
      });
    }
  }

  updateEnvironmentalTax(enviromentalTax: any) {
    this.loading = true;
    if (enviromentalTax) {
      if (!enviromentalTax.licenseRef) {
        enviromentalTax.licenseRef = this.data.LicenseRef;
      }
      if (enviromentalTax.date) {
        enviromentalTax.date = this.datePipe.transform(enviromentalTax.date, 'yyyy-MM-dd')!;

      }
      this.environmentalTaxService.saveOrUpdate(enviromentalTax).then((res: any) => {
        if (res) {
          this.getAllEnvironmentalTaxes();
        }
      }).finally(() => {
        this.loading = false;
        this._snackBar.open("Miljø Tillæg Opdateret", "Success", { duration: 1000, panelClass: ['snack-bar'] });
      });
    }
  }

  getCustomers(): void {
    this.loading = true;
    let customer: Customer;
    customer = {
      licenseRef: this.data.LicenseRef,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.customers = this.customers.filter(x => x.name);
      this.customers.unshift({ name: 'Ingen valgt' });
      this.environmentalTaxesFiltered = this.environmentalTaxes.filter((element) => {
        if (this.filterValue == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
      });
      this.environmentalTaxesDataSource = new MatTableDataSource(this.environmentalTaxesFiltered);
      this.environmentalTaxesDataSource.paginator = this.paginator;
    }).finally(() => this.loading = false);
  }
}
