<div fxLayout="row" style="width:100%; margin:4px;">
    <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
        <div style="width: 60%; display:flex;justify-content: space-between; margin:4px; align-items:center;">
            <h2 style="margin: 0px; width: 300px;" class="header">{{'PRODUCTS'|translate}}</h2>
            <div style="width: 100px; position: relative;">
                <fieldset>
                    <legend style="margin-bottom: -8px !important;">{{'FILTER'|translate}}</legend>
                    <input class="inputstyle" type="text" [(ngModel)]="inputFilter" (ngModelChange)="filterChanged()">
                    <mat-icon *ngIf="inputFilter" (click)="clearFilter()" style="position: absolute; top: 22px; left: 168px; font-size: 16px; cursor: pointer;" 
                        matSuffix>close
                    </mat-icon>
                </fieldset>
            </div>
            <div style="width: 100px;"></div>
        </div>

        <div style="width: 60%; display: flex; flex-direction: column; justify-content: space-between;">
            <div style="border-radius: 6px;  max-height: 500px; overflow-x: hidden;" class="mat-elevation-z2 table-responsive">
                <table #tableProduct mat-table [dataSource]="productDataSource">
                    <!-- NAME ↓ -->
                    <ng-container matColumnDef="name">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'NAME'|translate}}</th>
                        <td [matTooltipDisabled]="product != products[0]" matTooltipPosition="right"
                            matTooltip="Navn skal være udfyldt" style="text-align: center !important;  cursor: pointer;" mat-cell
                            *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input required (ngModelChange)="checkData(product)" #name placeholder="Navn" type="text" matInput [(ngModel)]="product.name">
                        </td>
                    </ng-container>
                    <!-- NUMBER ↓ -->
                    <ng-container matColumnDef="number">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'ERPCODE'|translate}}</th>
                        <td [matTooltipDisabled]="product != products[0]" matTooltipPosition="right"
                            matTooltip="Navn skal være udfyldt" style="text-align: center !important;  cursor: pointer;" mat-cell
                            *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input required (ngModelChange)="checkData(product)" #number placeholder="ERPCode" type="text" matInput [(ngModel)]="product.erpCode">
                        </td>
                    </ng-container>
                    <!-- PRODUCTUNIT ↓ -->
                    <ng-container matColumnDef="productunit">
                        <th mat-header-cell *matHeaderCellDef>{{'PRODUCTUNIT' | translate}}</th>
                        <td [matTooltipDisabled]="product != product[0]" matTooltipPosition="right"
                            matTooltip="Produkt Enhed skal være udfyldt" mat-cell *matCellDef="let product"
                            [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <mat-form-field style="width:100px;" floatLabel='never'>
                                <mat-label>{{'UNIT' | translate}}</mat-label>
                                <mat-select (ngModelChange)="checkData(product)" #productunit
                                [(ngModel)]="product.productUnit" name="productunit" [compareWith]="compareByOptionId">
                                <mat-option *ngFor="let pu of productUnits" [value]="pu">
                                    {{pu.name}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <!-- OIL ↓ -->
                    <ng-container matColumnDef="oil">
                        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u> {{'OIL' |translate}}</th>
                        <td style="text-align: center;" mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <input (ngModelChange)="checkData(product)" #oil placeholder="Olie" type="checkbox"
                            [(ngModel)]="product.excludeOilTariff">
                        </td>
                    </ng-container>
                    <!-- ENVIRONMENT ↓ -->
                    <ng-container matColumnDef="enviroment">
                        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u>
                            {{'ENVIRONMENTFEE' | translate}}</th>
                        <td style="text-align: center;" mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkData(product)" #environment placeholder="Miljø afgift" type="checkbox"
                            [(ngModel)]="product.excludeEnvironmentalTariff">
                        </td>
                    </ng-container>
                    <!-- CAPACITY ↓ -->
                    <ng-container matColumnDef="capacity">
                        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u>{{'CAPACITYFEE' | translate}}</th>
                        <td style="text-align: center;" mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <input (ngModelChange)="checkData(product)" #capacity placeholder="Kapacitets tillæg" type="checkbox"
                            [(ngModel)]="product.excludeCapacityTariff">
                        </td>
                    </ng-container>
                    <!-- FREIGHTLINE ↓ -->
                    <ng-container matColumnDef="freightLine">
                        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} {{'FROM' | translate}} {{'FREIGHTLINES' |translate}}</th>
                        <td style="text-align: center;" mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <input (ngModelChange)="checkData(product)" #environment placeholder="Beregn Fragt Linjer?"
                            type="checkbox" [(ngModel)]="product.calcFromFreightLine">
                        </td>
                    </ng-container>
                    <!-- COPYALWAYS ↓ -->
                    <ng-container matColumnDef="copyalways">
                        <th mat-header-cell *matHeaderCellDef>{{'COPYALWAYS' | translate}}</th>
                        <td mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <input type="checkbox" #environment (ngModelChange)="checkData(product)" placeholder="Medtag altid" [(ngModel)]="product.copyAlways">
                        </td>
                    </ng-container>
                    <!-- RECALCONRERUN ↓ -->
                    <ng-container matColumnDef="recalconrerun">
                        <th mat-header-cell *matHeaderCellDef>{{'RECALCONRERUN' | translate}}</th>
                        <td mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <input type="checkbox" #environment (ngModelChange)="checkData(product)" placeholder="Beregn genkørsel" [(ngModel)]="product.recalcOnRerun">
                        </td>
                    </ng-container>
                    <!-- DELETE/ACTION ↓ -->
                    <ng-container matColumnDef="action">
                        <th style="text-align: center;" mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let product" [ngStyle]="{'background-color' : product.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <div *ngIf="product.id && !product.editable">
                            <button matTooltip="Slet Produkt"  mat-mini-fab color="warn"
                                (click)="deleteProduct(product)">
                                <mat-icon style="font-size: 22px;">delete</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="!product.id || product.editable">
                            <button matTooltip="Gem Produkt"
                                style="background-color: var(--primary-blue); color:white;" mat-mini-fab
                                (click)="applyProduct(product)">
                                <mat-icon style="font-size: 22px;">save</mat-icon>
                            </button>
                        </div>
                        </td>
                    </ng-container>
                    <tr style="background-color:var(--primary-blue);" mat-header-row *matHeaderRowDef="productColumns; sticky:true; "></tr>
                    <tr style="max-height: 24px !important;" mat-row (click)="productRowClicked(row)" *matRowDef="let row; columns: productColumns; let i = index;"></tr>        
                </table>
                <mat-paginator #paginatorProduct [pageSizeOptions]="[8, 24, 48, 96]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>

            </div>
        </div>
        <div style="width: 60%; display:flex;justify-content: space-between; margin:4px; align-items:center;">
            <h2 style="margin: 0px; width: 300px;" class="header">{{ getPriceHeader('PRODUCTPRICES'|translate)}}</h2>
            <button *ngIf="hasMultiplePriceUpdates()" mat-mini-fab style="background-color: var(--primary-blue); color: white;" 
                matTooltip="Gem alle" (click)="saveAllPrices()">
                <mat-icon style="font-size: 24px;">save</mat-icon>
            </button>
            <div style="width: 100px; position: relative;" *ngIf="actualPriceView && actualPriceView.length > 8">
                <fieldset>
                    <legend style="margin-bottom: -8px !important;">{{'FILTER'|translate}}</legend>
                    <input class="inputstyle" type="text" [(ngModel)]="inputFilterPrice" (ngModelChange)="filterPriceChanged()">
                    <mat-icon *ngIf="inputFilterPrice" (click)="clearFilterPrice()" style="position: absolute; top: 22px; left: 168px; font-size: 16px; cursor: pointer;" 
                        matSuffix>close
                    </mat-icon>
                </fieldset>
            </div>
            <mat-form-field style="margin-left:4px; margin-top: 12px; width:120px;">
                <mat-label>Vælg en Dato</mat-label>
                <mat-select [value]="selectedDate" style="font-weight:450;">
                    <mat-option *ngFor="let date of pricesheetDates" [value]="date" (click)="chooseDate(date)">
                        {{ date | date: 'dd/MM/yyyy'}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="width: 60%; display: flex; flex-direction: column; justify-content: space-between;">
            <div style="border-radius: 6px; max-height: 500px; overflow-x: hidden;" class="mat-elevation-z2 table-responsive">
                <table #tablePrice mat-table [dataSource]="productPriceDataSource">
                    <!-- CUSTOMERNUMBER ↓ -->
                    <ng-container matColumnDef="customernumber">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'CUSTOMERNUMBER'|translate}}</th>
                        <td [matTooltipDisabled]="productPrice != productPrices[0]" matTooltipPosition="right"
                            matTooltip="Navn skal være udfyldt" style="text-align: center !important;  cursor: pointer;" mat-cell
                            *matCellDef="let productPrice" [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            {{productPrice.customer.clientObject.number}}
                        </td>
                    </ng-container>

<!--                      <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>{{'CUSTOMER' | translate}}</th>
                        <td [matTooltipDisabled]="productPrice != productPrice[0]" matTooltipPosition="right"
                            matTooltip="Produkt Enhed skal være udfyldt" mat-cell *matCellDef="let productPrice"
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <mat-form-field style="width:200px;" floatLabel='never'>
                                <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                                <mat-select (ngModelChange)="checkPriceData(productPrice)" [(ngModel)]="productPrice.customer.clientObject">
                                    <mat-option *ngFor="let cc of customers" [value]="cc.clientObject">
                                        {{cc.clientObject!.name}} | {{cc.clientObject!.number}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
 --> 
                     
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>{{'CUSTOMER' | translate}}</th>
                        <td [matTooltipDisabled]="productPrice != productPrice[0]" matTooltipPosition="right"
                            matTooltip="Produkt Enhed skal være udfyldt" mat-cell *matCellDef="let productPrice"
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <mat-form-field *ngIf="!productPrice.id" style="width: 200px;" floatLabel="never">
                                <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                                <input type="text" matInput [matAutocomplete]="auto" aria-label="Kunde" [formControl]="customControl" >
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let cc of filteredCustomers()" [value]="cc.clientObject!.number + ' | ' + cc.clientObject!.name" (click)="selectCustomer(productPrice, cc)" (onSelectionChange)="selectCustomer(productPrice, cc)">
                                        {{cc.clientObject!.name}} | <small>{{cc.clientObject!.number}} {{cc.clientObject!.address}} {{cc.clientObject!.zip}} {{cc.clientObject!.city}}</small>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="productPrice.id">{{productPrice.customer.clientObject.nickname}}</div>
                        </td>
                    </ng-container>
                    
                    <!-- INBTERVALFROM ↓ -->
                    <ng-container matColumnDef="intervalfrom">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'INTERVALFROM'|translate}}</th>
                        <td mat-cell style="text-align: center !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceData(productPrice)" #intervalfrom placeholder="Interval fra" type="number" matInput
                                [(ngModel)]="productPrice.intervalfrom">
                        </td>
                    </ng-container>
                    <!-- INBTERVALTO ↓ -->
                    <ng-container matColumnDef="intervalto">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'INTERVALTO'|translate}}</th>
                        <td mat-cell style="text-align: center !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceData(productPrice)" #intervalto placeholder="Interval til" type="number" matInput
                                [(ngModel)]="productPrice.intervalto">
                        </td>
                    </ng-container>
                    <!-- CURRENCY ↓ -->
                    <ng-container matColumnDef="valuta">
                        <th style="text-align: center;" mat-header-cell *matHeaderCellDef>{{'CURRENCY'|translate}}</th>
                        <td mat-cell *matCellDef="let productPrice" [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <mat-form-field style="width:100px;" floatLabel='never'>
                            <mat-label>{{'CURRENCY' | translate}}</mat-label>
                            <mat-select (ngModelChange)="checkPriceData(productPrice)" #currency [(ngModel)]="productPrice.currency"
                                name="currency" [compareWith]="compareByOptionId">
                                <mat-option *ngFor="let curr of currencies" [value]="curr">
                                    {{curr.isocode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        </td>
                    </ng-container>
                    <!-- PRICE ↓ -->
                    <ng-container matColumnDef="price">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'PRICE'|translate}}</th>
                        <td mat-cell style="text-align: right !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceData(productPrice)" #price placeholder="Pris" type="number" matInput
                                [(ngModel)]="productPrice.price">
                        </td>
                    </ng-container>
                    <!-- PERCENT ↓ -->
                    <ng-container matColumnDef="percent">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'PERCENT'|translate}}</th>
                        <td mat-cell style="text-align: right !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceData(productPrice)" #percent placeholder="Procent" type="number" matInput
                                [(ngModel)]="productPrice.percent">
                        </td>
                    </ng-container>
                    <!-- MINPRICE ↓ -->
                    <ng-container matColumnDef="minprice">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'MINPRICE'|translate}}</th>
                        <td mat-cell style="text-align: right !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceData(productPrice)" #minprice placeholder="Mindste pris" type="number" matInput
                                [(ngModel)]="productPrice.minPrice">
                        </td>
                    </ng-container>
                    <!-- VALIDFROM ↓ -->
                    <ng-container matColumnDef="validfrom">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'VALIDFROM'|translate}}</th>
                        <td mat-cell style="text-align: right !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceDateData(productPrice, $event)" #validFrom placeholder="Valid fra" type="date" matInput
                                [ngModel]="productPrice.validFrom | date: 'yyyy-MM-dd'">
                        </td>
                    </ng-container>
                    <!-- VALIDTO ↓ -->
                    <ng-container matColumnDef="validto">
                        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'VALIDTO'|translate}}</th>
                        <td mat-cell style="text-align: right !important;" *matCellDef="let productPrice" 
                            [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                            <input (ngModelChange)="checkPriceDateData2(productPrice, $event)" #validTo placeholder="Valid til" type="date" matInput
                                [ngModel]="productPrice.validTo | date: 'yyyy-MM-dd'">
                        </td>
                    </ng-container>
    

                    <!-- DELETE/ACTION ↓ -->
                    <ng-container matColumnDef="action">
                        <th style="text-align: center;" mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let productPrice" [ngStyle]="{'background-color' : productPrice.id ? 'rgba(128, 128, 128, 0.1)' : 'white'}">
                        <div *ngIf="productPrice.id && !productPrice.editable">
                            <button matTooltip="Slet Produkt" mat-mini-fab color="warn"
                                (click)="deletePrice(productPrice)">
                                <mat-icon style="font-size: 22px;">delete</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="!productPrice.id || productPrice.editable">
                            <button matTooltip="Gem Produkt"
                                style="background-color: var(--primary-blue); color:white;" mat-mini-fab
                                (click)="applyPrice(productPrice)">
                                <mat-icon style="font-size: 22px;">save</mat-icon>
                            </button>
                        </div>
                        </td>
                    </ng-container>

                    <tr style="background-color:var(--primary-blue);" mat-header-row *matHeaderRowDef="priceColumns; sticky:true;"></tr>
                    <tr style="max-height: 24px !important;" mat-row (click)="priceRowClicked(row)" *matRowDef="let row; columns: priceColumns; let i = index;"></tr>        
                </table>
                <mat-paginator #paginatorPrice [pageSizeOptions]="[8, 24, 48, 96]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
            </div>
        </div>
    </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>