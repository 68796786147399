import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataShareService } from '../shared/api/datashare.service';
import { RulesService } from '../shared/api/rules.service';
import { Customer } from '../shared/Models/customer';
import { Pricesheet } from '../shared/Models/pricesheet';
import { Rule } from '../shared/Models/rule';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-rules',
  templateUrl: './customer-rules.component.html',
  styleUrls: ['./customer-rules.component.scss']
})
export class CustomerRulesComponent implements OnInit, OnChanges {

  @Input() customer = <Customer>{};
  @Input() pricesheet = <Pricesheet>{};

  customerRule: Rule = {};
  sheetRule: Rule = {};
  customerRuleORG: Rule = {};
  sheetRuleORG: Rule = {};

  rules: Rule[] = [];
  showOld = false;
  isCustomer = false;
  valueType? = "";

  constructor(
    private ruleService: RulesService, 
    public data: DataShareService, 
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,
    ) {

  }

  ngOnInit(): void {
    this.getRulesByCustomer();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateRule();
  }

  updateRule() {
    if (this.rules && this.rules.length > 0) {
      if (this.pricesheet) {
        let sRule = this.rules.find((r) => {
          return r.fk_pricesheetId == this.pricesheet.id
        });
        if (sRule) {
          this.sheetRule = sRule;
          this.sheetRuleORG = {createdAt: sRule.createdAt, customer: sRule.customer, defaultPriceCode: sRule.defaultPriceCode, fk_customerId: sRule.fk_customerId,
                fk_pricesheetId: sRule.fk_pricesheetId, id: sRule.id, oilpaydatetype: sRule.oilpaydatetype, pllprldm: sRule.pllprldm, pllRound: sRule.pllRound,
                pricesheet: sRule.pricesheet, weightprldb: sRule.weightprldb, weightprvolume: sRule.weightprvolume}
        } else {
          this.sheetRule = {};
          this.sheetRuleORG = {};
        }

      }
      else {
        this.sheetRule = {};
        this.sheetRuleORG = {};
      }
      if (this.customer) {
        let cRule = this.rules.find((r) => {
          return r.fk_customerId == this.customer.id && r.fk_pricesheetId == null
        });
        if (cRule) {
          this.customerRule = cRule;
          this.customerRuleORG = {createdAt: cRule.createdAt, customer: cRule.customer, defaultPriceCode: cRule.defaultPriceCode, fk_customerId: cRule.fk_customerId,
            fk_pricesheetId: cRule.fk_pricesheetId, id: cRule.id, oilpaydatetype: cRule.oilpaydatetype, pllprldm: cRule.pllprldm, pllRound: cRule.pllRound,
            pricesheet: cRule.pricesheet, weightprldb: cRule.weightprldb, weightprvolume: cRule.weightprvolume}
        }else{
          this.customerRule = {};
          this.customerRuleORG = {};
        }
      }else{
        this.customerRule = {};
        this.customerRuleORG = {};
      }
    }
  }


  getRulesByCustomer() {
    if (this.customer) {
      this.ruleService.getByCustomer({fk_customerId: this.customer.id, licenseRef: this.data.LicenseRef, oilpaydatetype: 0}).then((res: any) => {
        if (res && res.modelObject) {
          this.rules = res.modelObject;
          this.updateRule();
        }
      });
    }
  }

  removeRule() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      maxHeight: '200px',
      height: "18%",
      data: {
        title: this.translate.instant("AREYOUSUREQUESTION"), 
        message: this.translate.instant("YOUAREABOUTTODELETETHERULEONSELECTEDPRICESHEET") + "!"
      }, disableClose: true
    });
    confirmDialog.keydownEvents().subscribe(e => {
      if (e.code === 'Escape'){
        confirmDialog.close();
      }
    });
    confirmDialog.afterClosed().subscribe((res: any) => {
      if (res) {
        this.ruleService.removeFromSheet(this.sheetRule).then(() => {
          this.getRulesByCustomer();
        });
      }
    });
  }

  saveRule() {
    let sheetIsSet = false;
    if (this.customerRule.defaultPriceCode != this.customerRuleORG.defaultPriceCode || this.customerRule.pllRound != this.customerRuleORG.pllRound ||
          this.customerRule.pllprldm != this.customerRuleORG.pllprldm || this.customerRule.weightprldb != this.customerRuleORG.weightprldb ||
          this.customerRule.weightprvolume != this.customerRuleORG.weightprvolume ||this.customerRule.calcPalletSpaces != this.customerRuleORG.calcPalletSpaces) {
      if (!this.sheetRule || !this.sheetRule.id) {
        this.sheetRule = {licenseRef: this.data.LicenseRef, fk_customerId: this.customerRule.fk_customerId};
        if (this.data.getSelectedPricesheet()) this.sheetRule.fk_pricesheetId = this.data.getSelectedPricesheet().id;
        if (this.customerRule.defaultPriceCode != null || this.customerRule.defaultPriceCode != undefined) this.sheetRule.defaultPriceCode = this.customerRule.defaultPriceCode;
        if (this.customerRule.pllRound != null || this.customerRule.pllRound != undefined) this.sheetRule.pllRound = this.customerRule.pllRound;
        if (this.customerRule.pllprldm != null || this.customerRule.pllprldm != undefined) this.sheetRule.pllprldm = this.customerRule.pllprldm;
        if (this.customerRule.weightprldb != null || this.customerRule.weightprldb != undefined) this.sheetRule.weightprldb = this.customerRule.weightprldb;
        if (this.customerRule.weightprvolume != null || this.customerRule.weightprvolume != undefined) this.sheetRule.weightprvolume = this.customerRule.weightprvolume;
        this.sheetRule.calcPalletSpaces = this.customerRule.calcPalletSpaces;
      }
      sheetIsSet = true;
      debugger;
      this.ruleService.saveOrUpdate(this.sheetRule).then((res) => {
        this.customerRule = {};
        this.customerRuleORG = {};
        this.sheetRule = {};
        this.sheetRuleORG = {};
        this.getRulesByCustomer();
      }).finally(() => {
        this._snackBar.open("Regler Gemt", "Success", {duration: 1000, panelClass: ['snack-bar']});
      });
    }
    if (!sheetIsSet && (this.sheetRule.defaultPriceCode != this.sheetRuleORG.defaultPriceCode || this.sheetRule.pllRound != this.sheetRuleORG.pllRound ||
          this.sheetRule.pllprldm != this.sheetRuleORG.pllprldm || this.sheetRule.weightprldb != this.sheetRuleORG.weightprldb || 
          this.sheetRule.weightprvolume != this.sheetRuleORG.weightprvolume || this.sheetRule.calcPalletSpaces != this.sheetRuleORG.calcPalletSpaces)) {
      this.ruleService.saveOrUpdate(this.sheetRule).then((res) => {
        this.getRulesByCustomer();
      }).finally(() => {
        this._snackBar.open("Regler Gemt", "Success", {duration: 1000, panelClass: ['snack-bar']});
      });
    }
  }

}