<div class="customersInfo">
    <div class="customers" style="width:73vw;" *ngIf="!this.data.License.creditorPrices || this.data.customerPrices || this.data.creditorPrices">
      <div class="buttonGrid">
        <form [formGroup]="formGroup!">
          <div style="display: flex; flex-direction: row; align-content: flex-start; gap: 8px;">
            <mat-form-field style="width:23vw; margin-top:1%;" appearance="outline" *ngIf="!this.data.License.creditorPrices || this.data.customerPrices">
              <!-- CUSTOMERPRICES -->
              <mat-label >{{'CHOOSECUSTOMER' | translate}}</mat-label>
              <input style="font-weight: 400;" type="text" autofocus aria-label="Kunde" matInput
                formControlName="customer" id="customerInput" [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option disabled *ngIf="customerLoading">
                  <mat-spinner style="align-content: center; justify-content: center; margin-left:50px;"
                    diameter="45"></mat-spinner>
                </mat-option>
                <mat-option  *ngFor="let option of (customers$ | async)"
                  [value]="(option.clientObject?.number + ' | ' + option.clientObject?.name)"
                  (click)="selectCustomer(option)" (onSelectionChange)="selectCustomer(option)">
                  {{option.clientObject?.number}} | <span>{{option.clientObject?.name}}</span> |
                  <small>{{option.clientObject?.address}} {{option.clientObject?.zip}} {{option.clientObject?.city}}
                    {{option.clientObject?.country}} </small>
                </mat-option>
              </mat-autocomplete>
              <mat-icon *ngIf="!selectedCustomer" class="primaryBlue" matSuffix>account_circle</mat-icon>
              <mat-icon *ngIf="selectedCustomer" (click)="resetCustomer()" matTooltip="Ryd kunde" class="primaryBlue"
                matSuffix><i class="far fa-times-circle"></i></mat-icon>
            </mat-form-field>
            <!-- CREDITORPRICES -->
            <mat-form-field style="width:23vw; margin-top:1%;" appearance="outline" *ngIf="this.data.creditorPrices">
              <mat-label >{{'CHOOSECREDITOR' | translate}}</mat-label>
              <input style="font-weight: 400;" type="text" autofocus aria-label="Kunde" matInput
                formControlName="creditor" id="creditorInput" [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option disabled *ngIf="customerLoading">
                  <mat-spinner style="align-content: center; justify-content: center; margin-left:50px;"
                    diameter="45"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of (creditors$ | async)"
                  [value]="(option.clientObject?.number + ' | ' + option.clientObject?.name)"
                  (click)="selectCustomer(option)" (onSelectionChange)="selectCustomer(option)">
                  {{option.clientObject?.number}} | <span>{{option.clientObject?.name}}</span> |
                  <small>{{option.clientObject?.address}} {{option.clientObject?.zip}} {{option.clientObject?.city}}
                    {{option.clientObject?.country}} </small>
                  
                </mat-option>
              </mat-autocomplete>
              <mat-icon *ngIf="!selectedCustomer" class="primaryBlue" matSuffix>account_circle</mat-icon>
              <mat-icon *ngIf="selectedCustomer" (click)="resetCustomer()" matTooltip="Ryd kunde" class="primaryBlue"
                matSuffix><i class="far fa-times-circle"></i></mat-icon>
            </mat-form-field>
            <!-- CUSTOMERPRICES -->
            <div class="customerPricesheetActions" *ngIf="selectedCustomer">
              <mat-form-field style="width:15vw; margin-top: 12px;" appearance="outline">
                <mat-label>{{'CHOOSEPRICESHEET' | translate}}</mat-label>
                <input style="font-weight: 400;" type="text" autofocus aria-label="Prisark" matInput
                  [formControl]="pricesheetControl" [matAutocomplete]="auto">
                <!-- <span *ngIf="this.data.selectedPricesheet && pricesheetType"
                style="font-size:10px; color:gray;">{{pricesheetType.name}}</span> -->
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let pricesheet of data.pricesheets" [value]="pricesheet.name"
                    (click)="selectPriceSheet(pricesheet)">
                    {{pricesheet.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matTooltip="Redigér Prisark" class="primaryBlue"
                  *ngIf="data.selectedPricesheet!= null  && (data.selectedPricesheet.fk_pricesheetTypeId>=2  && data.selectedPricesheet.fk_pricesheetTypeId<=5)"
                  [ngClass]="{'pointer': this.data.selectedPricesheet != null}" (click)="editPricesheet($event)" matSuffix>
                  description</mat-icon>
              </mat-form-field>
              <button matTooltip="Nyt Prisark" mat-mini-fab class="stdButton" (click)="newPricesheet()">
                <mat-icon>add</mat-icon>
              </button>
              <button *ngIf="data.selectedPricesheet"
                [disabled]="data.selectedPricesheet!= null  && (data.selectedPricesheet.fk_pricesheetTypeId<2  || data.selectedPricesheet.fk_pricesheetTypeId>5)"
                matTooltip="Gem valgte Prisark" mat-mini-fab class="stdButton" (click)="savePricesheet()">
                <mat-icon>save</mat-icon>
              </button>
              <!-- <button *ngIf="data.selectedPricesheet" matTooltip="Opret en Kopi af Valgte Prisark" mat-mini-fab
              class="stdButton" (click)="copyPricesheet()">
              <mat-icon>file_copy</mat-icon>
            </button> -->
              <button *ngIf="data.selectedPricesheet" matTooltip="Slet Valgte Prisark" mat-mini-fab color="warn"
                [disabled]="data.selectedPricesheet!= null  && (data.selectedPricesheet.fk_pricesheetTypeId<2  || data.selectedPricesheet.fk_pricesheetTypeId>5)"
                style="color:white; border-radius: 10px;" (click)="deletePricesheet(data.selectedPricesheet!)">
                <mat-icon>delete</mat-icon>
              </button>
              <button class="stdButton" mat-raised-button (click)="openCustomerSettings()">{{'SETUP' | translate}}</button>
            </div>
          </div>
        </form>
      </div>

      <div class="dateFlex">
        <mat-form-field *ngIf="data.selectedPricesheet" style="width:120px; margin:4px;">
          <mat-label>{{'CHOOSEDATE' | translate}}</mat-label>
          <mat-select [value]="data.selectedDate" style="font-weight:450;">
            <mat-option *ngFor="let zoneDate of data.ZoneDates" [value]="zoneDate" (click)="chooseDate(zoneDate)">{{
              zoneDate |
              date: 'dd/MM/yyyy'}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button *ngIf="data.selectedPricesheet" class="stdButton" mat-raised-button (click)="AddNewDate()">{{'NEWDATE' |
          translate}}</button>
        <button *ngIf="data.selectedDate && data.selectedPricesheet && data.ZoneDates && data.ZoneDates.length > 1"
          mat-raised-button color="warn" (click)="removeDate()">Fjern dato</button>
      </div>
      <div *ngIf="selectedCustomer">
        <app-pricesheet [customer]="selectedCustomer"></app-pricesheet>
      </div>
    </div>
    <div *ngIf="selectedCustomer && (!this.data.License.creditorPrices || this.data.customerPrices || this.data.creditorPrices)" class="mat-elevation-z5 customerRules" style="overflow-x:hidden;">
      <mat-tab-group mat-stretch-tabs style="max-width:40vw; width:40vw;">
        <mat-tab [label]="'PRODUCTS' | translate">
          <div class="mat-elevation-z1 tab">
            <app-products style="min-height:300px;">
            </app-products>
          </div>
        </mat-tab>
        <mat-tab [label]="'ADDITIONS' | translate">
          <div class="mat-elevation-z1 tab">
            <app-additions style="width:65%;" [customer]="selectedCustomer"></app-additions>
          </div>
        </mat-tab>
        <mat-tab *ngIf="data.selectedPricesheet && data.selectedPricesheet.fk_pricesheetTypeId != 1"
          [label]="'RETURNCALC' | translate">
          <div class="mat-elevation-z1">
            <app-price-calc [customer]="selectedCustomer"></app-price-calc>
          </div>
        </mat-tab>
        <mat-tab *ngIf="data.selectedPricesheet" [label]="'RECALCRULES' | translate">
          <app-customer-rules style="width: 100%;" [customer]="selectedCustomer"
            [pricesheet]="data.selectedPricesheet">
          </app-customer-rules>
        </mat-tab>
      </mat-tab-group>
  </div>
</div>
<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>