<h1 mat-dialog-title
  style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
  {{'EDITPRICESHEET' | translate}}</h1>
<mat-dialog-content style="height:150px; display:flex; flex-direction: column; justify-content: flex-start;">
  <div fxLayout="row">
    <mat-form-field style="width: 100%;">
      <mat-label>{{'NAME' | translate}}</mat-label>
      <input [(ngModel)]="selectedPricesheet.name" matInput />
    </mat-form-field>
  </div>
  <div fxLayour="row">

  </div>
  <div fxLayout="row">
    {{'HTFROMTOZIP' | translate}}
    <mat-slide-toggle style="align-content: right;" [(ngModel)]="selectedPricesheet.htFromToZip"></mat-slide-toggle>
  </div>
  <div fxLayout="row" style="margin-top:10px;">
    <mat-form-field style="width:100%;">
      <mat-label>Vælg Prisark type</mat-label>
      <mat-select [(ngModel)]="chosenPricesheetType" name="name">
        <mat-option *ngFor="let type of pricesheetTypes" [value]="type">
          {{type.name!.toUpperCase() | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
  <button mat-raised-button (click)="close()" style="text-shadow: none;">{{'CLOSE' | translate}}</button>
  <button mat-raised-button [disabled]="selectedPricesheet.length == 0"
    style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'SAVE' | translate}}</button>
</mat-dialog-actions>