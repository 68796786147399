<mat-toolbar class="mat-elevation-z10" style="width:102.05% !important; position: relative;" color="primary" *ngIf="isLoggedIn$ | async as isLoggedIn">
  <button mat-button routerLink="/" style="font-size:20px; text-shadow:-1px 1px black;"
    (contextmenu)="onRightClick($event, {content: ''})" [matMenuTriggerFor]="clientMenu" matTooltip="{{getMainToolTip()}}">
    <mat-icon class="mainIcon">local_shipping</mat-icon>
    {{'PRICEENGINE' | translate}}
    <mat-icon matSuffix style="margin-top: -4px;" class="fa fa-caret-down " *ngIf="this.data.License.creditorPrices"></mat-icon>
  </button>
  <mat-menu #clientMenu="matMenu">
    <div *ngIf="this.data.License.creditorPrices">
      <button style="text-shadow: none;" class="headerBtn" mat-menu-item (click)="priceSelect('Customer')" >
        <mat-icon style="font-size:18px;"><i class="fas fa-user-circle iconColor"></i></mat-icon>
        <span>{{'CUSTOMERPRICES' | translate}}</span>
      </button>
      <button style="text-shadow: none;" class="headerBtn" mat-menu-item (click)="priceSelect('Creditor')" >
        <mat-icon style="font-size:18px;"><i class="fas fa-truck iconColor"></i></mat-icon>
        <span>{{'CREDITORPRICES' | translate}}</span>
      </button>
    </div>
  </mat-menu>
  <span
    style="position: absolute; top:36px; left:90px; font-size: small; text-shadow:-1px 1px black; font-style:oblique;">
    {{data.customerChosen}}
  </span>
  
  <span class="fill-remaining-space">&nbsp;|</span>
  
  <button [ngClass]="{ 'active' : isValue == 1 }" (click)="toggle(1)" class="headerBtn textShadow" routerLink="/prices"
    mat-button>
    <mat-icon style="padding:5px;">table_chart</mat-icon><span>{{'PRICES' | translate}}</span>
  </button>

  <button [ngClass]="{ 'active' : isValue == 2 }" (click)="toggle(2)" class="headerBtn textShadow"
    routerLink="/standard" mat-button>
    <mat-icon style="padding:5px;">description</mat-icon>{{'STANDARD' | translate}}
  </button>
  <button [ngClass]="{ 'active' : isValue == 3 }" (click)="toggle(3)" class="headerBtn textShadow"
    routerLink="/standard-products" mat-button>
    <mat-icon style="padding:5px; font-size:18px;"><i class="far fa-credit-card"></i></mat-icon>{{'PRODUCTS' |
    translate}}
  </button>
  <button [ngClass]="{ 'active' : isValue == 4 }" (click)="toggle(4)" class="headerBtn" mat-button
    [matMenuTriggerFor]="headerMenu">
    <mat-icon>settings</mat-icon>
    {{'SETUP' | translate}}
  </button> 
  <mat-menu #headerMenu="matMenu">
    <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/terminal">
      <mat-icon style="font-size:18px;"><i class="fas fa-warehouse iconColor"></i></mat-icon>
      <span>{{'TERMINAL' | translate}}</span>
    </button>
    <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/omex">
      <mat-icon style="color:black;">compare_arrows</mat-icon>
      <span>{{'OMEX' | translate}}</span>
    </button>
    <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/collitype">
      <mat-icon class="midFont"><i class='fas fa-dolly iconColor'></i></mat-icon>
      <span>{{'COLLITYPE' | translate}}</span>
    </button>
    <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/collimeasurement">
      <mat-icon class="midFont"><i class="fas fa-ruler-combined iconColor"></i></mat-icon>
      <span>{{'COLLITYPEMEASUREMENT' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="taxes" style="text-shadow: none;">
      <mat-icon style="color:black;"><i class="fas fa-cart-plus"></i></mat-icon>
      {{'TAXES' | translate}}
    </button>
    <mat-menu #taxes="matMenu">
      <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/oil">
        <mat-icon class="midFont"><i class='fas fa-oil-can iconColor'></i></mat-icon>
        <span>{{'OIL' | translate}}</span>
      </button>
      <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/capacityTax">
        <mat-icon class="midFont"><i class="fas fa-truck-moving iconColor"></i></mat-icon>
        <span>{{'CAPACITYTAX' | translate}}</span>
      </button>
      <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/environmentalTax">
        <mat-icon class="midFont"><i class="fab fa-envira iconColor"></i></mat-icon>
        <span>{{'ENVIRONMENTALTAX' | translate}}</span>
      </button>
      <button style="text-shadow: none;" class="headerBtn" mat-menu-item routerLink="/setup/roadTax">
        <mat-icon class="midFont"><i class="fa fa-road iconColor"></i></mat-icon>
        <span>{{'ROADTAX' | translate}}</span>
      </button>
    </mat-menu>
  </mat-menu>
  <button *ngIf="data.customerPrices" [ngClass]="{ 'active' : isValue == 5 }" (click)="toggle(5)" class="headerBtn"
    style="text-shadow:-1px 1px black;" routerLink="/customer" mat-button>
    <mat-icon style="padding:5px; font-size:18px;"><i class="fas fa-user-plus"></i></mat-icon>
    {{'NEWCUSTOMER' | translate}}
  </button>
  <button *ngIf="data.customerPrices" [ngClass]="{ 'active' : isValue == 6 }" (click)="toggle(6)" class="headerBtn" style="text-shadow:-1px 1px black;" routerLink="/allcustomers" mat-button >
    <mat-icon style="padding: 5px; font-size: 18px;" class="fas fa-feather"></mat-icon>
    <span>{{'PRICEADJUSTMENTS' | translate}}</span>
  </button>
  <button *ngIf="data.creditorPrices" [ngClass]="{ 'active' : isValue == 5 }" (click)="toggle(5)" class="headerBtn"
    style="text-shadow:-1px 1px black;" routerLink="/customer" mat-button>
    <mat-icon style="padding:5px; font-size:18px;"><i class="fas fa-cart-plus"></i></mat-icon>
    {{'NEWCREDITOR' | translate}}
  </button>

<!--   <button *ngIf="!isProduction" mat-button class="headerBtn" (click)="openDeveloper()">
    {{'DEVELOPERTOOLS' | translate}}
    <mat-icon style="padding:5px; font-size:18px;"><i class="fas fa-hospital-o"></i></mat-icon>
  </button>
 -->
  <span class="spacer"></span>
  <button class="hvr-icon-wobble-horizontal" style="text-shadow:-1px 1px black;" mat-button
    (click)="logout()">{{'LOGOUT' | translate}}
    <mat-icon class="hvr-icon midFont" style="padding:5px;">logout</mat-icon>
  </button>
</mat-toolbar>

<!-- Context/Right click menu -->
<div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu">
</div>

<!-- No need to translate will be removed.. -->
<mat-menu style="background: var(--primary-blue) !important;" #rightMenu="matMenu">
  <ng-template style="background: var(--primary-blue) !important;" matMenuContent let-item="item">
    <button style="background-color: #337ab7; color:white;" (click)="chooseColor('#337ab7')"
      mat-menu-item>Default</button>
    <button style="background-color: #990000; color:white;" (click)="chooseColor('#990000')" mat-menu-item>Rød</button>
    <button style="background-color: #006600; color:white;" (click)="chooseColor('#006600')" mat-menu-item>Grøn</button>
    <button style="background-color: #4d004d; color:white;" (click)="chooseColor('#4d004d')"
      mat-menu-item>Lilla</button>
    <button style="background-color: gray; color:white;" (click)="chooseColor('gray')" mat-menu-item>Grå</button>
    <button style="background-color: #4d2600; color:white;" (click)="chooseColor('#4d2600')" mat-menu-item>Brun</button>
    <button style="background-color: #e68a00; color:white;" (click)="chooseColor('#e68a00')"
      mat-menu-item>Orange</button>
    <button style="background-color: black; color:white;" (click)="chooseColor('random')" mat-menu-item>Tilfædig ( Eget
      ansvar)</button>
  </ng-template>
</mat-menu>