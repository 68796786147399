<div style="width: calc(100% + 48px); height: 32px; background-color: var(--primary-blue); color: white; margin-top: -24px; margin-left: -24px; display: flex; flex-direction: row; align-items: center; justify-content: center;">
    <span style="letter-spacing: 2px; text-transform: uppercase;">{{'POSTPRICEADJUSTMENT' | translate}}</span>
</div>
<div style="width: 100%; height: calc(100% - 16px - 48px); margin-top: 8px; position: relative;">
    <div style="width: 100%; height: 32px; display: flex; flex-direction: row; font-weight: 700;">
        <div style="width: 5%;">{{'NUMBER' | translate}}</div>
        <div style="width: 14%;">{{'NAME' | translate}}</div>
        <div style="width: 6%;">{{'DATE' | translate}}</div>
        <div style="width: 48%;">{{'PRICESHEETS' | translate}}</div>
        <div style="width: 30%;">{{'STATUS' | translate}}</div>
        <div style="width: 2%;">{{'RESULT' | translate}}</div>
    </div>
    <div style="width: 100%; height: 1px; background-color: var(--primary-blue);"></div>
    <div style="width: 100%; display: flex; flex-direction: column; align-items: center;" *ngFor="let price of priceAdjustments; let i = index;">
        <div style="width: 100%; min-height: 28px; display: flex; flex-direction: row; align-items: center; cursor: pointer;" [ngStyle]="{'background-color':  i % 2 == 0 ? '#cecece' : 'Transparent' }">
            <div style="width: 5%;">{{price.clientEntity.number}}</div>
            <div style="width: 14%;">{{price.clientEntity.name}}</div>
            <div style="width: 6%;">{{price.adjustmentDate | date: 'dd-MM-yyyy'}}</div>
            <div style="width: 50%; display: flex; flex-direction: row; gap: 8px; justify-content: flex-start; flex-wrap: wrap;">
                <div  *ngFor="let sheet of price.sheetsAffected; let j = index;">
                    <div style="background-color: var(--primary-blue); color: white; border-radius: 8px; white-space: nowrap; display: flex; flex-direction: row; align-items: center; cursor: default;" [ngStyle]="{'background-color': sheet.doAdjust ? 'var(--primary-blue)' : '#bebece'}">
                        <span style="margin-left: 4px; margin-right: 4px;">{{sheet.name}}</span>
                        <mat-icon *ngIf="sheet.doAdjust" style="font-size: 14px; margin-top: 8px; cursor: pointer;" (click)="clickClosePricesheet(price, sheet)" matSuffix>highlight_off</mat-icon>
                        <mat-icon *ngIf="!sheet.doAdjust" style="font-size: 14px; margin-top: 8px; cursor: pointer;" (click)="clickOpenPricesheet(price, sheet)" matSuffix>task_alt</mat-icon>
                    </div>
                </div>
                <div *ngIf="price.hasOwnPricedProducts" style="background-color: orange; color: white; border-radius: 8px; white-space: nowrap; display: flex; flex-direction: row; align-items: center; cursor: default;" [ngStyle]="{'background-color': price.adjustPricedProducts ? 'orange' : '#bebece'}">
                    <span style="margin-left: 4px; margin-right: 4px;">{{'PRODUCTS' | translate}}</span>
                    <mat-icon *ngIf="price.adjustPricedProducts" style="font-size: 14px; margin-top: 8px; cursor: pointer;" (click)="clickCloseProduct(price)" matSuffix>highlight_off</mat-icon>
                    <mat-icon *ngIf="!price.adjustPricedProducts" style="font-size: 14px; margin-top: 8px; cursor: pointer;" (click)="clickOpenProduct(price)" matSuffix>task_alt</mat-icon>
                </div>
            </div>
            <div style="width: 30%; display: flex; flex-direction: row; gap: 8px; justify-content: flex-start; flex-wrap: wrap;">
                <div *ngFor="let msg of price.adjustMessages">
                    <div [ngStyle]="{'background-color': msg.type == 1 ? 'var(--primary-red)' : 'var(--primary-green)'}" style="background-color: var(--primary-red); color: white; border-radius: 8px; white-space: nowrap; display: flex; flex-direction: row; align-items: center; cursor: default;">
                        <span style="margin: 4px;">{{msg.text}}</span>
                    </div>
                </div>
            </div>
            <div style="width: 2%; display: flex; flex-direction: row; gap: 8px; justify-content: flex-start; flex-wrap: wrap;">
                <div *ngFor="let result of price.saveStatus">
                    <div [ngStyle]="{'background-color': result.type == 1 ? 'var(--primary-red)' : 'var(--primary-green)'}" style="color: white; border-radius: 8px; white-space: nowrap; display: flex; flex-direction: row; align-items: center; cursor: default;">
                        <span style="margin: 4px;">{{result.text}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: 8px;"></div>
    </div>
</div>
<div style="width: 100%; height: 32px; display: flex; flex-direction: row; justify-content: flex-end; gap: 8px">
    <button *ngIf="!hasClickedAdjust" mat-raised-button style="background-color: var(--primary-blue); color: white;" (click)="clickJustify()" [disabled]="!getOKStatus()" [ngStyle]="{'cursor' : getOKStatus() ?  'pointer' : 'not-allowed'}">{{'ADJUST'|translate}}</button>
    <button mat-raised-button style="background-color: var(--primary-red); color: white;" (click)="clickCancel()">{{'CLOSE'|translate}}</button>
</div>
<app-mat-spinner-overlay *ngIf="dataShare.showSpinner === true" [overlay]="true">
</app-mat-spinner-overlay>
