import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyService } from "src/app/shared/api/currency.service";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { ProductUnitService } from "src/app/shared/api/productunit.service";
import { Currency } from "src/app/shared/Models/currency";
import { ProductPrice } from "src/app/shared/Models/productPrice";
import { ProductUnit } from "src/app/shared/Models/productUnit";
import { ConfirmDialogComponent } from "src/app/shared/util/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'standard-products-dialog',
    templateUrl: './standard-products.dialog.html',
    styleUrls: ['./standard-products.dialog.scss'],
})
export class StandardProductDialog {
    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <ProductPrice>{ product: { name: '', erpCode: '', copyAlways: false }, currency: {} };
    currencies: any;
    productUnits: ProductUnit[] = [];
    constructor(public dialogRef: MatDialogRef<StandardProductDialog>, private data: DataShareService, private currencyService: CurrencyService, private productUnitService: ProductUnitService, private dialog: MatDialog, private translateService: TranslateService) {
        let currency: Currency = {
            licenseRef: this.data.LicenseRef
        };
        this.currencyService.getCurrencies(currency).then((res: any) => {
            if (res) {
                this.currencies = res.modelObject;
            }
        });
        let prodUnit: ProductUnit = {
            licenseRef: this.data.LicenseRef
        };
        this.productUnitService.getAll(prodUnit).then((res: any) => {
            if (res) {
                this.productUnits = res.modelObject;
            }
        })
    }

    save() {
        this.dialogRef.close(this.dialogData);
    }

    close() {
        if (this.dialogData!.product!.name != '') {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant('CLOSENEWPRODUCTDIALOGTITLE'),
                    message: this.translateService.instant('FOUNDDATAERRORMESSAGE')
                }
            });

            confirmDialog.afterClosed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.dialogRef.close();
                }
            });

        }
        else {
            this.dialogRef.close();
        }
    }

    compareByOptionId(idFist: any, idSecond: any) {
        return idFist && idSecond && idFist.id == idSecond.id;
    }
}
