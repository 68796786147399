import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsutilService {

  constructor() { }

  convertTextDate(value?: string): string{
    if (value) {
      let arr: string[] = [];
      if (value.includes("-")) {
        arr = value.split("-");
      }
      if (value.includes("/")) {
        arr = value.split("/");
      }
      if (arr && arr.length == 3) {
        let retval = arr[2] + "/" + arr[1] + "/" + arr[0];
        return retval;
      }
    }
    return "";
  }

  isCorrectDate(date: Date): boolean {
    return date instanceof Date && isFinite(+date);
  
    // return date instanceof Date && !isNaN(+date);
  }
}
