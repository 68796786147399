import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { Customer } from 'src/app/shared/Models/customer';
import { roadtax } from 'src/app/shared/Models/roadtax';

@Component({
  selector: 'app-roadtaxeditdialog',
  templateUrl: './roadtaxeditdialog.component.html',
  styleUrls: ['./roadtaxeditdialog.component.scss']
})
export class RoadtaxeditdialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RoadtaxeditdialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: roadtax,
    private customerService: CustomerService,
    private dataShare: DataShareService,

  ) { this.editRoadtax = data; }

  editRoadtax?: roadtax;
  customers: any;

  ngOnInit(): void {
    this.getCustomers();
  }

  compareByOptionId(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst.id == refSecond.id;
  }

  getCustomers(): void {
    let customer: Customer;
    customer = {
        licenseRef: this.dataShare.LicenseRef,
        includeReturnCalcMethods: true,
        includeCustomerRules: true,
        includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
        let customers = res.modelObject;
        customers.sort((a: Customer, b: Customer) => {
            if (a.clientObject == undefined && b.clientObject == undefined) return 0;
            if (a.clientObject == undefined) return -1;
            if (b.clientObject == undefined) return 1;
            if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
                (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
            if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
            if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
            if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
                return -1;
            }
            if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        for (let i = 0; i < customers.length; i++) {
            if (customers[i].clientObject?.name) {
                customers[i].name = customers[i].clientObject.name;
            }
        }
        this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
        this.customers = this.customers.filter((x: any) => x.name);
        this.customers.unshift({ name: 'Ingen valgt' });
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.editRoadtax);
  }
}
