<div fxLayout="row" style="max-width:100%; margin:1%;">
    <div style="display:flex; justify-content: space-between; margin: 1%; align-items: center;">
        <h2 style="margin:0px;" class="header">{{'CLIENTSETTINGS'|translate}}</h2>
    </div>
    <div style="display: flex; flex-direction: column;">
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.doNotChangeColliType">{{'DONOTCHANGECOLLITYPE' |translate}}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.doNotUsePriceCode">{{'DONOTUSEPRICECODE' |translate}}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.doNotCalcCollies">{{'DONOTCALCCOLLIES' |translate}}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.calcLdm">{{'CALCLDM' |translate}}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.calcColliesIfZeroVol">{{'CALCCOLLIESIFZEROVOL' |translate}}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.calcColliesIfZeroldm">{{'CALCCOLLIESIFZEROLDM' |translate}}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="selectedCustomer!.clientObject!.calcVolumePKK">{{'CALCVOLUMEPKK' |translate}}</mat-checkbox>
        </div>
    </div>
    <div style="display:fle; flex-direction: row;">
        <button mat-raised-button (click)="saveAll()" class="saveBtn">{{'SAVE' | translate}}
        </button>
    </div>
</div>