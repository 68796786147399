<div style="display: flex; justify-content: flex-start; align-items: center">
    <!-- <mat-form-field style="width:12%; font-size:14px; min-width:220px;" appearance="outline">
        <mat-label>{{'FROMCOUNTRY' | translate}}</mat-label>
        <mat-select [(value)]="chosenCountryFrom">
            <mat-option *ngFor="let count of countries" [value]="count.name" (click)="selectCountryFrom(count)">
                {{count.isocode}}
            </mat-option>
        </mat-select>
        <mat-icon style="color: var(--primary-blue);" matSuffix>language</mat-icon>
    </mat-form-field>
    <mat-form-field style="width:12%; font-size:14px; min-width:220px; margin-left:6px;" appearance="outline">
        <mat-label>{{'TOCOUNTRY' | translate}}</mat-label>
        <mat-select [(value)]="chosenCountryTo">
            <mat-option *ngFor="let count of countries" [value]="count.name" (click)="selectCountryTo(count)">
                {{count.isocode}}
            </mat-option>
        </mat-select>
        <mat-icon style="color: var(--primary-blue);" matSuffix>language</mat-icon>

    </mat-form-field> -->
    <h3 class="header">{{'ZONES'|translate}} </h3>
    <mat-slide-toggle *ngIf="pricesheet" style="margin-left:30%;" [(ngModel)]="showHidePrint"
        (click)="data.hidePrintToggle()">{{'SHOWHIDDEN' | translate}}</mat-slide-toggle>
</div>
<div style="width:82% !important;">
    <!-- Ignore errors Below-->
    <ejs-spreadsheet [allowNumberFormatting]='true' (created)="created()" (cellEdit)="cellEdit($event)"
        (dataSourceChanged)='dataSourceChanged($event)' #spreadsheet [allowConditionalFormat]="false"
        [showFormulaBar]='false' [showRibbon]='false' [showSheetTabs]='false' [scrollSettings]="{ enableVirtualization: false}">
        <e-sheets>
            <e-sheet name="Zoner">
                <e-ranges>
                    <e-range [dataSource]='zones'>
                    </e-range>
                </e-ranges>
                <e-rows>
                    <e-row>
                        <e-cells>
                        </e-cells>
                    </e-row>
                </e-rows>
                <e-columns>
                    <e-column [width]=1></e-column>
                    <e-column [width]=100></e-column>
                    <e-column [width]=100></e-column>
                    <e-column [width]=100></e-column>
                    <e-column [width]=100></e-column>
                    <e-column [width]=100></e-column>
                    <e-column [width]=100></e-column>
                    <e-column [width]=80></e-column>
                    <e-column [width]=140></e-column>
                    <e-column [width]=110></e-column>
                </e-columns>
            </e-sheet>
            <e-sheet name="CountryFrom" state="VeryHidden">
                <e-ranges>
                    <e-range [dataSource]='countries'></e-range>
                </e-ranges>
            </e-sheet>
            <e-sheet name="HandlingType" state="VeryHidden">
                <e-ranges>
                    <e-range [dataSource]='handlingTypes'></e-range>
                </e-ranges>
            </e-sheet>
            <e-sheet name="Terminals" state="VeryHidden">
                <e-ranges>
                    <e-range [dataSource]='terminals'></e-range>
                </e-ranges>
            </e-sheet>
        </e-sheets>
    </ejs-spreadsheet>
</div>