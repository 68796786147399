import { Injectable } from '@angular/core';
import { ClientapiService } from './clientapi.service';
import { DataShareService } from './datashare.service';
import { Client } from '../Models/client';

@Injectable({
  providedIn: 'root'
})
export class ClientshareService {

  constructor(
    private clientApi: ClientapiService,
    private dataShare: DataShareService,

  ) { }

  clients: Client[] = [];
  clientsContainer: Client[] = [];
  filteredClients: Client[] = [];
  filterValue = '';

  async initialize() {
    let dummy = {licenseRef: this.dataShare.License.licenseRef};
     await this.clientApi.getAll(dummy).then((res: any) => {
      if (res && res.modelObject) {
        this.clients = res.modelObject;
        this.clients.sort((a, b) => a.name! > b.name! ? 1 : a.name == b.name ? 0 : -1);
      }
    });
  }

  setFilter() {
    if (this.filterValue) {
      this.filteredClients = this.clientsContainer.filter(
        x => (x.name && x.name.toLowerCase().includes(this.filterValue.toLowerCase())) || 
        (x.nickname && x.nickname.toLowerCase().includes(this.filterValue.toLowerCase()))  ||
        x.number.toLowerCase().includes(this.filterValue.toLowerCase())
      );
    }else{
      this.filteredClients = this.clientsContainer;
    }
  }
}
