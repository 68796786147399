import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { CustomerSettingsDialog } from '../pricesheet/customer-settings-dialog/customer-settings.dialog';
import { PriceSheetDialog } from '../pricesheet/pricesheet-dialog/pricesheet.dialog';
import { CountryService } from '../shared/api/country.service';
import { CurrencyService } from '../shared/api/currency.service';
import { CustomerService } from '../shared/api/customer.service';
import { DataShareService } from '../shared/api/datashare.service';
import { HandlingTypeService } from '../shared/api/handlingtype.service';
import { MeasureUnitService } from '../shared/api/measure.service';
import { PriceCalcUnitService } from '../shared/api/pricecalcunit.service';
import { PricesheetService } from '../shared/api/pricesheet.service';
import { PricesheetpricesService } from '../shared/api/pricesheetprices.service';
import { PricesheetTypeService } from '../shared/api/pricesheettype.service';
import { PricesheetzonesService } from '../shared/api/pricesheetzones.service';
import { ProductService } from '../shared/api/product.service';
import { TerminalService } from '../shared/api/terminal.service';
import { Country } from '../shared/Models/country';
import { Currency } from '../shared/Models/currency';
import { Customer } from '../shared/Models/customer';
import { MeasureUnit } from '../shared/Models/measureUnit';
import { PriceCalcUnit } from '../shared/Models/priceCalcUnit';
import { Pricesheet } from '../shared/Models/pricesheet';
import { PricesheetPrice } from '../shared/Models/pricesheetPrice';
import { PricesheetType } from '../shared/Models/pricesheetType';
import { HandlingType, PricesheetZone } from '../shared/Models/pricesheetZone';
import { Product } from '../shared/Models/product';
import { Terminal } from '../shared/Models/terminal';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';
import Utils from '../shared/util/internalMethodHelper';
import { EditPricesheetDialog } from './edit-pricesheet-dialog/edit-pricesheet.dialog';
import { NewPricesheetDateDialog } from './new-pricesheet-date-dialog/new-pricesheet-date.dialog';
import { NewPricesheetDialog } from './new-pricesheet-dialog/new-pricesheet.dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  customers: Customer[] = [];
  selectedCustomer: Customer | undefined;
  displayedCustomerColumns: string[] = ["name", "address", "zip", "city", "country"];
  dataSource = <MatTableDataSource<Customer>>{};
  customControl = new FormControl();
  filteredOptions: Observable<Customer[]>;
  pricesheetControl = new FormControl();
  selectedPricesheet: any;
  savePricesheetVariable: any;
  loading = false;
  type: any;
  countries: Country[] = [];
  handlingTypes: any;
  countryRequest = <Country>{};
  currencies: Currency[] = [];
  measureUnits: MeasureUnit[] = [];
  products: Product[] = [];
  terminals: Terminal[] = [];
  currencyRequest = <Currency>{};
  newPricesheetDate: any;
  isRunning: boolean = false;
  subscriptions: any;
  priceCalcUnits: PriceCalcUnit[] = [];
  customerLoading = false;
  pricesheetTypes: PricesheetType[] = [];

  formGroup?: FormGroup;
  customers$?: Observable<Customer[]>;
  creditors$?: Observable<Customer[]>;
  searchCustomer: Customer = {};

  pricesheetType: any;

  constructor(private customerService: CustomerService,
    public countryService: CountryService,
    private dialog: MatDialog,
    public pricesheetTypeService: PricesheetTypeService,
    public priceSheetService: PricesheetService,
    private datePipe: DatePipe,
    public data: DataShareService,
    private router: Router,
    private productService: ProductService,
    private currencyService: CurrencyService,
    private _snackBar: MatSnackBar,
    private pricesheetZoneService: PricesheetzonesService,
    private pricesheetPriceService: PricesheetpricesService,
    private handlingTypeService: HandlingTypeService,
    private measureUnitService: MeasureUnitService,
    private terminalService: TerminalService,
    private priceCalcUnitService: PriceCalcUnitService,
    private pricesheetService: PricesheetService,
    private translate: TranslateService,
    private fb: FormBuilder) {
    this.searchCustomer.licenseRef = this.data.LicenseRef;
    this.searchCustomer.includeReturnCalcMethods = true;
    this.formGroup = this.fb.group({
      customer: [null],
      creditor: [null]
    })
    this.customers$ = this.formGroup.get('customer')?.valueChanges.pipe(
      distinctUntilChanged(), // Has to be new value
      debounceTime(500), // To make sure it does not search on each key input ( Waits half a second to search)
      filter((val: any) => !!val), // (double) Checks if it is a new value - Fuck yeah!
      switchMap(name => this.customerService.searchCustomers(this.searchCustomer, name)) // switch and get result from client search. 
    );
    this.creditors$ = this.formGroup.get('creditor')?.valueChanges.pipe(
      distinctUntilChanged(), // Has to be new value
      debounceTime(500), // To make sure it does not search on each key input ( Waits half a second to search)
      filter((val: any) => !!val), // (double) Checks if it is a new value - Fuck yeah!
      switchMap(name => this.customerService.searchCreditors(this.searchCustomer, name)) // switch and get result from client search. 
    );
    this.filteredOptions = new Observable<Customer[]>();
    if (!this.data.LicenseRef) {
      this.router.navigateByUrl('');
    }
  }
  ngOnDestroy(): void {
    this.data.ClearData();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

  }

  ngOnInit(): void {
    // this.loading = true;
    this.customerLoading = true;
    this.fetchData();
    this.subscriptions = this.data.DoneSerializing.subscribe((result) => {
      if (!result) {
        this._snackBar.open("Der opstod en fejl da prisarket skulle gemmes", "Success", { duration: 2000, panelClass: ['snack-bar'] });
        this.isRunning = false;
        this.loading = false;
        return
      }
      if (!this.isRunning) {
        this.isRunning = true;
        this.savePricesheetVariable = this.data.getSelectedPricesheet();
        if(!this.savePricesheetVariable.returnCalcMethods || this.savePricesheetVariable.returnCalcMethods.length==0){
          if (this.selectedCustomer) {
            if (this.selectedCustomer.returnCalcMethods != undefined && this.selectedCustomer.returnCalcMethods.length > 0) {
              this.selectedCustomer.returnCalcMethods.sort((a: any, b: any) => (a.fk_pricesheetId > b.fk_pricesheetId) ? -1 : 1);
      
              for (let i = 0; i < this.selectedCustomer.returnCalcMethods.length; i++) {
                if (this.selectedCustomer.returnCalcMethods[i].fk_pricesheetId == this.data.getSelectedPricesheet().id) {
                  this.savePricesheetVariable.returnCalcMethods.push(this.selectedCustomer.returnCalcMethods[i]);
                  break;
                }
                if (this.selectedCustomer.returnCalcMethods[i].fk_pricesheetId == null) {
                  this.savePricesheetVariable.returnCalcMethods.push(this.selectedCustomer.returnCalcMethods[i]);
                  break;
                }
              }
            }
          }
        }
        this.savePricesheetVariable.pricesheetZones = this.mapWorkZones();
        this.savePricesheetVariable.pricesheetPrices = this.mapWorkPrices();
        this.savePricesheetVariable.fk_pricesheetTypeId = this.data.getSelectedPricesheet().fk_pricesheetTypeId;
        this.priceSheetService.savePricesheet(this.savePricesheetVariable!).then((res: any) => {
          this.data.ClearData();
          this.getPriceSheets();
          if (res) {
            this.selectPriceSheet(res.modelObject);
            this.pricesheetControl.patchValue(res.modelObject.name);
          }
          this.isRunning = false;
          this.loading = false;
        }).finally(() => {
          this._snackBar.open("Prisarket er Gemt", "Success", { duration: 2000, panelClass: ['snack-bar'] });
        });
        this.newPricesheetDate = null;
      }
    });
  }
  fetchData() {
    this.getCustomers();
    this.getCountries();
    this.getAllCurrencies();
    this.getMeasureUnits();
    this.getHandlingTypes();
    this.getAllProducts();
    this.getTerminals();
    this.getAllPriceCalcUnit();
  }

  resetCustomer() {
    this.loading = true;
    let ctrl: any = document.getElementById("customerInput");
    if (ctrl) {
      ctrl.value = '';
    }
    this.customControl.patchValue('');
    this.pricesheetControl.patchValue('');
    this.selectedCustomer = {};
    this.data.selectedCustomer = {};
    this.data.ClearData();
    this.loading = false;
  }

  mapWorkZones() {
    const tmpZoneArray: PricesheetZone[] = [];
    for (let i = 0; i < this.data.WorkZones.length; i++) {

      // Check Country To 
      if (!Utils.isObject(this.data.WorkZones[i].countryTo)) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.countries[j].isocode == this.data.WorkZones[i].countryTo) {
            this.data.WorkZones[i].countryTo = this.countries[j];
          }
        }
      }
      // Check Country From
      if (!Utils.isObject(this.data.WorkZones[i].countryFrom)) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.countries[j].isocode == this.data.WorkZones[i].countryFrom) {
            this.data.WorkZones[i].countryFrom = this.countries[j];
          }
        }
      }
      // Check HandlingType

      if (!Utils.isObject(this.data.WorkZones[i].handlingType)) {
        for (let k = 0; k < this.handlingTypes.length; k++) {
          if (this.handlingTypes[k].name == this.data.WorkZones[i].handlingType) {
            this.data.WorkZones[i].handlingType = this.handlingTypes[k];
          }
        }
      }
      // Check Terminal
      if (!Utils.isObject(this.data.WorkZones[i].terminal)) {
        for (let l = 0; l < this.terminals.length; l++) {
          if (this.terminals[l].name == this.data.WorkZones[i].terminal) {
            this.data.WorkZones[i].terminal = this.terminals[l];
          }
        }
      }

      const wZone: PricesheetZone = {
        collitypeCategory: this.data.WorkZones[i].collitypeCategory,
        id: this.data.WorkZones[i].id ? this.data.WorkZones[i].id : null,
        fromZipFrom: this.data.WorkZones[i].fromZipFrom?.toString(),
        fromZipTo: this.data.WorkZones[i].fromZipTo?.toString(),
        toZipFrom: this.data.WorkZones[i].toZipFrom?.toString(),
        toZipTo: this.data.WorkZones[i].toZipTo?.toString(),
        zoneNo: this.data.WorkZones[i].zoneNo?.toString(),
        validFrom: this.newPricesheetDate != null ? this.newPricesheetDate : this.data.selectedDate,
        licenseRef: this.data.LicenseRef,
        fk_pricesheetId: this.data.getSelectedPricesheet()?.id,
        fk_countryFromId: this.data.WorkZones[i].countryFrom?.id!,
        fk_countryToId: this.data.WorkZones[i].countryTo?.id!,
        handlingType: this.data.WorkZones[i].handlingType,
        terminal: this.data.WorkZones[i].terminal,
        fk_terminalId: this.data.WorkZones[i].terminal?.id
      };
      tmpZoneArray.push(wZone);
    }
    return tmpZoneArray;
  }

  mapWorkPrices() {
    const tmpPriceArray: PricesheetPrice[] = [];
    for (let j = 0; j < this.data.WorkPrices.length; j++) {

      // Check Currency
      if (!Utils.isObject(this.data.WorkPrices[j].currency)) {
        for (let k = 0; k < this.currencies.length; k++) {
          if (this.currencies[k].isocode == this.data.WorkPrices[j].currency) {
            this.data.WorkPrices[j].currency = this.currencies[k];
          }
        }
      }
      // Check MeasureUnit
      if (!Utils.isObject(this.data.WorkPrices[j].measureUnit)) {
        for (let a = 0; a < this.measureUnits.length; a++) {
          if (this.measureUnits[a].name == this.data.WorkPrices[j].measureUnit) {
            this.data.WorkPrices[j].measureUnit = this.measureUnits[a];
          }
        }
      }
      // Check Product
      if (!Utils.isObject(this.data.WorkPrices[j].product)) {
        for (let b = 0; b < this.products.length; b++) {
          if (this.products[b].name == this.data.WorkPrices[j].product) {
            this.data.WorkPrices[j].product = this.products[b];
          }
        }
      }
      // Check PriceCalcUnit
      if (!Utils.isObject(this.data.WorkPrices[j].priceCalcUnit)) {
        for (let c = 0; c < this.priceCalcUnits.length; c++) {
          if (this.priceCalcUnits[c].name == this.data.WorkPrices[j].priceCalcUnit) {
            this.data.WorkPrices[j].priceCalcUnit = this.priceCalcUnits[c];
          }
        }
      }

      const wPrice: PricesheetPrice = {
        id: this.data.WorkPrices[j].id ? this.data.WorkPrices[j].id : null,
        collitypeRef: this.deserializeColliType(this.data.WorkPrices[j].collitypeRef),
        licenseRef: this.data.LicenseRef,
        zoneNo: this.data.WorkPrices[j].zoneNo?.toString(),
        intervalFrom: this.data.WorkPrices[j].intervalFrom,
        intervalTo: this.data.WorkPrices[j].intervalTo,
        price: this.data.WorkPrices[j].price,
        hidePrint: false, // Hardcoded to false as it is required in backend
        validFrom: this.newPricesheetDate != null ? this.newPricesheetDate : this.data.selectedDate,
        fk_pricesheetTypeId: 1,
        fk_pricesheetId: this.data.getSelectedPricesheet()?.id,
        fk_currencyId: this.data.WorkPrices[j].currency?.id!,
        fk_measureUnitId: this.data.WorkPrices[j].measureUnit?.id!,
        fk_productId: this.data.WorkPrices[j].product?.id!,
        fk_pricecalcUnitId: this.data.WorkPrices[j].priceCalcUnit?.id!,
        product: this.data.WorkPrices[j].product,
        text: this.data.WorkPrices[j].text,
        priceCode: this.data.WorkPrices[j].priceCode,
        address: this.data.WorkPrices[j].address,
        zipcode: this.data.WorkPrices[j].zipcode,
        countrycode: this.data.WorkPrices[j].countrycode,
      };
      tmpPriceArray.push(wPrice);
    }
    return tmpPriceArray;
  }

  deserializeColliType(colliRef: any) {
    if (this.data.ColliTypes) {
      for (let i = 0; i < this.data.ColliTypes.length; i++) {
        if (colliRef == this.data.ColliTypes[i].name) {
          return this.data.ColliTypes[i].ref;
        }
      }
    }
  }


  getTerminals() {
    let terminal: Terminal = {
      licenseRef: this.data.LicenseRef
    };
    this.terminalService.getAll(terminal).then((res) => {
      this.terminals = res.modelObject;
    })
  }

  getCountries() {
    this.countryRequest.licenseRef = this.data.LicenseRef;
    this.countryService.getCountries(this.countryRequest).then((res: any) => {
      this.countries = res.modelObject;
      this.countries.sort((a, b) => (a.isocode! > b.isocode!) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getAllPriceCalcUnit() {
    let priceCalcUnit: PriceCalcUnit = {
      licenseRef: this.data.LicenseRef,
      name: " " // required name
    };
    this.priceCalcUnitService.getAll(priceCalcUnit).then((res) => {
      this.priceCalcUnits = res.modelObject;
    });
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    var customer = this.customers.filter(option => option.clientObject?.name?.toLowerCase().includes(filterValue));
    customer = customer.concat(this.customers.filter(option => option.clientObject?.number?.toString().includes(filterValue)));
    return customer;
  }
  saveNewPricesheet(pricesheet: Pricesheet) {
    if (this.data.WorkZones && this.data.WorkPrices) {
      this.data.setSelectedPricesheet( pricesheet);
      this.data.startSerialization();
    }
  }


  deletePricesheet(pricesheet: Pricesheet) {
    if (pricesheet) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: "16%",
        maxHeight: '200px',
        height: "18%",
        data: {
          title: this.translate.instant('AREYOUSUREQUESTION'),
          message: this.translate.instant('YOUAREABOUTTODELETE') + ":  <b>" + pricesheet.name + "</b>"
        }, disableClose: true
      });
      confirmDialog.keydownEvents().subscribe(e => {
        if (e.code === 'Escape') {
          confirmDialog.close();
        }
      });
      confirmDialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.priceSheetService.deleteCustomerRelation(pricesheet.id!, this.selectedCustomer!.id, pricesheet.licenseRef).then((res) => {
            this.data.setSelectedPricesheet( undefined);
            this.data.ClearData();
            this.pricesheetControl.patchValue('');
            this.getPriceSheets();
            this._snackBar.open("Prisark Slettet", "Success", { duration: 1000, panelClass: ['snack-bar'] });
          });
        }
      });
    }
  }

  editPricesheet(event: any) {
    event?.stopPropagation();
    if (this.data.getSelectedPricesheet()) {
      var dt = {
        pricesheet: this.data.getSelectedPricesheet(),
        pricesheetTypes: this.pricesheetTypes,
      };
      const renameDialog = this.dialog.open(EditPricesheetDialog,
        { data: dt, maxHeight: '90vh', maxWidth: '80vw', minWidth: '20vw' });
      renameDialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.data.getSelectedPricesheet().name = res.name;
          this.pricesheetService.savePricesheet(res).then((res: any) => {
            if (res) {
              this.pricesheetControl.patchValue(this.data.getSelectedPricesheet().name);
            }
          }).finally(() => {
            this._snackBar.open("Prisark Gemt", "Success", { duration: 2000, panelClass: ['snack-bar'] });
          });
        }
      });
    }
  }

  AddNewDate() {
    const dialogRef = this.dialog.open(NewPricesheetDateDialog);

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.date) {
        this.pricesheetService.newPriceAndZoneDate(this.data.getSelectedPricesheet().id!, this.data.selectedDate, res.date, this.data.getSelectedPricesheet().licenseRef).then((res: any) => {
          this.getPriceSheets();
        });
      }
    });
  }


  removeDate() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Slet dato',
        message: `Er du sikker på at du vil slette alle zoner og priser for D. ${this.datePipe.transform(this.data.selectedDate, 'dd/MM/yyyy')}?`
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.pricesheetZoneService.deleteZonesByValidDate(this.data.getSelectedPricesheet().id, this.data.selectedDate, this.data.getSelectedPricesheet().licenseRef).then((res: any) => {
        });

        this.pricesheetPriceService.deletePricesByValidDate(this.data.getSelectedPricesheet().id, this.data.selectedDate, this.data.getSelectedPricesheet().licenseRef).then((res: any) => {
          let customer = this.data.selectedCustomer;
          this.data.ClearData();
          this.data.selectedCustomer = customer;
          this.pricesheetControl.patchValue('');
          this.getPriceSheets();
        });

      }
    });

  }

  getAllCurrencies() {
    this.currencyRequest.licenseRef = this.data.LicenseRef;
    this.currencyService.getCurrencies(this.currencyRequest).then((res) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getMeasureUnits() {
    var licenseRef = this.data.LicenseRef;
    this.measureUnitService.getMeasureUnits(licenseRef).subscribe((res: any) => {
      this.measureUnits = res.modelObject;
    });
  }

  getAllProducts() {
    const product: Product = {
      name: "",
      erpCode: "",
      licenseRef: this.data.LicenseRef,
      fk_productUnitID: 0,
      includeProductPrices: true,
      includeProductUnit: true
    };
    this.productService.getAll(product).then((res: any) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        this.products.push({
          id: res.modelObject[i].id,
          name: res.modelObject[i].name,
          erpCode: res.modelObject[i].erpCode
        });
      }
    });
  }

  getHandlingTypes() {
    let handlingType: HandlingType = {
      licenseRef: this.data.LicenseRef
    };
    this.handlingTypeService.getAll(handlingType).then((res) => {
      this.handlingTypes = res.modelObject;
    });
  }

  getPriceSheets(): void {
    if (this.selectedCustomer && this.selectedCustomer.licenseRef) {
      let priceSheet: Pricesheet = {
        licenseRef: this.selectedCustomer.licenseRef,
        includeCustomerPricesheets: true
      }
      this.priceSheetService.getPriceSheetsByCustomer(this.selectedCustomer.id!, priceSheet).then((res: any) => {
        if (res && res.modelObject) {
          let sheets: Pricesheet[] = res.modelObject;
          sheets.forEach(sheet => {
            if (sheet.customerPricesheets && sheet.customerPricesheets.length > 0) {
              sheet.priority = sheet.customerPricesheets[0].priority;
            }
          });
          if (sheets) {
            sheets.sort((a, b) => a.priority! > b.priority! ? 1 : a.priority == b.priority ? 0 : -1);
          }
          //this.data.pricesheets = res.modelObject;
          this.data.pricesheets = sheets;
          this.getDefaultPricesheetType();
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  getDefaultPricesheetType() {
    let priceSheetType: PricesheetType = {
      licenseRef: this.data.LicenseRef,
      name: ''
    }
    this.pricesheetTypeService.getAll(priceSheetType).then((res) => {
      this.pricesheetTypes = [];
      for (let i = 0; i < res.modelObject.length; i++) {
        let pt: PricesheetType = {
          id: res.modelObject[i].id,
          name: res.modelObject[i].name,
        };
        this.pricesheetTypes.push(pt);
        if (res.modelObject[i].name && res.modelObject[i].name == "Customer") {
          this.type = res.modelObject[i];
        }
      }
    });
  }

  mapTmpPricesheet(data: any): any {
    this.newPricesheetDate = this.datePipe.transform(data.date, 'yyyy-MM-dd');
    if (this.data.getSelectedPricesheet()) {
      const tmpPricesheet = Object.assign({}, this.data.getSelectedPricesheet());
      tmpPricesheet.id = undefined;
      tmpPricesheet.name = data.name;
      tmpPricesheet.validFrom = this.datePipe.transform(data.date, 'yyyy-MM-dd');
      tmpPricesheet.customerPricesheets = [
        {
          fk_customerId: this.data.selectedCustomer.id,
          customer: this.data.selectedCustomer,
          fk_pricesheetId: this.data.getSelectedPricesheet()!.id,
          licenseRef: this.data.getSelectedPricesheet()!.licenseRef
        }];
      return tmpPricesheet;
    }
  }

  openCustomerSettings() {
    if (this.selectedCustomer) {
      const dialogRef = this.dialog.open(CustomerSettingsDialog, { width: '70vw', data: this.selectedCustomer });
      dialogRef.afterClosed().subscribe(() => {
        this.getPriceSheets();
      });
    }
  }


  selectPriceSheet(pricesheet: any) {
    if (this.data.getSelectedPricesheet() != pricesheet) {
      this.data.AlmostClearData();
      this.data.ZoneDates = [];
    }
    this.data.setSelectedPricesheet(pricesheet);
    // this.pricesheetType = this.pricesheetTypes.find(x => x.id == this.data.getSelectedPricesheet().fk_pricesheetTypeId);
    this.data.getSelectedPricesheet().fk_customerId = this.selectedCustomer!.id;

  }

  chooseDate(date: any) {
    this.data.setSelectedDate(date);
  }

  savePricesheet() {
    if (this.data.showHidePrint) {
      this._snackBar.open("Kan ikke gemme prisark mens Skjulte er vist", "Fejl", { duration: 2000, panelClass: ['snack-bar'] }); // Translate
      return;
    }
    if (this.data.WorkZones && this.data.WorkPrices) {
      //this.loading = true;
      this.data.startSerialization();
    }
  }

  newPricesheet() {
    if (this.selectedCustomer) {
      const dialogRef = this.dialog.open(NewPricesheetDialog, { data: this.selectedCustomer.name, height: "500px", width: "27vw" });
      dialogRef.afterClosed().subscribe((res) => {
        if (res && res.pricesheet && !res.name && res.saved) {
          this.loading = true;
          this.pricesheetService.copyPricesheet(res.pricesheet.id, res.pricesheet.name, res.date, res.pricesheet.licenseRef, this.selectedCustomer?.id).then((res) => {
            this.getPriceSheets();
            this.loading = false;
          });
        }
        else if (res && !res.pricesheet && res.name) {
          this.loading = true;
          let formattedDate = this.datePipe.transform(res.date, 'yyyy-MM-dd');
          const newPricesheet: Pricesheet = {
            id: undefined,
            name: res.name,
            validFrom: formattedDate?.toString(),
            fk_customerId: this.selectedCustomer?.id,
            licenseRef: this.data.LicenseRef,
            priority: 10,
            fk_pricesheetTypeId: 2,
            htFromToZip: res.htFromToZip != null ? res.htFromToZip : false
          };
          this.pricesheetService.savePricesheet(newPricesheet).then((res: any) => {
            if (res) {
              this.data.setSelectedPricesheet(undefined);
              this.data.ClearData();
              this.pricesheetControl.patchValue('');
              this.getPriceSheets();
            }
          }).finally(() => {
            this._snackBar.open("Prisark Oprettet", "Success", { panelClass: ['snack-bar'] }); // Translate
            this.loading = false;
          });
        }
      });
    }
  }

  getCustomers(): void {
    let customer: Customer;
    customer = {
      licenseRef: this.data.LicenseRef,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    }

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.filteredOptions = this.customControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }
    ).finally(() => {
      this.customerLoading = false;
    });
  }

  selectCustomer(customer: Customer): void {
    this.loading = true;
    this.data.ClearData();
    this.pricesheetControl.patchValue('');
    this.selectedPricesheet = undefined;
    let cust = this.customers.filter(x => x.id == customer.id)[0];
    if (cust) {
      customer = cust;
    }
    if (!customer.name) {
      customer.name = customer.clientObject!.name;
    }
    this.data.selectedCustomer = customer;
    this.selectedCustomer = customer;
    this.getPriceSheets();
    this.data.CustomerChanged.emit(customer)
  }
}


