import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DataShareService } from "./datashare.service";
import { Client } from '../Models/client';

@Injectable({
  providedIn: 'root'
})
export class ClientapiService {

  constructor(
    private http: HttpClient, 
    private data: DataShareService
  ) { }

  getAll(client: any) : Promise<any>{
    return this.http.post(environment.api_clients_url + "/api/customer/getall", client, this.getOptions()).toPromise();
  }


  getOptions() {
    let httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json', "Authorization": '1beb53c3-d10c-40cd-ab28-4bb3bf762ecd' }),
    };
    return httpOptions;
  }

}
