import { AfterViewInit, Component, ElementRef, isDevMode, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { AuthService } from '../shared/api/auth.service';
import { DataShareService } from '../shared/api/datashare.service';
import { License } from '../shared/Models/license';
import { LicenseService } from '../shared/api/license.service';
import { RoadtaxshareService } from '../shared/share/roadtaxshare.service';
import { roadtax } from '../shared/Models/roadtax';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  selectedLicense?: number;
  password?: string;
  license: string = '';
  licenses: License[] = [];

  @ViewChild('licenseField') licenseField!: ElementRef;

  constructor(
    private router: Router, 
    private _snackBar: MatSnackBar, 
    private dataShare: DataShareService, 
    private auth: AuthService,
    private licenseService: LicenseService,
    private roadtaxShare: RoadtaxshareService,
  ) 
  {}

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    if (!environment.production) {
      this.password = "A4Ever1234";
      this.license = "TJW"
    }
    if (this.dataShare.LicenseRef) {
      this.router.navigateByUrl('/prices');
    }
    this.licenseService.getAll().then(result => {
      this.licenses = result.modelObject;
    });
  this.dataShare.oilpayrule.push({ key: 0, value: 'Booking' });
    this.dataShare.oilpayrule.push({ key: 1, value: 'Afhentning' });
    this.dataShare.oilpayrule.push({ key: 2, value: 'Levering' });
    this.dataShare.oilpayrule.push({ key: 3, value: 'Kørsel' });
  }


  // Hardcoding will be removed when Actual Auth flow is build
  doLogin() {
    
    if (this.licenses && this.licenses.length > 0) {
      var lic = this.licenses.filter(x => x.name?.toUpperCase() == this.license.toUpperCase())[0];
      if (lic) {
        if (lic.password == this.password || lic.passwordAdmin == this.password) {
          this.login(lic.licenseRef!, lic.name!)
        }else{
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
      }
    }
    
/*
    switch (this.license.toUpperCase()) {
      case 'TJW':
        if ((this.password == 'L9V!rb') || (this.password == environment.password)) {
          this.login('A47F92DB-7E46-462F-862F-69B6F07D478E', 'TJW');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'PD':
        if ((this.password == 'gAxy4W') || (this.password == environment.password)) {
          this.login('E9583DDB-13C4-461A-AFEB-5BBB12F25947', 'Per Damgård');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'TJK':
        if ((this.password == 'c3E3Rm') || (this.password == environment.password)) {

          this.login('CAEF9437-7DF7-41B4-9097-E5611DE1D7AD', 'Team JK');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;

      case 'JJD':
        if ((this.password == 'a1E2Rk') || (this.password == environment.password)) {
          this.login('F599A9DD-AF53-4053-B5E3-7F79368A5DC7', 'Jørgen Jensen Distribution');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;

      case 'DDT':
        if ((this.password == 'RPcYQz') || (this.password == environment.password)) {
          this.login('516EB78A-5B5F-4313-BC2D-CF99C527755A', 'Dansk Distribution Taulov');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;

      case 'EGJ':
        if ((this.password == '5nEx9g') || (this.password == environment.password)) {
          this.login('187E0447-65EF-4ACC-A096-357DAC3BCA02', 'Jyderup Gods');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'SD':
        if ((this.password == '7w#zGu') || (this.password == environment.password)) {
          this.login('7e54fc15-767c-4bdd-a4db-49dbe7e001a1', 'Sand Distribution');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'ST':
        if ((this.password == '73F6h)') || (this.password == environment.password)) {
          this.login('CEA14210-84F8-4FD0-95CA-3654381F69D3', 'Struer Transportcenter');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      default:
        this._snackBar.open('Forkerte oplysninger, prøv igen', "fejl", { panelClass: ['snack-bar'] });
        break;
    }
        */
  }



  private login(license: string, customerChosen: string) {
    this.dataShare.LicenseRef = license;
    this.dataShare.customerChosen = customerChosen;
    this.auth.login(license);
    if (this.dataShare.LicenseRef) {
      let dmy: roadtax = {licenseRef: license};
      this.roadtaxShare.initialize(dmy).then(() => {
        console.log(this.roadtaxShare.roadtaxes)
        this.router.navigateByUrl('/prices');
      });
    }
  }

}
