import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CurrencyService } from '../shared/api/currency.service';
import { DataShareService } from '../shared/api/datashare.service';
import { ProductPriceService } from '../shared/api/productprice.service';
import { Currency } from '../shared/Models/currency';
import { ProductPrice } from '../shared/Models/productPrice';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, DialogData } from '../shared/util/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  productprice = <ProductPrice>{};
  productprices: ProductPrice[] = [];
  displayedColumns: string[] = ["name", "intervalfrom", "intervalto", "price", "valuta"];
  dataSource!: MatTableDataSource<ProductPrice>;
  originalProductPrices: any;
  menuTopLeftPosition = { x: '0', y: '0' };
  currencyRequest = <Currency>{};
  currencies: any;

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;

  constructor(
    private productpriceService: ProductPriceService, 
    private currencyService: CurrencyService, 
    private data: DataShareService, 
    private datePipe: DatePipe, 
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,

    ) { }

  ngOnInit(): void {
    this.getProductPrices();
    this.getAllCurrencies();
    this.data.CustomerChanged.subscribe((res) => {
      this.getProductPrices();
    });
  }

  productChanged(productPrice: ProductPrice) {
    if (!productPrice.fk_customerId) {
      productPrice.id = null;
      productPrice.fk_customerId = this.data.selectedCustomer.id;
      productPrice.customer = this.data.selectedCustomer;
    }
  }
  intervalfromChanged(productPrice: ProductPrice) {
    if (!productPrice.fk_customerId) {
      productPrice.id = null;
      productPrice.fk_customerId = this.data.selectedCustomer.id;
      productPrice.customer = this.data.selectedCustomer;
    }
  }
  intervaltoChanged(productPrice: ProductPrice) {
    if (!productPrice.fk_customerId) {
      productPrice.id = null;
      productPrice.fk_customerId = this.data.selectedCustomer.id;
      productPrice.customer = this.data.selectedCustomer;
    }
  }
  compareByOptionId(idFist: any, idSecond: any) {
    return idFist && idSecond && idFist.id == idSecond.id;
  }

  getAllCurrencies() {
    this.currencyRequest.licenseRef = this.data.LicenseRef;
    this.currencyService.getCurrencies(this.currencyRequest).then((res) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  saveCustomerProducts() {
    let prices = [];
    for (let i = 0; i < this.productprices.length; i++) {
      if (this.productprices[i].fk_customerId) {
        this.productprices[i].validFrom = this.productprices[i].validFrom;
        this.productprices[i].fk_currencyId = this.productprices[i].currency?.id;
        prices.push(this.productprices[i]);
      }
    }
    this.productpriceService.saveOrUpdateCustomerProducts(prices).then((res) => {
      if (res) {
        this.getProductPrices();
      }
    }).finally(() => {
      this._snackBar.open("Produkt er Gemt", "Success", { panelClass: ['snack-bar'] });
    });
  }

  onRightClick(event: MouseEvent, item: any) {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.matMenuTrigger.menuData = { item: item }
    this.matMenuTrigger.openMenu();

  }

  getProductPrices() {
    if (this.productprices) {
      this.productprices = [];
    }
    if (this.data.selectedCustomer.id) {
      this.productprice.licenseRef = this.data.LicenseRef;
      this.productprice.includeCustomer = true;
      this.productprice.includeProduct = true;
      this.productprice.includeCurrency = true;
      this.productprice.fk_customerId = this.data.selectedCustomer.id;
      this.productpriceService.getProductPricesByCustomerId(this.productprice).then((res) => {
        if (res.modelObject) {
          this.productprices = res.modelObject;
          this.productprices = this.productprices.sort((a: ProductPrice, b: ProductPrice) => {
            if (a.fk_customerId == null && b.fk_customerId == null) {
              if (a.product?.name! > b.product?.name!) {
                return 1;
              }
              if (b.product?.name! > a.product?.name!) {
                return -1;
              }
              return 0;
            }
            if (a.fk_customerId == null) {
              return 1;
            }
            if (b.fk_customerId == null) {
              return -1;
            }
            if (a.product?.name! > b.product?.name!) {
              return 1;
            }
            if (b.product?.name! > a.product?.name!) {
              return -1;
            }
            return 0;
          });
          this.dataSource = new MatTableDataSource(this.productprices);
        }
      });
    }
  }

  changeToStandard(item?: any) {
    if (item && item.content) {
      var data: DialogData = {message: this.translate.instant('AREYOUSURETODELETECUSTOMERPRICEANDUSEDEFAULTPRICE'), title: this.translate.instant('SETCUSTOMERPRICETODETAULTPRICE')}
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {disableClose: true, data: data});
      dialogRef.keydownEvents().subscribe(key => {
        if (key.code === 'Escape'){
          dialogRef.close();
        }
      })
      dialogRef.afterClosed().subscribe(answer => {
        if (answer) {
          this.productpriceService.delete(item.content.id, item.content.licenseRef).then(() => {
            this.getProductPrices();
          })
        }
      })
    }
  }
}
