<div style="display:flex; margin-top:5%;">
    <div class="row">
        <div class="mat-elevation-z8 table-responsive" style="border-radius: 10px;">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'NAME'|translate}}
                    </th>
                    <td style="text-align: center !important; width: 120px;" matTooltipClass="tooltipbreak"
                        matTooltip="Hvid er Kunde specifike &#13; Grå er standard Produkter " mat-cell
                        *matCellDef="let productPrice"
                        [ngClass]="{'StandardProduct': productPrice.fk_customerId==null}">
                        <span *ngIf="productPrice.fk_customerId == null">{{productPrice.product!.name}}</span>
                        <span *ngIf="productPrice.fk_customerId != null"
                            (contextmenu)="onRightClick($event, {content: productPrice})">{{productPrice.product!.name}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="intervalfrom">
                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'INTERVALFROM'|translate}}
                    </th>
                    <td mat-cell style="text-align: center !important;" matTooltipClass="tooltipbreak"
                        matTooltip="Hvid er Kunde specifike &#13; Grå er standard Produkter "
                        *matCellDef="let productPrice"
                        [ngClass]="{'StandardProduct': productPrice.fk_customerId==null}">
                        <span *ngIf="productPrice.fk_customerId == null">
                            <label>{{productPrice.intervalfrom}}</label>
                        </span>
                        <span *ngIf="productPrice.fk_customerId != null" (contextmenu)="onRightClick($event, {content: productPrice})">
                            <label>{{productPrice.intervalfrom}}</label>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="intervalto">
                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'INTERVALTO'|translate}}
                    </th>
                    <td mat-cell style="text-align: center !important;" matTooltipClass="tooltipbreak"
                        matTooltip="Hvid er Kunde specifike &#13; Grå er standard Produkter "
                        *matCellDef="let productPrice"
                        [ngClass]="{'StandardProduct': productPrice.fk_customerId==null}">
                        <span *ngIf="productPrice.fk_customerId == null">
                            <label>{{productPrice.intervalto}}</label>
                        </span>
                        <span *ngIf="productPrice.fk_customerId != null" (contextmenu)="onRightClick($event, {content: productPrice})">
                            <label>{{productPrice.intervalto}}</label>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'PRICE'|translate}}
                    </th>
                    <td mat-cell style="text-align: center !important;" matTooltipClass="tooltipbreak"
                        matTooltip="Hvid er Kunde specifike &#13; Grå er standard Produkter "
                        *matCellDef="let productPrice"
                        [ngClass]="{'StandardProduct': productPrice.fk_customerId==null}">
                        <span *ngIf="productPrice.fk_customerId == null"><input matInput type="number"
                                [(ngModel)]="productPrice.price" (change)="productChanged(productPrice)" /></span>
                        <span *ngIf="productPrice.fk_customerId != null"
                            (contextmenu)="onRightClick($event, {content: productPrice})"><input matInput type="number"
                                [(ngModel)]="productPrice.price" /></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="valuta">
                    <th mat-header-cell *matHeaderCellDef>{{'CURRENCY'|translate}}</th>
                    <td mat-cell *matCellDef="let productPrice" matTooltipClass="tooltipbreak" style="min-width:60px;"
                        matTooltip="Hvid er Kunde specifike &#13; Grå er standard Produkter "
                        [ngClass]="{'StandardProduct': productPrice.fk_customerId==null}">
                        <span *ngIf="productPrice.fk_customerId == null">{{productPrice.currency.isocode}} </span>
                        <span *ngIf="productPrice.fk_customerId != null"
                            (contextmenu)="onRightClick($event, {content: productPrice})">
                            <mat-select #currency [(ngModel)]="productPrice.currency" name="currency"
                                [compareWith]="compareByOptionId">
                                <mat-option *ngFor="let curr of currencies" [value]="curr">
                                    {{curr.isocode}}
                                </mat-option>
                            </mat-select>
                            <!-- {{productPrice.currency.isocode}} -->
                        </span>
                    </td>
                </ng-container>

                <tr style="background-color:var(--primary-blue);" mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky:true;" ></tr>
                <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <div style="display:flex; align-items: flex-start;">
        <button style="background-color: var(--primary-blue); color:white; border-radius: 10px;" mat-raised-button
            (click)="saveCustomerProducts()">{{'SAVE' | translate}}</button>
    </div>
</div>

<div style="visibility: hidden; position: fixed;" [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu">
</div>

<mat-menu style="background: var(--primary-blue) !important;" #rightMenu="matMenu">
    <ng-template style="background: var(--primary-blue) !important;" matMenuContent let-item="item">
        <button style="background-color: var(--primary-blue); color:white;" (click)="changeToStandard(item)" mat-menu-item>{{'CHANGETOSTANDARD' | translate}}</button>
    </ng-template>
</mat-menu>