<div class="col">
    <div style="margin-top:10px;">
        <mat-card class="flip-card cardDesign">
            <mat-card-title class="centText">{{'OIL' | translate}}</mat-card-title>
            <mat-card-content>
            </mat-card-content>
            <mat-card-actions>
                <div *ngIf="oilSheets && oilSheets.length > 0">
                    <div *ngFor="let os of oilSheets;" style="text-align: center;">
                        <b>{{'PERCENT' | translate}}:</b> {{os.procent}} % | <b>{{'DATE' | translate}}:</b> {{os.date |
                        date : 'dd-MM-yyyy'}} <span *ngIf="os.erpCode">| <b>{{'ERPCODE' | translate}}:</b>
                            {{os.erpCode}}</span><br />
                    </div>
                </div>
                
                <mat-divider inset style="margin-top:2%;"></mat-divider>
                <div style="margin-top: 5%; display:flex; justify-content: space-between;">
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'PERCENT' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newOilSheet!.procent" type="number" />
                    </mat-form-field>
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'DATE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newOilSheet!.date" type="date" />
                    </mat-form-field>
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'ERPCODE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newOilSheet!.erpCode" type="number" />
                    </mat-form-field>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div class="saveButtonPlacement">
        <button mat-raised-button class="saveBtnDesign" *ngIf="newOilSheet.date && newOilSheet.procent"
            (click)="saveOil()">{{'SAVEOIL' | translate}}</button>
    </div>
    <div style="margin-top:10px;">
        <mat-card class="cardDesign">
            <mat-card-title style="text-align: center;">{{'ENVIRONMENT' | translate}}</mat-card-title>
            <mat-card-content>
            </mat-card-content>
            <mat-card-actions>
                <div *ngFor="let envTax of environmentalTaxes;" style="text-align: center;">
                    <b>{{'PERCENT' | translate}}:</b> {{envTax.procent}} % | <b>{{'DATE' | translate}}:</b>
                    {{envTax.date |
                    date : 'dd-MM-yyyy'}} <span *ngIf="envTax.erpCode">| <b>{{'ERPCODE' | translate}}:</b>
                        {{envTax.erpCode}}</span><br />
                </div>
                <mat-divider inset style="margin-top:2%;"></mat-divider>
                <div class="formFields">
                    <mat-form-field style="width: 33%;">
                        <mat-label>{{'PERCENT' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newEnvironmentalTax!.procent" type="number" />
                    </mat-form-field>
                    <mat-form-field style="width: 33%;">
                        <mat-label>{{'DATE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newEnvironmentalTax!.date" type="date" />
                    </mat-form-field>
                    <mat-form-field style="width: 33%;">
                        <mat-label>{{'ERPCODE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newEnvironmentalTax!.erpCode" type="number" />
                    </mat-form-field>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div class="saveButtonPlacement">
        <button mat-raised-button *ngIf="newEnvironmentalTax.procent && newEnvironmentalTax.date" class="saveBtnDesign"
            (click)="saveEnvironmental()">{{'SAVEENVIRONMENTAL' | translate}}</button>
    </div>
    <div style="margin-top:10px;">
        <mat-card class="example-card cardDesign">
            <mat-card-title class="centText">{{'CAPACITY' | translate}}</mat-card-title>
            <mat-card-content></mat-card-content>
            <mat-card-actions>
                <div *ngFor="let capTax of capacityTaxes" class="centText">
                    <b>{{'PERCENT' | translate}}:</b> {{capTax.procent}} % | <b>{{'DATE' | translate}}:</b>
                    {{capTax.date |
                    date : 'dd-MM-yyyy'}} <span *ngIf="capTax.erpCode">| <b>{{'ERPCODE' | translate}}:</b>
                        {{capTax.erpCode}}</span> <br />
                </div>
                <mat-divider inset style="margin-top:2%;"></mat-divider>
                <div class="formFields">
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'PERCENT' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newCapacityTax!.procent" type="number" />
                    </mat-form-field>
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'DATE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newCapacityTax!.date" type="date" />
                    </mat-form-field>
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'ERPCODE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newCapacityTax!.erpCode" type="number" />
                    </mat-form-field>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="saveButtonPlacement">
        <button mat-raised-button *ngIf="newCapacityTax.procent && newCapacityTax.date" class="saveBtnDesign"
            (click)="saveCapacity()">{{'SAVECAPACITY' | translate}}</button>
    </div>

    <div style="margin-top:10px;">
        <mat-card class="example-card cardDesign">
            <mat-card-title class="centText">{{'ROADTAX' | translate}}</mat-card-title>
            <mat-card-content></mat-card-content>
            <mat-card-actions>
                <div *ngFor="let capTax of roadtaxes" class="centText">
                    <b>{{'PERCENT' | translate}}:</b> {{capTax.procent}} % | <b>{{'DATE' | translate}}:</b>
                    {{capTax.date |
                    date : 'dd-MM-yyyy'}} <span *ngIf="capTax.erpCode">| <b>{{'ERPCODE' | translate}}:</b>
                        {{capTax.erpCode}}</span> <br />
                </div>
                <mat-divider inset style="margin-top:2%;"></mat-divider>
                <div class="formFields">
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'PERCENT' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newRoadTax.procent" type="number" />
                    </mat-form-field>
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'DATE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newRoadTax.date" type="date" />
                    </mat-form-field>
                    <mat-form-field style="width:33%;">
                        <mat-label>{{'ERPCODE' | translate}}</mat-label>
                        <input matInput [(ngModel)]="newRoadTax.erpCode" type="number" />
                    </mat-form-field>
                </div>
            </mat-card-actions>
        </mat-card>
        <div class="saveButtonPlacement">
            <button mat-raised-button *ngIf="newRoadTax.procent && newRoadTax.date" class="saveBtnDesign" (click)="saveRoadtax()">{{'SAVEROADTAX' | translate}}</button>
        </div>
    </div>


</div>