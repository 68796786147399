import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../shared/api/customer.service';
import { DataShareService } from '../shared/api/datashare.service';
import { Customer } from '../shared/Models/customer';
import { CustomerPricesheetService } from '../shared/api/customerpricesheet.service';
import { CustomerPriceSheet } from '../shared/Models/customerPriceSheet';
import { ClientshareService } from '../shared/api/clientshare.service';
import { Client } from '../shared/Models/client';
import { MatDialog } from '@angular/material/dialog';
import { PostadjustmentComponent } from '../postadjustment/postadjustment.component';
import { priceadjustment } from '../shared/Models/priceadjustment';
import { PricesheetService } from '../shared/api/pricesheet.service';
import { Pricesheet } from '../shared/Models/pricesheet';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { roundtype } from '../shared/Models/roundtype';
import { ProductPriceService } from '../shared/api/productprice.service';
import { ProductPrice } from '../shared/Models/productPrice';

@Component({
  selector: 'app-allcustomers',
  templateUrl: './allcustomers.component.html',
  styleUrls: ['./allcustomers.component.scss']
})
export class AllcustomersComponent implements OnInit {

  constructor(
    public clientShare: ClientshareService,
    private snackbar: MatSnackBar,
    public dataShare: DataShareService,
    private customerService: CustomerService,
    private customerPriceService: CustomerPricesheetService,
    private pricesheetService: PricesheetService,
    private productpriceService: ProductPriceService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,

  ) { }

  priceAdjust: number | null = null;
  adjustDate: string | null = null;
  showTaged = false;
  customerPricesheets: CustomerPriceSheet[] = [];
  priceSheets: Pricesheet[] = [];
  foundCustomers: Customer[] = [];
  roundTypes: roundtype[] = [];
  selectedRoundTypeValue = 1;

  ngOnInit(): void {    
    this.dataShare.showSpinner = true;    
    this.roundTypes.push({text: this.translate.instant('ROUNDUP'), value: 1})
    this.roundTypes.push({text: this.translate.instant('NONE'), value: 0})
    this.roundTypes.push({text: this.translate.instant('ROUNDDOWN'), value: 2})
    let customer: Customer = {licenseRef: this.dataShare.License.licenseRef!};
    this.customerService.getCustomers(customer).then((response: any) => {
      if (response && response.modelObject) {
        let customers: Customer[] = response.modelObject;
        let customerPricesheet: CustomerPriceSheet = {licenseRef: this.dataShare.License.licenseRef}
        let priceSheet: Pricesheet = {licenseRef: this.dataShare.License.licenseRef!};
        this.pricesheetService.getAll(priceSheet).then(response => {
          if (response && response.modelObject){
            this.priceSheets = response.modelObject;
          }
          this.customerPriceService.getAll(customerPricesheet).then(response => {          
            if (response && response.modelObject) {
              this.customerPricesheets = response.modelObject;
              this.clientShare.initialize().then(() => {
                //let clients = this.clientShare.clients;
                let customerIds: number[] = [];
                this.customerPricesheets.forEach(sheet => {
                  if (!customerIds.includes(sheet.fk_customerId!)){
                    customerIds.push(sheet.fk_customerId!);
                  }
                });
                this.foundCustomers = [];
                customerIds.forEach(id => {
                  let cust = customers.filter(x => x.id == id)[0];                  
                  if (cust){
                    if (this.pricesheetTypeValid(cust)){
                      this.foundCustomers.push(cust);
                    }
                  }
                });
                this.clientShare.filteredClients = [];
                let clients: Client[] = [];
                this.foundCustomers.forEach(customer => {
                  let client = this.clientShare.clients.filter(x => x.externalRef?.toLowerCase() == customer.customerexternRef?.toLowerCase())[0];
                  if (client) {
                    clients.push(client);
                  }
                });
                if (clients) {
                  clients.sort((a, b) => a.name! > b.name! ? 1 : a.name == b.name ? 0 : -1);                
                  clients.forEach(client => {
                    client.priceAdjust = null;
                  });
                }
                this.clientShare.clientsContainer = clients;
                this.clientShare.setFilter();
              });
            }
          });  
          this.dataShare.showSpinner = false;
        });
      }
    });
  }

  pricesheetTypeValid(customer: Customer): boolean{
    let customerPricesheets = this.customerPricesheets.filter(x => x.fk_customerId == customer.id);
    if (customerPricesheets && customerPricesheets.length > 0) {
      let sheets: Pricesheet[] = [];
      let status = false;
      customerPricesheets.forEach(customerSheet => {
        let sheet = this.priceSheets.filter(x => x.id == customerSheet.fk_pricesheetId)[0];
        if (sheet && sheet.fk_pricesheetTypeId != 1 && sheet.fk_pricesheetTypeId != 7 && sheet.fk_pricesheetTypeId != 6) {          
          status = true;
        }        
      });
      return status;
    }
    return false;
  }

  filterChanged() {
    this.clientShare.setFilter();
  }

  clearFilter() {
    this.clientShare.filterValue = '';
    this.clientShare.setFilter();
  }

  clickAdjust() {
    if (this.clientShare.filteredClients) {
      this.clientShare.filteredClients.forEach(client => {
        client.priceAdjust = this.priceAdjust;
      });
      this.priceAdjust = null;
    }
  }
  
  clickAdjustDate() {
    if (!this.validAdjustDate()) {
      this.snackbar.open( this.translate.instant('ADJUSTDATEISWRONGTODAYORHEIGHER'), "Fejl", {duration: 5000});
      return;
    }
    if (this.clientShare.filteredClients) {
      this.clientShare.filteredClients.forEach(client => {
        client.adjustDate = this.adjustDate;
      });
      this.adjustDate = null;
    }
  }

  getDisabledStatus(): boolean {
    if (this.clientShare.filteredClients) {
      let t = this.clientShare.filteredClients.filter(x => x.priceAdjust != null && x.adjustDate != null);
      if (t && t.length > 0) {
        return false;
      }
    }
    return true;
  }

  clickSort(sortField?: string) {
    if (sortField && this.clientShare.filteredClients) {
      switch (sortField) {
        case 'number':
          this.clientShare.filteredClients.sort((a, b) => a.number! > b.number! ? 1 : a.number == b.number ? 0 : -1);
          break;
        case 'name':
          this.clientShare.filteredClients.sort((a, b) => a.name! > b.name ? 1 : a.name == b.name ? 0 : -1);
          break;
        case 'nickname':
          this.clientShare.filteredClients.sort((a, b) => a.nickname! > b.nickname! ? 1 : a.nickname == b.nickname ? 0 : -1);
          break;
        case 'taxnumber':
          this.clientShare.filteredClients.sort((a, b) => a.taxNumber! > b.taxNumber! ? 1 : a.taxNumber == b.taxNumber ? 0 : -1);
          break;
        case 'address':
          this.clientShare.filteredClients.sort((a, b) => a.address! > b.address! ? 1 : a.address == b.address ? 0 : -1);
          break;
        case 'zipcode':
          this.clientShare.filteredClients.sort((a, b) => a.zip! > b.zip! ? 1 : a.zip == b.zip ? 0 : -1);
          break;
        case 'city':
          this.clientShare.filteredClients.sort((a, b) => a.city! > b.city! ? 1 : a.city == b.city ? 0 : -1);
          break;
        case 'country':
          this.clientShare.filteredClients.sort((a, b) => a.country! > b.country! ? 1 : a.country == b.country ? 0 : -1);
          break;
        case 'phone':
          this.clientShare.filteredClients.sort((a, b) => a.phone! > b.phone! ? 1 : a.phone == b.phone ? 0 : -1);
          break;
        default:
          break;
      }
        
    }
  }

  clickShowTaged() {
    if (this.clientShare.filteredClients && this.clientShare.clientsContainer) {
      if (this.showTaged) {
        this.clientShare.filteredClients = this.clientShare.clientsContainer.filter(x => x.adjustDate != null || x.priceAdjust != null);
      }else{
        this.clientShare.setFilter();
      }        
    }
  }

  validAdjustDate(): boolean {
    if (this.adjustDate){
      if (this.datePipe.transform(this.adjustDate, 'yyyy-MM-dd')! >= this.datePipe.transform(new Date, 'yyyy-MM-dd')!){
        return true;
      }
    }
    return false;
  }

  clickPostAdjust() {
    if (this.clientShare.filteredClients && this.clientShare.filteredClients.length > 0) {
      let items = this.clientShare.filteredClients.filter(x => x.priceAdjust != null && x.adjustDate != null);
      if (items && items.length > 0) {
        let priceadjustments: priceadjustment[] = [];
        items.forEach(item => {
          let adjust: priceadjustment = {
            clientEntity: item, sheetsAffected: [], errorMessage: [], adjustMessages: [],  adjustmentDate: item.adjustDate!, licenseRef: this.dataShare.License.licenseRef!, priceRoundType: this.selectedRoundTypeValue,
            saveStatus: [], hasOwnPricedProducts: true, adjustPricedProducts: true
          };
          let customer = this.foundCustomers.filter(x => x.customerexternRef!.toLowerCase() == item.externalRef?.toLowerCase())[0]
          if (customer) {            
            adjust.clientEntity!.peId = customer.id;
            let customerPricesheets = this.customerPricesheets.filter(x => x.fk_customerId == customer.id);
            if (customerPricesheets && customerPricesheets.length > 0) {
              customerPricesheets.forEach(customerSheet => {
                let pricesheet = this.priceSheets.filter(x => x.id == customerSheet.fk_pricesheetId)[0];
                if (pricesheet && pricesheet.fk_pricesheetTypeId != 1 && pricesheet.fk_pricesheetTypeId != 7 && pricesheet.fk_pricesheetTypeId != 6 && pricesheet.fk_pricesheetTypeId != 8 ){
                  pricesheet.doAdjust = true;
                  adjust.sheetsAffected?.push(pricesheet);
                }
              });
            }
          }
          priceadjustments.push(adjust);
        });
        const dialogRef = this.dialog.open(PostadjustmentComponent, {disableClose: true, data: priceadjustments});
        dialogRef.keydownEvents().subscribe(key => {
          if (key.code === 'Escape'){
            dialogRef.close();
          }
        });
        dialogRef.afterClosed().subscribe(response => {
          
        });        
          
      }
    }
  }
}
