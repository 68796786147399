<div style="margin-left: 500px; margin-top: 8px; width: 300px; display: flex; flex-direction: column;">
    <mat-form-field class="formField">
        <mat-label class="textColorDark">{{'WEIGHTTOVOLUME' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="rule.weightprvolume">
    </mat-form-field>

    <mat-form-field class="formField">
        <mat-label class="textColorDark">{{'WEIGHTTOLDM' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="rule.weightprldb">
    </mat-form-field>


    <mat-form-field class="formField">
        <mat-label class="textColorDark">{{'PALLETTOLDM' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="rule.pllprldm">
    </mat-form-field>

    <mat-form-field class="formField">
        <mat-label class="textColorDark">{{'ROUNDING' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="rule.pllRound">
    </mat-form-field>
    <!--Per damgaard license-->
    <mat-form-field class="formField" *ngIf="dataShare.LicenseRef == 'E9583DDB-13C4-461A-AFEB-5BBB12F25947'">
        <mat-label class="textColorDark">{{'DEFAULTPRICECODE' | translate}}</mat-label>
        <input matInput type="text" [(ngModel)]="rule.defaultPriceCode">
    </mat-form-field>
    <mat-form-field class="formField" floatLabel="always">
        <mat-slide-toggle [(ngModel)]="rule.calcPalletSpaces" ><span class="text-invisible">123</span></mat-slide-toggle>
        <mat-label class="textColorDark">{{'CALCPALLETSPACES' | translate}}</mat-label>
        <input matInput type="text" hidden />
    </mat-form-field>
    <div>
        <mat-form-field class="formField">
            <mat-label class="textColorDark">{{'OILDATERULE'|translate}}</mat-label>
            <mat-select class="textColorDark" [(ngModel)]="rule.oilpaydatetype">
                <mat-option *ngFor="let oil of dataShare.oilpayrule" [value]="oil.key">
                    {{oil.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="saveBtn">
        <button (click)="saveRule()" mat-raised-button>{{'SAVE' | translate}}</button>
    </div>
</div>