import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from '../../api/customer.service';
import { Customer } from '../../Models/customer';
import { DataShareService } from '../../api/datashare.service';

@Component({
  selector: 'app-developerform',
  templateUrl: './developerform.component.html',
  styleUrls: ['./developerform.component.scss']
})
export class DeveloperformComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeveloperformComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: DialogData
    private customerApi: CustomerService,
    private dataShare: DataShareService,

  ) { }

  customers: Customer[] = [];
  
  ngOnInit(): void {
    this.dialogRef.updateSize('70vw', '70vh')
    this.getAllClients();

  }

  getAllClients() {
    let dummy: Customer = {licenseRef: this.dataShare.LicenseRef};
    this.customerApi.getCustomers(dummy).then((res: any) => {
      if (res && res.modelObject) {
        this.customers = res.modelObject;
        this.customers.sort((a, b) => a.clientObject!.name! > b.clientObject!.name! ? 1 : a.clientObject!.name == b.clientObject!.name ? 0 : -1);
      }
    });
  }
}
