<h1 mat-dialog-title
  style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
  {{'CREATENEWSHEET' | translate}}</h1>
<mat-dialog-content fxLayout="column">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab style="height:30vh;" label="Nyt Prisark">
      <div style="display:flex; flex-direction: row; justify-content: center;">
        <mat-form-field style="width:100%; margin-left:48px; margin-right:42px;">
          <mat-label>{{'NAME' | translate}}</mat-label>
          <input [(ngModel)]="name" matInput type="text" />
        </mat-form-field>
      </div>
      <div style="display:flex; flex-direction: row; justify-content: center; margin-top:10px;">
        <mat-form-field style="width:100%; margin-left:48px; margin-right:42px;">
          <mat-label>{{'VALIDDATE' | translate}}</mat-label>
          <input [(ngModel)]="date" matInput type="date" />
        </mat-form-field>
      </div>
      <div style="display:flex; flex-direction: row; justify-content: flex-start;">
        <mat-slide-toggle style="padding:50px;" [(ngModel)]="htFromToZip">{{'HTFROMTOZIP' | translate}}
        </mat-slide-toggle>
      </div>
    </mat-tab>
    <mat-tab style="height:40vh;" label="Kopiér Prisark">
      <div style="display:flex; flex-direction: row; align-items: center; justify-content: center; margin-top:5%;">
        <mat-form-field *ngIf="customers" style="margin-top:1%; margin-left:1%; width:20vw;" appearance="outline">
          <mat-label>{{'CUSTOMERS' | translate}}</mat-label>
          <mat-select [(value)]="selectedCustomer">
            <mat-option *ngFor="let cust of customers" [value]="cust" (click)="selectCustomer(cust)">
              {{cust.clientObject && cust.clientObject.nickname ? cust.clientObject.nickname: cust.name}} 
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="display:flex; flex-direction: row; align-items: center; justify-content: center;">
        <mat-form-field *ngIf="pricesheets" style="margin-top:1%; margin-left:1%; width:20vw;" appearance="outline">
          <mat-label>{{'PRICESHEET' | translate}}</mat-label>
          <mat-select [(value)]="selectedPricesheet">
            <mat-option *ngFor="let pricesheet of pricesheets" [value]="pricesheet"
              (click)="selectPricesheet(pricesheet)">
              {{pricesheet.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="display:flex; flex-direction: row; justify-content: center;">
        <mat-form-field *ngIf="selectedPricesheet" style="width:100%; margin-left:48px; margin-right:42px;">
          <mat-label>{{'NEWNAME' | translate}}</mat-label>
          <input [(ngModel)]="newPricesheetName" matInput type="text" />
        </mat-form-field>
      </div>
      <div style="display:flex; flex-direction: row; justify-content: center;">
        <mat-form-field *ngIf="selectedPricesheet" style="width:100%; margin-left:48px; margin-right:42px;">
          <mat-label>{{'VALIDDATE' | translate}}</mat-label>
          <input [(ngModel)]="date" matInput type="date" />
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
  <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
  <button mat-raised-button style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' |
    translate}}</button>
</mat-dialog-actions>