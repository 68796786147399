<div fxLayout="row"
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    <h1 mat-dialog-title>{{'NEWPRODUCT' | translate}}</h1>
</div>
<mat-dialog-content fxLayout="column" style="width:25vw; margin-bottom:6%; overflow:unset;">
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field appearance="outline">
            <mat-label>{{'NAME' | translate}}</mat-label>
            <input required [(ngModel)]="dialogData.product!.name" matInput type="text" />
        </mat-form-field>
    </div>
    <div style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field appearance="outline">
            <mat-label>{{'PRICE' | translate}}</mat-label>
            <input [(ngModel)]="dialogData.price" matInput type="number" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'MINPRICE' | translate}}</mat-label>
            <input [(ngModel)]="dialogData.minPrice" matInput type="number" />
        </mat-form-field>
    </div>
    <div fxLayout="row" style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field appearance="outline">
            <mat-label>{{'PERCENT' | translate}}</mat-label>
            <input [(ngModel)]="dialogData.percent" matInput type="number" />
        </mat-form-field>
        <mat-form-field floatLabel='never' appearance="outline">
            <mat-label>{{'CURRENCY' | translate}}</mat-label>
            <mat-select #currency [(ngModel)]="dialogData.currency" name="currency" [compareWith]="compareByOptionId">
                <mat-option *ngFor="let curr of currencies" [value]="curr">
                    {{curr.isocode}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field appearance="outline">
            <mat-label>{{'ERPCODE' | translate}}</mat-label>
            <input [(ngModel)]="dialogData.product!.erpCode" matInput />
        </mat-form-field>
        <mat-form-field floatLabel='never' appearance="outline">
            <mat-label>{{'UNIT' | translate}}</mat-label>
            <mat-select #productunit [(ngModel)]="dialogData.product!.productUnit" name="productunit"
                [compareWith]="compareByOptionId">
                <mat-option *ngFor="let pu of productUnits" [value]="pu">
                    {{pu.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div
        style="display:flex;flex-direction: row; justify-content: space-between; border-bottom:3px solid #C4C4C3; margin-bottom:10px;">
        <mat-label>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u> {{'OIL' | translate}}</mat-label>
        <mat-checkbox [(ngModel)]="dialogData.product!.excludeOilTariff" type="checkbox"></mat-checkbox>
    </div>
    <div fxLayout="row"
        style="display:flex;flex-direction: row; justify-content: space-between; border-bottom:3px solid #C4C4C3; margin-bottom:10px;">
        <mat-label>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u> {{'ENVIRONMENTFEE' | translate}}</mat-label>
        <mat-checkbox [(ngModel)]="dialogData.product!.excludeEnvironmentalTariff" type="checkbox"></mat-checkbox>
    </div>
    <div fxLayout="row"
        style="display:flex;flex-direction: row; justify-content: space-between; border-bottom:3px solid #C4C4C3; margin-bottom:10px;">
        <mat-label>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u> {{'CAPACITYFEE' | translate}}</mat-label>
        <mat-checkbox [(ngModel)]="dialogData.product!.excludeCapacityTariff" type="checkbox"></mat-checkbox>
    </div>
    <div fxLayout="row"
        style="display:flex;flex-direction: row; justify-content: space-between; border-bottom:3px solid #C4C4C3;">
        <mat-label>{{'CALCULATE' | translate}} {{'FROM' | translate}} {{'FREIGHTLINES' | translate}}</mat-label>
        <mat-checkbox [(ngModel)]="dialogData.product!.calcFromFreightLine">
        </mat-checkbox>
    </div>
    <div fxLayout="row"
        style="display:flex;flex-direction: row; justify-content: space-between; border-bottom:3px solid #C4C4C3;">
        <mat-label>{{'COPYALWAYS' | translate}}</mat-label>
        <mat-checkbox [(ngModel)]="dialogData.product!.copyAlways">
        </mat-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
    <button mat-raised-button [disabled]="dialogData.product!.name!.length == 0"
        style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' | translate}}</button>
</mat-dialog-actions>