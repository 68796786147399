import { Injectable } from '@angular/core';
import { roadtax } from '../Models/roadtax';
import { RoadtaxapiService } from '../api/roadtaxapi.service';

@Injectable({
  providedIn: 'root'
})
export class RoadtaxshareService {

  constructor(
    private api: RoadtaxapiService,
    
  ) { }

  public roadtaxes: roadtax[] = [];

  async initialize(roadtax: roadtax) {
    await this.api.getAll(roadtax).then(response => {
      if (response && response.modelObject) {
        let res: roadtax[] = response.modelObject;
        if (res) {
          res.sort((a, b) => a.date! > b.date! ? -1 : a.date == b.date ? 0 : 1);
        }
        this.roadtaxes = res;
      }
    });
  }

  async delete(roadtax: roadtax) {
    await this.api.delete(roadtax).then(() => {

    });
  }

  async saveorupdate(roadtax?: roadtax) {
    if (roadtax){
      await this.api.saveorupdate(roadtax).then(() => {

      })
    }
  }

  async saveOrUpdateAll(roadtaxes?: roadtax[]) {
    if (roadtaxes) {
      await this.api.saveOrUpdateAll(roadtaxes).then(res => {

      });
    }
  }
}
