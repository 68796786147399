import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';
import { AdditionsComponent } from './additions/additions.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCfgModule } from './appcfg/appcfg.module';
import { AuthGuard } from './auth/auth.guard';
import { CustomerRulesComponent } from './customer-rules/customer-rules.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { CustomerComponent } from './customer/customer.component';
import { EditPricesheetDialog } from './customer/edit-pricesheet-dialog/edit-pricesheet.dialog';
import { NewPricesheetDateDialog } from './customer/new-pricesheet-date-dialog/new-pricesheet-date.dialog';
import { NewPricesheetDialog } from './customer/new-pricesheet-dialog/new-pricesheet.dialog';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { PriceCalcComponent } from './price-calc/price-calc.component';
import { CustomerSettingsDialog } from './pricesheet/customer-settings-dialog/customer-settings.dialog';
import { NewPriceSheetDialog } from './pricesheet/new-pricesheet-dialog/new-pricesheet.dialog';
import { PriceSheetDialog } from './pricesheet/pricesheet-dialog/pricesheet.dialog';
import { PricesheetComponent } from './pricesheet/pricesheet.component';
import { PricesheetpricesComponent } from './pricesheetprices/pricesheetprices.component';
import { PricesheetzonesComponent } from './pricesheetzones/pricesheetzones.component';
import { ProductsComponent } from './products/products.component';
import { CapacityTaxComponent } from './setup/capacity-tax/capacity-tax.component';
import { NewCapacityTaxDialog } from './setup/capacity-tax/new-capacity-tax-dialog/new-capacity-tax.dialog';
import { ColliMeasurementComponent } from './setup/colli-measurement/colli-measurement.component';
import { NewColliMeasurementDialog } from './setup/colli-measurement/new-colli-measurement-dialog/new-colli-measurement.dialog';
import { ColliTypeTranslateComponent } from './setup/colli-type-translate/colli-type-translate.component';
import { NewColliTypeTranslateDialog } from './setup/colli-type-translate/new-colli-type-translate-dialog/new-colli-type-translate.dialog';
import { EnvironmentalTaxComponent } from './setup/environmental-tax/environmental-tax.component';
import { NewEnvironmentalTaxDialog } from './setup/environmental-tax/new-environmental-tax-dialog/new-environmental-tax.dialog';
import { NewOilDialog } from './setup/oil/new-oil-dialog/new-oil.dialog';
import { OilComponent } from './setup/oil/oil.component';
import { NewOmexDialog } from './setup/omex/new-omex-dialog/new-omex.dialog';
import { OmexComponent } from './setup/omex/omex.component';
import { NewTerminalDialog } from './setup/terminal/new-terminal-dialog/new-terminal.dialog';
import { TerminalComponent } from './setup/terminal/terminal.component';
import { AuthService } from './shared/api/auth.service';
import { ConfirmDialogComponent } from './shared/util/confirm-dialog/confirm-dialog.component';
import { MatSpinnerOverlayComponent } from './shared/util/mat-spinner-overlay.component';
import { StandardPricesheetDialog } from './standard-pricesheet/standard-pricesheet-dialog/standard-pricesheet-dialog';
import { StandardPricesheetComponent } from './standard-pricesheet/standard-pricesheet.component';
import { StandardProductDialog } from './standard-products/standard-products-dialog/standard-products.dialog';
import { StandardProductsComponent } from './standard-products/standard-products.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { StandardproductsnewComponent } from './standardproductsnew/standardproductsnew.component';
import { DeveloperformComponent } from './shared/util/developerform/developerform.component';
import { CustomerconversionrulesComponent } from './setup/customerconversionrules/customerconversionrules.component';
import { AllcustomersComponent } from './allcustomers/allcustomers.component';
import { PostadjustmentComponent } from './postadjustment/postadjustment.component';
import { ProgressdialogComponent } from './shared/util/progressdialog/progressdialog.component';
import { RoadtaxComponent } from './setup/roadtax/roadtax.component';
import { RoadtaxeditdialogComponent } from './setup/roadtax/roadtaxeditdialog/roadtaxeditdialog.component';


registerLocaleData(localeDa);


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lang/', '.json');
}
// Should help Format dates in mat date picker
export const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'yyyy-MM-dd'
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthLabel: 'MM YYYY',
    dateA11yLabel: 'DA',
    monthYearLabel: 'MM YYYY',
    monthYearA11yLabel: 'DA'
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CustomerComponent,
    CustomerRulesComponent,
    PricesheetComponent,
    PriceSheetDialog,
    NewPriceSheetDialog,
    CustomerSettingsDialog,
    NewOilDialog,
    StandardPricesheetDialog,
    NewPricesheetDateDialog,
    NewPricesheetDialog,
    NewTerminalDialog,
    StandardProductDialog,
    NewColliTypeTranslateDialog,
    NewColliMeasurementDialog,
    NewCapacityTaxDialog,
    NewEnvironmentalTaxDialog,
    NewOmexDialog,
    ConfirmDialogComponent,
    PricesheetpricesComponent,
    PricesheetzonesComponent,
    ProductsComponent,
    MatSpinnerOverlayComponent,
    LoginComponent,
    StandardPricesheetComponent,
    HeaderComponent,
    StandardProductsComponent,
    PriceCalcComponent,
    TerminalComponent,
    OmexComponent,
    OilComponent,
    AdditionsComponent,
    CustomerSettingsComponent,
    ColliTypeTranslateComponent,
    EditPricesheetDialog,
    ColliMeasurementComponent,
    CapacityTaxComponent,
    EnvironmentalTaxComponent,
    ClientSettingsComponent,
    StandardproductsnewComponent,
    DeveloperformComponent,
    CustomerconversionrulesComponent,
    AllcustomersComponent,
    PostadjustmentComponent,
    ProgressdialogComponent,
    RoadtaxComponent,
    RoadtaxeditdialogComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppCfgModule,
    SpreadsheetAllModule,
    DropDownListModule,
    DragDropModule,
     TranslateModule.forRoot({
      defaultLanguage: 'da',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule
  ],
  providers:
    [DatePipe, AuthService, AuthGuard,
      { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
      { provide: LOCALE_ID, useValue: 'da' },
      { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000 } },
      { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
    ],
  bootstrap: [AppComponent],
  entryComponents: [PriceSheetDialog,
    EditPricesheetDialog,
    ConfirmDialogComponent,
    NewPricesheetDialog,
    NewPricesheetDateDialog,
    CustomerSettingsDialog,
    NewTerminalDialog,
    NewCapacityTaxDialog,
    NewOmexDialog,
    NewOilDialog,
    NewPriceSheetDialog,
    DeveloperformComponent,
    NewEnvironmentalTaxDialog,
    StandardProductDialog,
    NewColliMeasurementDialog,
    NewColliTypeTranslateDialog,
    StandardPricesheetDialog,
    CustomerconversionrulesComponent,
    PostadjustmentComponent,
    RoadtaxeditdialogComponent]
})
export class AppModule { }
