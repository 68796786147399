import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { Customer } from 'src/app/shared/Models/customer';
import { roadtax } from 'src/app/shared/Models/roadtax';
import { RoadtaxshareService } from 'src/app/shared/share/roadtaxshare.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { RoadtaxeditdialogComponent } from './roadtaxeditdialog/roadtaxeditdialog.component';

@Component({
  selector: 'app-roadtax',
  templateUrl: './roadtax.component.html',
  styleUrls: ['./roadtax.component.scss']
})
export class RoadtaxComponent implements OnInit {

  constructor(
    private roadtaxShare: RoadtaxshareService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private data: DataShareService,
    private customerService: CustomerService,
    private dialog: MatDialog,

  ) { }

  displayedColumns: string[] = ["customer", "date", "procent", "erpCode", "action"]; // currency
  roadtaxes: roadtax[] = [];
  roadtaxesFiltered: roadtax[] = [];
  roadtaxesDatasource!: MatTableDataSource<roadtax>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customers: Customer[] = [];
  loading: any;

  filterValue = '';
  updateFilter = new Subject<string>();

  ngOnInit(): void {
    this.getAllRoadTaxes();
    this.getCustomers();
    this.updateFilter.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe(value=>{
      this.roadtaxesFiltered = this.roadtaxes.filter((element) => {
        if (value == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      this.roadtaxesDatasource = new MatTableDataSource(this.roadtaxesFiltered);
      this.roadtaxesDatasource.paginator = this.paginator;
    });

  }

  getAllRoadTaxes() {
    this.loading = true;
    this.roadtaxShare.initialize({licenseRef: this.data.License.licenseRef!}).then(() => {
      this.roadtaxes = this.roadtaxShare.roadtaxes;
      for (let i = 0; i < this.roadtaxes.length; i++) {
        if (this.roadtaxes[i].date) {
          this.roadtaxes[i].date = this.datePipe.transform(this.roadtaxes[i].date, 'yyyy-MM-dd')!;
        }
      }
      this.roadtaxesFiltered = this.roadtaxes.filter((element) => {
        if (this.filterValue == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
      });
      this.roadtaxesDatasource = new MatTableDataSource(this.roadtaxesFiltered);
      this.roadtaxesDatasource.paginator = this.paginator;
      this.loading = false;
    });
  }

  getCustomers() {
    this.loading = true;
    let customer: Customer;
    customer = {
      licenseRef: this.data.LicenseRef,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.customers = this.customers.filter(x => x.name);
      this.customers.unshift({ name: 'Ingen valgt' });
      this.roadtaxesFiltered = this.roadtaxes.filter((element) => {
        if (this.filterValue == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
      });
      this.roadtaxesDatasource = new MatTableDataSource(this.roadtaxesFiltered);
      this.roadtaxesDatasource.paginator = this.paginator;
    }).finally(() => this.loading = false);
  }

  sortData(sort: Sort) {
    const data = this.roadtaxesFiltered.slice();
    if (!sort.active || sort.direction === '') {
      this.roadtaxesFiltered = data;
      return;
    }

    this.roadtaxesFiltered = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'customer':
          let customerA = this.customers.find(element=> element.id == a.fk_customerId);
          let customerB = this.customers.find(element=> element.id == b.fk_customerId);
          return this.compare(customerA!.name, customerB!.name, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'percent':
          return this.compare(a.procent, b.procent, isAsc);
        default:
          return 0;
      }
    });
    this.roadtaxesDatasource = new MatTableDataSource(this.roadtaxesFiltered);
    this.roadtaxesDatasource.paginator = this.paginator;
  }  
  compare(a: any, b: any, isAsc: boolean) {
    if(a==b)return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareByOptionId(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst == refSecond;
  }

  checkData(roadtax: any) {
    if (roadtax.id > 0) {
      roadtax.editable = true;
    }
  }

  updateRoadTax(roadtax: roadtax) {
    this.loading = true;
    if (roadtax) {
      if (!roadtax.licenseRef) {
        roadtax.licenseRef = this.data.LicenseRef;
      }
      if (roadtax.date) {
        roadtax.date = this.datePipe.transform(roadtax.date, 'yyyy-MM-dd')!;

      }
      this.roadtaxShare.saveorupdate(roadtax).then((res: any) => {
        if (res) {
          this.getAllRoadTaxes();
        }
      }).finally(() => {
        this.loading = false;
        this.snackBar.open("Miljø Tillæg Opdateret", "Success", { duration: 3000, panelClass: ['snack-bar'] });
      });
    }
  
  }

  deleteRoadTax(roadtax: roadtax) {
    if (roadtax) {
      if (!roadtax.licenseRef) {
        roadtax.licenseRef = this.data.LicenseRef;
      }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {width: "16%", height: "18%", disableClose: true, data: {
        title: "Er du sikker?", // Translate 
        message: "Du er ved at slette :  <b>Vejskat</b>" // Translate
      }
      });
      dialogRef.keydownEvents().subscribe(key => {
        if (key.code === 'Ecsape') {
          dialogRef.close();
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          this.roadtaxShare.delete(roadtax).then((res: any) => {
              this.getAllRoadTaxes();
          }).finally(() => {
            this.snackBar.open("Vejskat Tillæg Slettet", "Success", { duration: 3000, panelClass: ['snack-bar'] });
          });
        }
        else{
          this.snackBar.open("Intet blev slettet.", "Annulleret", { duration: 3000, panelClass: ['snack-bar'] });
        }
      });
    }
  }

  newRoadTax() {
    let dummy: roadtax = {licenseRef: this.data.License.licenseRef!}
    const dialogRef = this.dialog.open(RoadtaxeditdialogComponent, {data: dummy, disableClose: true});
    dialogRef.keydownEvents().subscribe(key => {
      if (key.code === 'Escape'){
        dialogRef.close();
      }    
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.roadtaxShare.saveorupdate(response).then(() => {
          this.getAllRoadTaxes();
        });
      }
    })
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {width: "16%", height: "18%", data: {
        title: "Er du sikker?", // Translate 
        message: "Du er ved at gemme :  <b>Alle Miljø Tillæg</b>" // Translate
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.roadtaxesDatasource.data.length; i++) {
          if (this.roadtaxesDatasource.data[i].date) {
            this.roadtaxesDatasource.data[i].date = this.datePipe.transform(this.roadtaxesDatasource.data[i].date, 'yyyy-MM-dd')!;
          }
          if (!this.roadtaxesDatasource.data[i].fk_customerId) {
            this.roadtaxesDatasource.data.splice(i, 1);
          }
          else {
            if (!this.roadtaxesDatasource.data[i].id) {

              this.updateRoadTax(this.roadtaxesDatasource.data[i]);
            }
          }
        }
        this.roadtaxShare.saveOrUpdateAll(this.roadtaxesDatasource.data).then((res: any) => {
          if (res) {
            this.getAllRoadTaxes();
          }
        }).finally(() => {
          this.loading = false;
          this.snackBar.open("Alle Miljø Tillæg Gemt", "Success", { duration: 3000, panelClass: ['snack-bar'] });
        });
      }
    });
  }

}
