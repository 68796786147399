import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ColliTypeService } from 'src/app/shared/api/collitype.service';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { ColliType } from 'src/app/shared/Models/collitype';
import { ColliTypeMeasurement } from 'src/app/shared/Models/colliTypeMeasurement';
import { Customer } from 'src/app/shared/Models/customer';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewColliMeasurementDialog } from './new-colli-measurement-dialog/new-colli-measurement.dialog';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-colli-measurement',
  templateUrl: './colli-measurement.component.html',
  styleUrls: ['./colli-measurement.component.scss']
})
export class ColliMeasurementComponent implements OnInit {

  @Input() selectedCustomer?: Customer;
  collitypeMeasurements: ColliTypeMeasurement[] = [];
  collitypeMeasurementsFiltered: ColliTypeMeasurement[] = [];
  collitypeMeasurementsDataSource!: MatTableDataSource<ColliTypeMeasurement>;
  colliTypes: ColliType[] = [];
  customers: Customer[] = [];
  loading: any;
  displayedColumns: string[] = ["collitype", "length", "width", "height", "weight", "volume", "ldm", "priceCode", "customer", "springCollitypeRef", "action"];
  filterValue: string = "";
  updateFilter = new Subject<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private data: DataShareService,
    private dialog: MatDialog,
    private collitypeService: ColliTypeService,
    private _snackBar: MatSnackBar,
    private customerService: CustomerService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.getallColliMeasurements();
    this.getAllColliTypes();
    this.getCustomers();
    this.updateFilter.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe(value => {
        this.collitypeMeasurementsFiltered = this.collitypeMeasurements.filter((element) => {
          if (value == "") return true;
          let returnVal = false;
          if (element.customerRef) {
            let customer = this.customers.find((custElement) => custElement.customerexternRef?.toLowerCase() == element.customerRef?.toLowerCase());
            if (!customer) {
              returnVal = returnVal || false;
            } else {
              returnVal = returnVal || customer!.name!.toLowerCase().indexOf(value.toLowerCase()) >= 0;
            }
          }
          if (element.fk_collitypeId) {
            let colliType = this.colliTypes.find(colElement => colElement.id == element.fk_collitypeId);
            if (!colliType) {
              returnVal = returnVal || false;
            } else {
              returnVal = returnVal || colliType!.name!.toLowerCase().indexOf(value.toLowerCase()) >= 0;
            }
          }
          if (element.priceCode) {
            returnVal = returnVal || element.priceCode.toString().toLowerCase() == value.toLowerCase();
          }
          return returnVal;
        });
        this.collitypeMeasurementsDataSource = new MatTableDataSource(this.collitypeMeasurementsFiltered);
        this.collitypeMeasurementsDataSource.paginator = this.paginator;
      });
  }

  sortData(sort: Sort) {
    const data = this.collitypeMeasurementsFiltered.slice();
    if (!sort.active || sort.direction === '') {
      this.collitypeMeasurementsFiltered = data;
      return;
    }

    this.collitypeMeasurementsFiltered = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      let colliTypeA;
      let colliTypeB;
      let returnVal = 0;
      switch (sort.active) {
        case 'collitype':
          colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
          colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
          return this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
        case 'length':
          returnVal = this.compare(a.length ?? 0, b.length ?? 0, isAsc);
          if (returnVal == 0) {
            returnVal = this.compare(a.priceCode ?? "", b.priceCode ?? "", isAsc);
            if (returnVal == 0) {
              colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
              colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
              returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
            }
          }
          return returnVal;
        case 'width':
          returnVal = this.compare(a.width ?? 0, b.width ?? 0, isAsc);
          if (returnVal == 0) {
            returnVal = this.compare(a.priceCode ?? "", b.priceCode ?? "", isAsc);
            if (returnVal == 0) {
              colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
              colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
              returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
            }
          }
          return returnVal;
        case 'height':
          returnVal = this.compare(a.height ?? 0, b.height ?? 0, isAsc);
          if (returnVal == 0) {
            returnVal = this.compare(a.priceCode ?? "", b.priceCode ?? "", isAsc);
            if (returnVal == 0) {
              colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
              colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
              returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
            }
          }
          return returnVal;
        case 'weight':
          returnVal = this.compare(a.weight ?? 0, b.weight ?? 0, isAsc);
          if (returnVal == 0) {
            returnVal = this.compare(a.priceCode ?? "", b.priceCode ?? "", isAsc);
            if (returnVal == 0) {
              colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
              colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
              returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
            }
          }
          return returnVal;
        case 'volume':
          returnVal = this.compare(a.volume ?? 0, b.volume ?? 0, isAsc);
          if (returnVal == 0) {
            returnVal = this.compare(a.priceCode ?? "", b.priceCode ?? "", isAsc);
            if (returnVal == 0) {
              colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
              colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
              returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
            }
          }
          return returnVal;
        case 'ldm':
          returnVal = this.compare(a.ldm ?? 0, b.ldm ?? 0, isAsc);
          if (returnVal == 0) {
            returnVal = this.compare(a.priceCode ?? "", b.priceCode ?? "", isAsc);
            if (returnVal == 0) {
              colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
              colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
              returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
            }
          }
          return returnVal;
        case 'customer':
          let customerA = undefined;
          let customerB = undefined;
          if(a.customerRef){
            customerA = this.customers.find(cust => cust.customerexternRef?.toLowerCase() == a.customerRef?.toLowerCase());
          }
          if(b.customerRef){
            customerB = this.customers.find(cust => cust.customerexternRef?.toLowerCase() == b.customerRef?.toLowerCase());
          }
          returnVal = this.compare(customerA?.name ?? "", customerB?.name ?? "", isAsc);
          if (returnVal == 0) {
            colliTypeA = this.colliTypes.find(element => element.id == a.fk_collitypeId);
            colliTypeB = this.colliTypes.find(element => element.id == b.fk_collitypeId);
            returnVal = this.compare(colliTypeA!.name, colliTypeB!.name, isAsc);
          }
          return returnVal;
        default:
          return 0;
      }
    });
    this.collitypeMeasurementsDataSource = new MatTableDataSource(this.collitypeMeasurementsFiltered);
    this.collitypeMeasurementsDataSource.paginator = this.paginator;
  }

  compare(a: any, b: any, isAsc: boolean) {
    if(a==b)return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  newColliMeasurement() {
    const dialogRef = this.dialog.open(NewColliMeasurementDialog, { disableClose: true });

    dialogRef.afterClosed().subscribe((colliMeasurement: any) => {
      if (colliMeasurement) {
        colliMeasurement.licenseRef = this.data.LicenseRef;
        if (colliMeasurement.colliType) {
          colliMeasurement.fk_collitypeId = colliMeasurement.colliType.id;
        }
        if (colliMeasurement.volume) {
          colliMeasurement.volume = Number(colliMeasurement.volume.toString().replace(',', '.'));
        }
        if (colliMeasurement.length) {
          colliMeasurement.length = Number(colliMeasurement.length.toString().replace(',', '.'));
        }
        if (colliMeasurement.width) {
          colliMeasurement.width = Number(colliMeasurement.width.toString().replace(',', '.'));
        }
        if (colliMeasurement.height) {
          colliMeasurement.height = Number(colliMeasurement.height.toString().replace(',', '.'));
        }
        if (colliMeasurement.weight) {
          colliMeasurement.weight = Number(colliMeasurement.weight.toString().replace(',', '.'));
        }
        if (colliMeasurement.ldm) {
          colliMeasurement.ldm = Number(colliMeasurement.ldm.toString().replace(',', '.'));
        }
        this.collitypeService.saveOrUpdateColliTypeMeasurement(colliMeasurement).then((res: any) => {
          if (res) {

          }
        }).finally(() => {
          this._snackBar.open("Godstype Mål Gemt", "Sucess", { duration: 1500, panelClass: ['snack-bar'] });
          this.getallColliMeasurements();
        });
      }
    });
  }

  getallColliMeasurements() {
    this.loading = true;
    let collMeasure: ColliTypeMeasurement = {
      licenseRef: this.data.LicenseRef,
      includeColliType: true
    };

    if (this.selectedCustomer) {
      let defaultColliMeas: ColliTypeMeasurement[] = [];
      collMeasure.customerRef = this.selectedCustomer.customerNr != null ? this.selectedCustomer.customerNr : this.selectedCustomer.customerexternRef;
      this.collitypeService.getAllColliTypeMeasurementByDefaults(collMeasure).then((res: any) => {
        if (res && res.modelObject){
          defaultColliMeas = res.modelObject;
        }
        this.collitypeService.getAllColliTypeMeasurementByCustomer(collMeasure).then((res: any) => {
          if (res) {
            if (res.modelObject) 
            { 
              this.collitypeMeasurements = res.modelObject; 
              if (defaultColliMeas){
                defaultColliMeas.forEach(item => {
                  this.collitypeMeasurements.push(item);
                });
              }
            }
            else { 
              this.collitypeMeasurements = []; 
              if (defaultColliMeas) this.collitypeMeasurements = defaultColliMeas;
            }
            this.collitypeMeasurements.unshift({ licenseRef: this.data.LicenseRef, customerRef: this.selectedCustomer?.customerexternRef });
            this.collitypeMeasurementsFiltered = this.collitypeMeasurements.filter((element) => {
              if (this.filterValue == "") return true;
              let returnVal = false;
              if (element.fk_customerId) {
                let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
                if (!customer) returnVal = returnVal || false;
                returnVal = returnVal || customer!.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
              }
              if (element.fk_collitypeId) {
                let colliType = this.colliTypes.find(colElement => colElement.id == element.fk_collitypeId);
                if (colliType) returnVal = returnVal || false;
                returnVal = returnVal || colliType!.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
              }
              if (element.priceCode) {
                returnVal = returnVal || element.priceCode.toString().toLowerCase() == this.filterValue.toLowerCase();
              }
              return returnVal;
            });
            this.collitypeMeasurementsDataSource = new MatTableDataSource(this.collitypeMeasurementsFiltered);
            this.collitypeMeasurementsDataSource.paginator = this.paginator;
          }
        });
      }).finally(() => this.loading = false);
    }
    else {
      this.collitypeService.getAllColliTypeMeasurement(collMeasure).then((res: any) => {
        if (res) {
          if (res.modelObject) {
            this.collitypeMeasurements = res.modelObject;
          }
          else {
            this.collitypeMeasurements = [];
          }
          // this.collitypeMeasurements.unshift({ licenseRef: this.data.License });
          this.collitypeMeasurementsDataSource = new MatTableDataSource(this.collitypeMeasurements);
          this.collitypeMeasurementsDataSource.paginator = this.paginator;
        }
      }).finally(() => this.loading = false);
    }
  }

  getAllColliTypes() {
    let colliType: ColliType = {
      licenseRef: this.data.LicenseRef
    };
    this.collitypeService.getAllColliTypes(colliType).then((res: any) => {
      if (res) {
        this.colliTypes = res.modelObject;
        this.colliTypes.sort((a: any, b: any) => (a.name > b.name) ? 1 : (a.name == b.name) ? 0 : -1)
      }
    });
  }

  checkData(colms: any) {
    if (colms.id > 0) {
      colms.editable = true;
    }
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate
        message: "Du er ved at gemme :  <b>Alle Gods Mål</b>" // Translate
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.collitypeMeasurementsDataSource.data.length; i++) {
          if (!this.collitypeMeasurementsDataSource.data[i].id) {
            if (this.collitypeMeasurementsDataSource.data[i].fk_collitypeId) {
              continue;
            }
            else {
              this.collitypeMeasurementsDataSource.data.splice(i, 1);
            }
          }
          if (this.collitypeMeasurementsDataSource.data[i].colliType) {
            this.collitypeMeasurementsDataSource.data[i].fk_collitypeId = this.collitypeMeasurementsDataSource.data[i].colliType?.id;
          }
        }

        this.collitypeService.bulkSaveOrUpdateColliTypeMeasurements(this.collitypeMeasurementsDataSource.data).then((res: any) => {
          if (res) {
            this.fetchData();
          }
        }).finally(() => {
          this.loading = false;
          this._snackBar.open("Alle Colli Mål Gemt", "Success", { panelClass: ['snack-bar'] });
        });
      }
    });

  }

  deleteColliTypeMeasurement(colms: any) {
    if (colms) {
      this.collitypeService.deleteColliTypeMeasurement(colms).then((res: any) => {
        if (res) {
          this.getallColliMeasurements();
        }
      });
    }
  }

  updateColliTypeMeasurement(colms: ColliTypeMeasurement) {
    if (colms) {
      if (colms.colliType) {
        colms.fk_collitypeId = colms.colliType.id;
      }
      debugger;
      if (colms.volume) {
        colms.volume = Number(colms.volume.toString().replace(',', '.'));
      }else{
        colms.volume = undefined;
      }
      if (colms.length) {
        colms.length = Number(colms.length.toString().replace(',', '.'));
      }else{
        colms.length = undefined;
      }
      if (colms.width) {
        colms.width = Number(colms.width.toString().replace(',', '.'));
      }else{
        colms.width = undefined;
      }
      if (colms.height) {
        colms.height = Number(colms.height.toString().replace(',', '.'));
      }else{
        colms.height = undefined;
      }
      if (colms.weight) {
        colms.weight = Number(colms.weight.toString().replace(',', '.'));
      }else{
        colms.weight = undefined;
      }
      if (colms.ldm) {
        colms.ldm = Number(colms.ldm.toString().replace(',', '.'));
      }else{
        colms.ldm = undefined;
      }
      this.collitypeService.saveOrUpdateColliTypeMeasurement(colms).then((res: any) => {
        this.getallColliMeasurements();
      });
    }
  }

  getCustomers(): void {
    let customer: Customer;
    customer = {
      licenseRef: this.data.LicenseRef,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.customers = this.customers.filter((x: any) => x.name);
      this.customers.unshift({ name: 'Ingen valgt' }); // Translate
    });
  }

  // Helper Comparer methods
  compareByOptionId(idFirst: any, idSecond: any) {
    return idFirst && idSecond && idFirst.id == idSecond.id;
  }

  compareByOptionSimple(first: any, second: any) {
    return first && second && first == second;
  }

  compareByOptionRef(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst == refSecond;
  }

}
