<div style="display: flex; flex-direction: column; justify-content: center; margin-top: 8px; margin-left: 300px;">
    <div style="width: 240px;">
        <div *ngIf="sheetRule && sheetRule.id">
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'WEIGHTTOVOLUME' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="sheetRule.weightprvolume">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'WEIGHTTOLDM' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="sheetRule.weightprldb">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'PALLETTOLDM' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="sheetRule.pllprldm">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'ROUNDING' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="sheetRule.pllRound">
                </mat-form-field>
            </div>
            <!--Per damgaard license-->
            <div>
                <mat-form-field class="formField" *ngIf="data.LicenseRef == 'E9583DDB-13C4-461A-AFEB-5BBB12F25947'">
                    <mat-label class="textColorDark">{{'DEFAULTPRICECODE' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="sheetRule.defaultPriceCode">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField" floatLabel="always"  >
                    <mat-slide-toggle [(ngModel)]="sheetRule.calcPalletSpaces" ><span class="text-invisible">123</span></mat-slide-toggle>
                    <mat-label class="textColorDark">{{'CALCPALLETSPACES' | translate}}</mat-label>
                    <input matInput type="text" hidden />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-select class="textColorDark" [(ngModel)]="customerRule.oilpaydatetype">
                        <mat-option *ngFor="let oil of data.oilpayrule" [value]="oil.key">
                            {{oil.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!sheetRule || !sheetRule.id" style="background-color: #cecece;">
            <div style="height: 16px;"></div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'WEIGHTTOVOLUME' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="customerRule.weightprvolume">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'WEIGHTTOLDM' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="customerRule.weightprldb">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'PALLETTOLDM' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="customerRule.pllprldm">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-label class="textColorDark">{{'ROUNDING' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="customerRule.pllRound">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-select class="textColorDark" [(ngModel)]="customerRule.oilpaydatetype">
                        <mat-option *ngFor="let oil of data.oilpayrule" [value]="oil.key">
                            {{oil.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <!--Per damgaard license-->
            <div>
                <mat-form-field class="formField" *ngIf="data.LicenseRef == 'E9583DDB-13C4-461A-AFEB-5BBB12F25947'">
                    <mat-label class="textColorDark">{{'DEFAULTPRICECODE' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="customerRule.defaultPriceCode">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField" floatLabel="always" >
                    <mat-slide-toggle [(ngModel)]="customerRule.calcPalletSpaces" ><span class="text-invisible">123</span></mat-slide-toggle>
                    <mat-label class="textColorDark">{{'CALCPALLETSPACES' | translate}}</mat-label>
                    <input matInput type="text" hidden />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="formField">
                    <mat-select class="textColorDark" [(ngModel)]="customerRule.oilpaydatetype">
                        <mat-option *ngFor="let oil of data.oilpayrule" [value]="oil.key">
                            {{oil.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="saveBtn" style="margin-top: 8px;">
            <button (click)="saveRule()" mat-raised-button>{{'SAVE' | translate}}</button>
            <div *ngIf="sheetRule && sheetRule.id">
                <button mat-raised-button (click)="removeRule()"
                    style="margin-left: 32px; background-color: var(--primary-red); color: white;"
                    matTooltip="{{'REMOVESHEETRULES' | translate}}">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>