import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../shared/api/auth.service';
import { DataShareService } from '../shared/api/datashare.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DeveloperformComponent } from '../shared/util/developerform/developerform.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isValue: any;
  menuTopLeftPosition = { x: '0', y: '0' }
  maxCount: number = 3;
  count: number = 0;
  isProduction = false;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isLoggedIn$!: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver, 
    private authService: AuthService, 
    private elementRef: ElementRef, 
    public data: DataShareService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) 
  { this.isProduction = environment.production }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  logout() {
    this.isValue = 1;
    this.authService.logout();
  }
 getMainToolTip(): string{
  if (this.data.License.creditorPrices) {
    if (this.data.creditorPrices){
      return "Skift til kundepriser"
    }
    return "Skift til vognmandspriser";      
  }else{
    return "";
  }
 }
  toggle(val: any) { this.isValue = val; }

  openDeveloper() {
    const dialogRef = this.dialog.open(DeveloperformComponent)

  }

  onRightClick(event: MouseEvent, item: any) {
    this.count++;
    event.preventDefault();
    if (this.count == this.maxCount) {
      this.count = 0;
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      this.matMenuTrigger.menuData = { item: item }
      this.matMenuTrigger.openMenu();
    }

  }

  priceSelect(type: string) {
    if (type == 'Customer') {
      this.data.customerPrices = true;
      this.data.creditorPrices = false;
      this.elementRef.nativeElement.ownerDocument.body.style.setProperty('--primary-blue', '#337ab7');
    }
    if (type == 'Creditor') {
      this.data.customerPrices = false;
      this.data.creditorPrices = true;
      this.elementRef.nativeElement.ownerDocument.body.style.setProperty('--primary-blue','#0f8845')
    }
  }

  chooseColor(color: any) {
    var primColor = "";
    if (color == "random") { // Set Random color if random
      primColor = "#" + Math.round(Math.random() * 1000);
    }
    this.elementRef.nativeElement.ownerDocument.body.style.setProperty('--primary-blue', primColor);

    if (color == "random") { // Recursive
      setTimeout(() => { this.chooseColor("random") }, 500);
    } else {
      this.elementRef.nativeElement.ownerDocument.body.style.setProperty('--primary-blue', color);
    }
  }

}
