import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { PricesheetTypeService } from "src/app/shared/api/pricesheettype.service";
import { PricesheetType } from "src/app/shared/Models/pricesheetType";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'new-pricesheet.dialog.html'
})
export class NewPriceSheetDialog {

    constructor(
        private datePipe: DatePipe, 
        public dialogRef: MatDialogRef<NewPriceSheetDialog>, 
        private pricesheetTypeService: PricesheetTypeService, 
        private data: DataShareService
    ) {
        this.date = new Date();
        this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
        let priceSheetType: PricesheetType = {
            licenseRef: this.data.LicenseRef,
            name: ''
        }
        this.pricesheetTypeService.getAll(priceSheetType).then((res) => {
            this.types = res.modelObject;
            if (this.types) {
                this.chosenType = this.types[1];
            }
        });
    }

    name: string = '';
    date: any;
    chosenType: any;
    saveBtnDisabled = true;
    types: any;
    dialogData = <any>{};

    save() {
        this.dialogRef.close({ name: this.name, type: this.chosenType, date: this.date });
    }

    close() {
        this.dialogRef.close();
    }

}