import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { LicenseService } from "src/app/shared/api/license.service";
import { PricesheetTypeService } from "src/app/shared/api/pricesheettype.service";
import { PricesheetType } from "src/app/shared/Models/pricesheetType";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'pricesheet.dialog.html'
})
export class PriceSheetDialog implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PriceSheetDialog>, 
        private datePipe: DatePipe,
        private pricesheetTypeService: PricesheetTypeService,
        private dataShare: DataShareService,
    ) {
        this.date = new Date();
        this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    }

    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <any>{};
    selectedPricesheetType?: PricesheetType;
    pricesheetTypes: PricesheetType[] = [];

    ngOnInit(): void {
        debugger;
        this.pricesheetTypeService.getAll({licenseRef: this.dataShare.License.licenseRef}).then(response => {
            if (response && response.modelObject) {
                this.pricesheetTypes = response.modelObject;
            }
        });
    }

    save() {
        this.dialogRef.close({ name: this.name, date: this.date });
    }

    close() {
        this.dialogRef.close();
    }
}
