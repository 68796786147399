import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Customer } from 'src/app/shared/Models/customer';
import { CustomerService } from '../shared/api/customer.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss']
})
export class ClientSettingsComponent implements OnInit {
  
  @Input() selectedCustomer?: Customer;
  constructor(private customerService: CustomerService,
    private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {

  }

  saveAll(){
    this.customerService.updateClientPEValues(this.selectedCustomer!).then(()=>{
      this._snackBar.open("Kunde indstillinger gemt", "Success", { duration: 2000, panelClass: ['snack-bar'] });

    }).catch(()=>{
      this._snackBar.open("Kunde indstillinger kunne ikke gemmes", "Failure", { duration: 2000, panelClass: ['snack-bar'] });

    });
  }

}
