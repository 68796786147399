import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SpreadsheetComponent } from '@syncfusion/ej2-angular-spreadsheet';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { ColliTypeService } from '../shared/api/collitype.service';
import { CurrencyService } from '../shared/api/currency.service';
import { DataShareService } from '../shared/api/datashare.service';
import { MeasureUnitService } from '../shared/api/measure.service';
import { PriceCalcUnitService } from '../shared/api/pricecalcunit.service';
import { PricesheetpricesService } from '../shared/api/pricesheetprices.service';
import { ProductService } from '../shared/api/product.service';
import { ColliType, ColliTypeClass } from '../shared/Models/collitype';
import { Currency } from '../shared/Models/currency';
import { PriceCalcUnit } from '../shared/Models/priceCalcUnit';
import { Pricesheet } from '../shared/Models/pricesheet';
import { PricesheetPrice, PricesheetPriceViewModel } from '../shared/Models/pricesheetPrice';
import { Product } from '../shared/Models/product';

@Component({
  selector: 'app-pricesheetprices',
  templateUrl: './pricesheetprices.component.html',
  styleUrls: ['./pricesheetprices.component.scss',]
})
export class PricesheetpricesComponent implements OnInit, OnChanges, OnDestroy {
  editAll: any = false;
  // Arrays
  prices: PricesheetPriceViewModel[] = [];
  savePrices: PricesheetPriceViewModel[] = [];
  updatePrices: PricesheetPriceViewModel[] = [];
  filteredColliTypes$: any = [];
  products: Product[] = [];
  priceCalcUnits: PriceCalcUnit[] = [];
  //Objects 
  @Input() pricesheet = <Pricesheet>{};
  collitypeRequest = <ColliType>{};
  currencyRequest = <Currency>{};
  colliForm = <FormGroup>{};
  collitypeControl = new FormControl();
  colliTypes: any; colliTypesObs: any; currencies: any; chosenCurrency: any; measureUnits: any; chosenMeasureunit: any; maxRow: any;
  filteredPriceObservable?: Subscription;
  @ViewChild('spreadsheet') spreadsheetObj!: SpreadsheetComponent;

  constructor(private pricesheetpriceService: PricesheetpricesService,
    private colliTypeService: ColliTypeService,
    private data: DataShareService,
    private translate: TranslateService,
    private currencyService: CurrencyService,
    private fb: FormBuilder,
    private productService: ProductService,
    private priceCalcUnitService: PriceCalcUnitService,
    private measureUnitService: MeasureUnitService) {

  }
  ngOnDestroy(): void {
    this.prices = [];
    this.filteredPriceObservable!.unsubscribe();
  }

  ngOnInit(): void {
    this.filteredPriceObservable = this.data.filteredPrices$.subscribe((res) => {
      this.copyPrices(res);
    });
    this.getAllCurrencies();
    this.getColliTypes();
    this.getMeasureUnits();
    this.getAllProducts();
    this.getAllPriceCalcUnit();

    this.colliForm = this.fb.group({
      colliInput: null
    })
    this.filteredColliTypes$ = this.colliForm.valueChanges.pipe(debounceTime(300), switchMap(value => this.search(value.colliInput)));
    this.data.SerializePrices.subscribe((val: any) => {
      if (val) {
        this.checkExtraRows();
      }
    });
  }


  // Sets Dropdown Validation on specified columns
  cellEdit(event: any) {
    var rowNo = event.address.substring(8);
    if (rowNo == this.maxRow) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=priceCalcUnit!C2:C" + (this.priceCalcUnits.length + 1) }, 'H2:H' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=measureUnit!C2:C" + (this.measureUnits.length + 1) }, 'G2:G' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=currencies!D2:D" + (this.currencies.length + 1) }, 'J2:J' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=colliTypes!C2:C" + (this.colliTypes.length + 1) }, 'C2:C' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=products!B2:B" + (this.products.length + 1) }, 'B2:B' + (+rowNo + 1));
      this.maxRow++;
    }
  }

  // Fetches all data from Spreadsheet containing Prices
  checkExtraRows() {

    var json = this.spreadsheetObj.saveAsJson();

    var rows: any[] = [];
    json.then((val: any) => {

      this.savePrices = [];
      for (let i = 1; i < val.jsonObject.Workbook.sheets[0].rows.length; i++) {
        if (!val.jsonObject.Workbook.sheets[0].rows[i]) {
          break;
        }
        if (i != val.jsonObject.Workbook.sheets[0].rows.length - 1 && val.jsonObject.Workbook.sheets[0].rows[i].cells.length < 11) {
          this.data.errorSerializingPrices();
          return;
        }
        rows.push(val.jsonObject.Workbook.sheets[0].rows[i]);
      }

      for (let j = 0; j < rows.length; j++) {
        if (rows[j].cells.length < 9) {
          // Missing data / Not enough fields populated to create entry
          break;
        }

        if (rows[j].cells[4]?.value == undefined) {
          // continue;
        }
        if (rows[j].cells[5]?.value == undefined || rows[j].cells[9]?.value == undefined
          || rows[j].cells[4]?.value == undefined || rows[j].cells[6]?.value == undefined || rows[j].cells[7]?.value == undefined) {

          continue;
        }
        if (rows[j].cells[3] == null) {
          rows[j].cells[3] = 0;
        }
        if (rows[j].cells[2] == null) {
          rows[j].cells[2] = ' ';
        }
        if (rows[j].cells[1] == null) {
          rows[j].cells[1] = ' ';
        }
        if (rows[j].cells[11] == null) {
          rows[j].cells[11] = '';
        }

        if (rows[j].cells.slice(1).every((el: any) => el != null)) {
          var newPrice = {
            id: rows[j].cells[0] != null ? rows[j].cells[0].value : null,
            product: rows[j].cells[1].value,
            collitypeRef: rows[j].cells[2].value,
            currency: rows[j].cells[9].value,
            intervalFrom: parseFloat(rows[j].cells[4].value.toString().replace(',', '.')),
            intervalTo: parseFloat(rows[j].cells[5].value.toString().replace(',', '.')),
            measureUnit: rows[j].cells[6].value,
            priceCalcUnit: rows[j].cells[7].value,
            price: parseFloat(rows[j].cells[8].value.toString().replace(',', '.')),
            priceCode: rows[j].cells[3].value != null ? rows[j].cells[3].value : "",
            zoneNo: rows[j].cells[10].value,
            text: rows[j].cells[11] != null ? rows[j].cells[11].value : null,
            address: rows[j].cells[12] != null ? rows[j].cells[12].value : null,
            zipcode: rows[j].cells[13] != null ? rows[j].cells[13].value : null,
            countrycode: rows[j].cells[14] != null ? rows[j].cells[14].value : null,
          } as PricesheetPriceViewModel;
          newPrice.fk_pricesheetId = this.data.getSelectedPricesheet()?.id;
          this.savePrices.push(newPrice);
        }
      }

      this.data.WorkPrices = this.savePrices;
      this.data.doneSerializingZones();

    });

  }

  // Fires when datasource in spreadsheet changes
  dataSourceChanged(event: any): void {
    if (event.action == "edit") {
      if (event.data) {
        for (let i = 0; i < event.data!.length; i++) {
          for (let j = 0; j < this.prices.length; j++) {
            if (this.prices[j].id == event.data[i].id) {
              this.prices[j] = event.data[i] as PricesheetPrice;
            }
          }
        }
        this.data.WorkPrices = this.prices;
      }
    }

    if (event.action == "dataSourceChanged") {
      this.setHeaderRow();
      this.setDataValidation();
    }
  }

  selectCurrency(curr: any) {
    if (curr) {
      for (let i = 0; i < this.prices.length; i++) {
        this.prices[i].currency = curr;
        this.spreadsheetObj.updateCell({ value: curr.isocode }, 'D' + (i + 2));
      }
    }
  }

  selectMeasureUnit(ms: any) {
    if (ms) {
      for (let i = 0; i < this.prices.length; i++) {
        this.prices[i].measureUnit = ms;
        this.spreadsheetObj.updateCell({ value: ms.name }, 'G' + (i + 2));
      }
    }
  }

  selectProduct(prod: any) {
    if (prod) {
      for (let i = 0; i < this.prices.length; i++) {
        this.prices[i].product = prod;
        this.spreadsheetObj.updateCell({ value: prod.name }, 'B' + (i + 2));
      }
    }
  }

  search(name: string): Observable<ColliType[]> {
    return this.colliTypeService.getAllColliTypesSync(this.collitypeRequest)
      .pipe(
        tap((response: any) => {
          response.modelObject = response.modelObject
            .map((col: ColliType) => new ColliTypeClass(col))
            .filter((col: ColliType) => col.name!.toLowerCase().includes(name.toLowerCase()))
          return response.modelObject;
        })
      );
  }

  getMeasureUnits() {
    var licenseRef = this.data.LicenseRef;
    this.measureUnitService.getMeasureUnits(licenseRef).subscribe((res: any) => {
      this.measureUnits = res.modelObject;
      console.log("Measure: ", this.measureUnits)
    });
  }


  copyPrices(prices: PricesheetPrice[]) {

    const tmpArray = [];
    for (let i = 0; i < prices.length; i++) {
      const price = {
        id: prices[i].id ? prices[i].id : null,
        product: prices[i].product,
        collitypeRef: prices[i].collitypeRef,
        priceCode: prices[i].priceCode,
        intervalFrom: prices[i].intervalFrom,
        intervalTo: prices[i].intervalTo,
        measureUnit: prices[i].measureUnit,
        priceCalcUnit: prices[i].priceCalcUnit,
        price: prices[i].price,
        currency: prices[i].currency,
        zoneNo: prices[i].zoneNo,
        text: prices[i].text,
        address: prices[i].address,
        zipcode: prices[i].zipcode,
        countrycode: prices[i].countrycode
      } as PricesheetPriceViewModel;
      tmpArray.push(price);
    }
    this.prices = tmpArray;
    this.maxRow = (this.prices.length + 2);
    this.data.WorkPrices = this.prices;

  }

  getColliTypes() {
    this.colliTypeService.getAllColliTypes(this.collitypeRequest).then((res: any) => {
      if (res && res.modelObject) {
        this.data.ColliTypes = res.modelObject;
        this.colliTypes = res.modelObject;
        this.colliTypes!.sort((a: any, b: any) => (a.name > b.name) ? 1 : (a.name == b.name) ? 0 : -1);
      }
    }).catch((err) => {
      console.error("ERROR Getting Colli Types: ", err);
    });

  }

  displayFn(colli: ColliType) {
    if (colli) { return colli.name; }
    return "";
  }

  // Sets Dropdown Validation on next/new Row
  setDataValidation() {
    if (this.priceCalcUnits) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=priceCalcUnit!C2:C" + (this.priceCalcUnits.length + 1) }, 'H2:H' + (this.prices.length + 2));
    }
    if (this.measureUnits) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=measureUnit!C2:C" + (this.measureUnits.length + 1) }, 'G2:G' + (this.prices.length + 2));
    }
    if (this.currencies) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=currencies!D2:D" + (this.currencies.length + 1) }, 'J2:J' + (this.prices.length + 2));
    }

    if (this.colliTypes) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=colliTypes!C2:C" + (this.colliTypes.length + 1) }, 'C2:C' + (this.prices.length + 2));
    }
    if (this.products) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=products!B2:B" + (this.products.length + 1) }, 'B2:B' + (this.prices.length + 2));
    }
  }

  // Sets Bold Translated Headers in Spreadsheet
  setHeaderRow() {
    this.spreadsheetObj.cellFormat({ fontWeight: 'bold', fontSize: '14px' }, 'B1:O1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('PRODUCTS') }, 'B1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('COLLITYPE') }, 'C1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('CURRENCY') }, 'J1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('INTERVALFROM') }, 'E1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('INTERVALTO') }, 'F1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('MEASUREUNIT') }, 'G1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('PRICECALCUNIT') }, 'H1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('PRICE') }, 'I1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('PRICECODE') }, 'D1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('ZONENO') }, 'K1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('TEXT') }, 'L1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('ADDRESS') }, 'M1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('ZIPCODE') }, 'N1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('COUNTRYCODE') }, 'O1');
    this.spreadsheetObj.cellFormat({ textAlign: 'center' }, 'I1:I' + (this.prices.length + 1));
  }

  getPrices() {
    if (this.pricesheet) {
      let pricesheetprice: PricesheetPrice = {
        licenseRef: this.pricesheet.licenseRef!,
        includeCurrency: true,
        includeMeasureunit: true
      }
      this.pricesheetpriceService.getPricesheetPricesView(this.pricesheet.id!, pricesheetprice).then((res: any) => {
        for (let i = 0; i < res.modelObject.length; i++) {
          var colliNames = this.colliTypes.filter(function (element: any) {
            return element.ref == res.modelObject[i].collitypeRef;
          });

          if (colliNames.length > 0) {
            res.modelObject[i].collitypeRef = colliNames[0].name;
          }
          res.modelObject[i].currency = this.checkCurrency(res.modelObject[i].currency);
          res.modelObject[i].product = this.checkProduct(res.modelObject[i].product);
          res.modelObject[i].measureUnit = this.checkTypeName(res.modelObject[i].measureUnit, this.measureUnits);
          res.modelObject[i].priceCalcUnit = this.checkTypeName(res.modelObject[i].priceCalcUnit, this.priceCalcUnits);
        }
        this.data.setPrices(res.modelObject);
      });
    }
  }

  checkProduct(prod: any): any {
    if (prod) {
      for (let i = 0; i < this.products.length; i++) {
        if (prod.id == this.products[i].id) {
          return this.products[i].name;
        }
      }
    }
  }


  checkTypeName(object: any, array: any) {
    if (object) {
      for (let i = 0; i < array.length; i++) {
        if (object.name == array[i].name) {
          return array[i].name;
        }
      }
    }
  }


  checkCurrency(curr: any) {
    for (let i = 0; i < this.currencies.length; i++) {
      if (curr.isocode == this.currencies[i].isocode) {
        return this.currencies[i].isocode;
      }
    }
  }

  created() {
    this.setHeaderRow();
  }

  getAllCurrencies() {
    this.currencyRequest.licenseRef = this.data.LicenseRef;
    this.currencyService.getCurrencies(this.currencyRequest).then((res) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getAllPriceCalcUnit() {
    let priceCalcUnit: PriceCalcUnit = {
      licenseRef: this.data.LicenseRef,
      name: " "
    };
    this.priceCalcUnitService.getAll(priceCalcUnit).then((res) => {
      this.priceCalcUnits = res.modelObject;
    });
  }


  getAllProducts() {
    const product: Product = {
      name: "",
      erpCode: "",
      licenseRef: this.data.LicenseRef,
      fk_productUnitID: 0,
      includeProductPrices: true,
      includeProductUnit: true
    };
    this.productService.getAll(product).then((res: any) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        this.products.push({
          id: res.modelObject[i].id,
          name: res.modelObject[i].name,
          erpCode: res.modelObject[i].erpCode
        });
      }
    });
  }

  ngOnChanges(chagnes: SimpleChanges) {
    this.getPrices();
  }
}
