<div class="outerStyle">
    <h2 style="margin:0px;" class="header">{{'OIL' | translate}}</h2>
    <div class="mat-elevation-z10 table-responsive" style="border-radius: 10px;">
        <table mat-table [dataSource]="oilGroups">
            <ng-container matColumnDef="name">
                <th class="centText" mat-header-cell *matHeaderCellDef>
                    {{'OILGROUPNAME'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let oilGroup">
                    {{oilGroup.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="procent">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'PROCENT'|translate}} %
                </th>
                <td class="centText" mat-cell *matCellDef="let oilGroup">
                    <input placeholder="Procent" title="procent" type="number" matInput [(ngModel)]="oilGroup!.procent">
                </td>
            </ng-container>
            <ng-container matColumnDef="erpCode">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'ERPCODE'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let oilGroup">
                    <input placeholder="ERP Kode" type="text" matInput [(ngModel)]="oilGroup!.erpCode">
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'DATE'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let oilGroup">
                    <input [(ngModel)]="oilGroup.date" matInput type="date" />
                </td>
            </ng-container>
            <tr style="background-color:var(--primary-blue);" mat-header-row
                *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
            <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
        </table>
    </div>
    <button mat-raised-button (click)="updateClick()" class="btn">{{'SAVE' | translate}}
        <mat-icon style="font-size:21px;">refresh</mat-icon>
    </button>
    <button mat-raised-button (click)="createNew()" class="btn">{{'CREATENEW' | translate}}
        <mat-icon style="font-size:21px;">add</mat-icon>
    </button>
</div>
<div fxLayout="row" style="width:80%; margin:1%;">
    <div style="border-radius: 6px;" class="mat-elevation-z20 table-responsive">
        <table style="width:100%;" mat-table [dataSource]="oilSheetsDataSource">
            <!-- OIL GROUP -->
            <ng-container matColumnDef="oilGroupName">
                <th class="centText" mat-header-cell *matHeaderCellDef>
                    {{'OILGROUPNAME'|translate}}</th>
                <td class="noselect" class="centText" mat-cell *matCellDef="let oilSheet">
                    <span *ngIf="oilSheet.oilsheetgroup">{{oilSheet.oilsheetgroup.name}}</span>
                    <span *ngIf="!oilSheet.oilsheetgroup">{{'NOTHINGCHOSEN' | translate}}</span>
                </td>
            </ng-container>             
            <!-- CUSTOMER NUMBER-->
             <ng-container matColumnDef="customerNumber">
                <th style="text-align: left !important;" mat-header-cell *matHeaderCellDef>
                    {{'CUSTOMERNUMBER'|translate}}</th>
                <td style="text-align: left !important;" mat-cell *matCellDef="let oilSheet">
                    {{oilSheet?.customer?.customerNr}}
                </td>
            </ng-container>

            <!-- CUSTOMER NAME-->
            <ng-container matColumnDef="customerName">
                <th class="centText" mat-header-cell *matHeaderCellDef>
                    {{'CUSTOMERNAME'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let oilSheet">
                    {{oilSheet?.customer?.name}}
                </td>
            </ng-container>
  
            <!-- DATE -->
            <ng-container matColumnDef="date">
                <th class="centText" mat-header-cell *matHeaderCellDef>
                    {{'DATE'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let oilSheet">
                    {{oilSheet.date | date: 'dd-MM-yyyy'}}

                </td>
            </ng-container>
            <!-- PROCENT ↓ -->
            <ng-container matColumnDef="procent">
                <th class="centText" mat-header-cell *matHeaderCellDef>
                    {{'OILTARRIFPROCENT'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let oilSheet">
                    {{oilSheet.procent | number: '2.2-2'}}
                </td>

            </ng-container>
            <!-- ERP CODE ↓ -->
            <ng-container matColumnDef="erpCode">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'ERPCODE'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let oilSheet">
                    <span *ngIf="oilSheet.oilsheetgroup">{{oilSheet.oilsheetgroup.erpCode}}</span>
                </td>
            </ng-container>
            <tr style="background-color:var(--primary-blue);" mat-header-row
                *matHeaderRowDef="displayedColumnsOilSheet; sticky:true;"></tr>
            <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumnsOilSheet; let i = index;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[20, 35, 50]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
    </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>