import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CapacityTaxService } from 'src/app/shared/api/capacity-tax.service';
import { ColliTypeService } from 'src/app/shared/api/collitype.service';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { CapacityTax } from 'src/app/shared/Models/capacityTax';
import { ColliType } from 'src/app/shared/Models/collitype';
import { Customer } from 'src/app/shared/Models/customer';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewCapacityTaxDialog } from './new-capacity-tax-dialog/new-capacity-tax.dialog';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-capacity-tax',
  templateUrl: './capacity-tax.component.html',
  styleUrls: ['./capacity-tax.component.scss']
})
export class CapacityTaxComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ["customer", "date", "procent", "colliType", "erpCode", "action"];
  capacityTaxes: CapacityTax[] = [];
  capacityTaxesFiltered: CapacityTax[] = [];
  customers: Customer[] = [];
  colliTypes: ColliType[] = [];
  loading: any;
  capaTaxesDataSource!: MatTableDataSource<CapacityTax>;
  filterValue: string = "";
  updateFilter = new Subject<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private capacityTaxService: CapacityTaxService,
    private datePipe: DatePipe,
    private collitypeService: ColliTypeService,
    private data: DataShareService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private customerService: CustomerService) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getCustomers();
    this.getAllCapacityTaxes();
    this.getAllColliTypes();
    this.updateFilter.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe(value=>{
      this.capacityTaxesFiltered = this.capacityTaxes.filter((element) => {
        if (value == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      this.capaTaxesDataSource = new MatTableDataSource(this.capacityTaxesFiltered);
      this.capaTaxesDataSource.paginator = this.paginator;
    });
  }

  sortData(sort: Sort) {
    const data = this.capacityTaxesFiltered.slice();
    if (!sort.active || sort.direction === '') {
      this.capacityTaxesFiltered = data;
      return;
    }

    this.capacityTaxesFiltered = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'customer':
          let customerA = this.customers.find(element=> element.id == a.fk_customerId);
          let customerB = this.customers.find(element=> element.id == b.fk_customerId);
          return this.compare(customerA!.name, customerB!.name, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'percent':
          return this.compare(a.procent, b.procent, isAsc);
        default:
          return 0;
      }
    });
    this.capaTaxesDataSource = new MatTableDataSource(this.capacityTaxesFiltered);
    this.capaTaxesDataSource.paginator = this.paginator;
  }  

  compare(a: any, b: any, isAsc: boolean) {
    if(a==b)return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getAllCapacityTaxes() {
    this.loading = true;
    this.capacityTaxService.getAll({
      licenseRef: this.data.LicenseRef,
      procent: 0,
      erpCode: ""
    }).then((res) => {
      if (res) {
        if (res.modelObject) {
          this.capacityTaxes = res.modelObject;
          for (let i = 0; i < this.capacityTaxes.length; i++) {
            if (this.capacityTaxes[i].date) {
              this.capacityTaxes[i].date = this.datePipe.transform(this.capacityTaxes[i].date, 'yyyy-MM-dd')!;
            }
          }
          this.capacityTaxesFiltered = this.capacityTaxes.filter((element) => {
            if (this.filterValue == "") return true;
            if (this.customers.length == 0) return true;
            let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
            if (!customer) return true;
            return customer.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
          });
          // this.capacityTaxes.unshift({ licenseRef: this.data.License });
          this.capaTaxesDataSource = new MatTableDataSource(this.capacityTaxes);
          this.capaTaxesDataSource.paginator! = this.paginator;
        }
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  checkData(capacityTax: any) {
    if (capacityTax.id > 0) {
      capacityTax.editable = true;
    }
  }

  compareByOptionRef(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst == refSecond;
  }

  newCapacityTax() {
    const dialogRef = this.dialog.open(NewCapacityTaxDialog, { disableClose: true });

    dialogRef.afterClosed().subscribe((capacityTax: any) => {
      if (capacityTax) {
        capacityTax.licenseRef = this.data.LicenseRef;
        this.capacityTaxService.saveOrUpdate(capacityTax).then((res: any) => {
          if (res) {

          }
        }).finally(() => {
          this._snackBar.open("Kapacitets Tillæg gemt", "Success"), { panelClass: ['snack-bar'] };
          this.getAllCapacityTaxes();
        });
      }
    });
  }

  updateCapacityTax(capacityTax: CapacityTax) {
    this.loading = true;
    if (capacityTax) {
      if (!capacityTax.licenseRef) {
        capacityTax.licenseRef = this.data.LicenseRef;
      }
      if (capacityTax.collitypeRef) {
        capacityTax.collitypeRef = this.colliTypes.find(x => x.ref == capacityTax.collitypeRef)?.ref;
      }
      if (capacityTax.date) {
        capacityTax.date = this.datePipe.transform(capacityTax.date, 'yyyy-MM-dd')!;
      }
      this.capacityTaxService.saveOrUpdate(capacityTax).then((res: any) => {
        if (res) {
          this.getAllCapacityTaxes();
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  getCustomers(): void {
    this.loading = true;
    let customer: Customer;
    customer = {
      licenseRef: this.data.LicenseRef,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.customers = this.customers.filter((x: any) => x.name);
      this.customers.unshift({ name: 'Ingen valgt' });
      this.capacityTaxesFiltered = this.capacityTaxes.filter((element) => {
        if (this.filterValue == "") return true;
        if (this.customers.length == 0) return true;
        let customer = this.customers.find((custElement) => custElement.id == element.fk_customerId);
        if (!customer) return true;
        return customer.name!.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0;
      });
      this.capaTaxesDataSource = new MatTableDataSource(this.capacityTaxesFiltered);
      this.capaTaxesDataSource.paginator = this.paginator;
    }).finally(() => {
      this.loading = false;
    });
  }

  deleteCapacityTax(capacityTax: CapacityTax) {
    this.loading = true;
    if (capacityTax) {
      if (!capacityTax.licenseRef) {
        capacityTax.licenseRef = this.data.LicenseRef;
      }
      this.capacityTaxService.delete(capacityTax).then((res: any) => {
        if (res) {
          this.getAllCapacityTaxes();
        }
      }).finally(() => {
        this.loading = false;
        this._snackBar.open("Kapacitets Tillæg slettet", "Success", { panelClass: ['snack-bar'] });
      });
    }
  }

  getAllColliTypes() {
    let col: ColliType = {
      licenseRef: this.data.LicenseRef,
      name: ' '
    };
    this.collitypeService.getAllColliTypes(col).then((res: any) => {
      this.colliTypes = res.modelObject;
    });
  }

  compareByOptionId(idFirst: any, idSecond: any) {
    return idFirst && idSecond && idFirst.id == idSecond.id;
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate 
        message: "Du er ved at gemme :  <b>Alle Kapaictets Tillæg</b>" // Translate
      }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.capaTaxesDataSource.data.length; i++) {
          if (!this.capaTaxesDataSource.data[i].fk_customerId) {
            this.capaTaxesDataSource.data.splice(i, 1);
          }
          else {
            if (!this.capaTaxesDataSource.data[i].id) {

              this.updateCapacityTax(this.capaTaxesDataSource.data[i]);
            }
          }
        }
        this.capacityTaxService.bulkSaveOrUpdate(this.capaTaxesDataSource.data).then((res: any) => {
          if (res) {
            this.getAllCapacityTaxes();
          }
        }).finally(() => {
          this.loading = false;
          this._snackBar.open("Alle Kapacitets Tillæg Gemt", "Success", { panelClass: ['snack-bar'] });
        });
      }
    });
  }

}
