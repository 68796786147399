<div style="width: 100%; height: calc(100% - 70px); margin-left: 16px;">
    <div style="width: 100%; height: 100%;">
        <!-- Toolbar -->
        <div style="width: 100%; height: 48px; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 8px;">
            <div>
                <span style="font-size: 24px;">{{'CUSTOMERSWITHPRICESHEETS' | translate}}</span>
            </div>
            <div style="width: 240px;">

            </div>
            <div style="position: relative;">
                <fieldset>
                    <legend style="margin-bottom: -8px !important;">{{'FILTER'|translate}}</legend>
                    <input class="inputstyle" type="text" [(ngModel)]="clientShare.filterValue" (ngModelChange)="filterChanged()">
                    <mat-icon *ngIf="clientShare.filterValue" (click)="clearFilter()" style="position: absolute; top: 20px; left: 164px; font-size: 16px; cursor: pointer;" 
                        matSuffix>close
                    </mat-icon>
                </fieldset>
            </div>
            <div style="width: 24px;">

            </div>
            <div style="display: flex; flex-direction: row; gap: 8px;">
                <label>{{'SHOWTAGED' | translate}}</label>
                <input type="checkbox" [(ngModel)]="showTaged" (change)="clickShowTaged()">
            </div>
            <div style="width: 24px;">

            </div>
            <div style="width: 200px;">
                <fieldset style="width: 180px;">
                    <legend  style="margin-bottom: -8px !important;">{{'ROUNDINGUP' | translate}}</legend>
                    <select style="width: 100%;" [(ngModel)]="selectedRoundTypeValue">
                        <option *ngFor="let type of roundTypes" [value]="type.value">
                            {{type.text}}
                        </option>
                    </select>
                </fieldset>
            </div>
            <div style="width: 240px;"></div>
            <div>
                <button mat-raised-button style="background-color: var(--primary-blue); color: white;" (click)="clickPostAdjust()" [disabled]="getDisabledStatus()" [ngStyle]="{'cursor' : getDisabledStatus() ? 'not-allowed' : 'pointer'}">
                    <span>{{'BEGINADJUSTMENT' | translate}}</span>
                </button>
            </div>
        </div>
        <div style="width: 100%; height: 32px; display: flex; flex-direction: row; gap: 16px; font-weight: 700; align-items: center;">
            <div style="width: 100px; cursor: pointer;" (click)="clickSort('number')">{{'NUMBER' | translate}}</div>
            <div style="width: 280px; cursor: pointer;" (click)="clickSort('name')">{{'NAME' | translate}}</div>
            <div style="width: 280px; cursor: pointer;" (click)="clickSort('nickname')">{{'NICKNAME' | translate}}</div>
            <div style="width: 100px; cursor: pointer;" (click)="clickSort('taxnumber')">{{'TAXNUMBER' | translate}}</div>
            <div style="width: 180px; cursor: pointer;" (click)="clickSort('address')">{{'ADDRESS' | translate}}</div>
            <div style="width: 60px; cursor: pointer;" (click)="clickSort('zipcode')">{{'ZIPCODESHORT' | translate}}</div>
            <div style="width: 120px; cursor: pointer;" (click)="clickSort('city')">{{'CITY' | translate}}</div>
            <div style="width: 100px; cursor: pointer;" (click)="clickSort('country')">{{'COUNTRY' | translate}}</div>
            <div style="width: 100px; cursor: pointer;" (click)="clickSort('phone')">{{'PHONE' | translate}}</div>
            <div style="width: 140px; display: flex; flex-direction: row; align-items: center; gap: 8px;">
                <label>{{'PRICEADJUST' | translate}}</label>
                <input type="text" style="width: 60px; text-align: right;" [(ngModel)]="priceAdjust">
                <mat-icon *ngIf="!priceAdjust" matSuffix (click)="clickAdjust()" style="cursor: pointer;">play_for_work</mat-icon>
                <mat-icon *ngIf="priceAdjust" class="pulseElement"  matSuffix (click)="clickAdjust()" style="cursor: pointer;">play_for_work</mat-icon>
            </div>
            <div style="width: 190px; display: flex; flex-direction: row; align-items: center; gap: 8px">
                <label>{{'PRDATE' | translate}}</label>
                <input type="date" style="width: 100px; text-align: right;" [(ngModel)]="adjustDate">
                <mat-icon *ngIf="!adjustDate" matSuffix (click)="clickAdjustDate()"  style="cursor: pointer;">play_for_work</mat-icon>
                <mat-icon *ngIf="adjustDate" class="pulseElement" matSuffix (click)="clickAdjustDate()"  style="cursor: pointer;">play_for_work</mat-icon>
            </div>
        </div>
        <div style="width: 100%; height: calc(100% - 48px - 48px); overflow-y: auto;">
            <div style="width: 100%; height: 32px; cursor: pointer; display: flex; flex-direction: row; gap: 16px; align-items: center;" *ngFor="let customer of clientShare.filteredClients; let i = index;" [ngStyle]="{'background-color':  i % 2 == 0 ? '#cecece' : 'Transparent' }">
                <div style="width: 100px;">{{customer.number}}</div>
                <div style="width: 280px; white-space: nowrap; overflow: hidden;">{{customer.name}}</div>
                <div style="width: 280px; white-space: nowrap; overflow: hidden;">{{customer.nickname}}</div>
                <div style="width: 100px;">{{customer.taxNumber}}</div>
                <div style="width: 180px;">{{customer.address}}</div>
                <div style="width: 60px;">{{customer.zip}}</div>
                <div style="width: 120px;">{{customer.city}}</div>
                <div style="width: 100px;">{{customer.country}}</div>
                <div style="width: 100px;">{{customer.phone}}</div>
                <div style="width: 140px;">
                    <input type="text" [(ngModel)]="customer.priceAdjust" style="width: 60px; text-align: right">
                </div>
                <div style="width: 160px;">
                    <input type="text" [(ngModel)]="customer.adjustDate" style="width: 72px; text-align: right">
                </div>
            </div>
        </div>
    </div>
</div>
<app-mat-spinner-overlay *ngIf="dataShare.showSpinner === true" [overlay]="true">    
</app-mat-spinner-overlay>
