import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { License } from "../Models/license";

@Injectable({
    providedIn: 'root',
})
export class LicenseService {
    constructor(
        private api: ApiService
    ) {}

    async get(license: License){
        return await this.api.post("/license/get", license).toPromise();
    }
    async getAll() {
        let dummy: License = {licenseRef: '00000000-0000-0000-0000-000000000000'};
        return await this.api.post("/license/getall", dummy).toPromise();
    }

    
}