<!-- Translate Dialog -->
<h1 mat-dialog-title class="header">
    {{'SETTINGS' | translate}} {{'FOR' | translate}} {{selectedCustomer!.name}}</h1>
<mat-dialog-content fxLayout="column">
    <mat-tab-group>
        <mat-tab label="{{'OILSETUP' | translate}}">
            <div style="display:flex; flex-direction: column; margin-left:1%; height:50vh;">
                <div style="display:flex; flex-direction: row; align-items: center; justify-content: center;">
                    <mat-form-field *ngIf="selectedCustomer" style="margin-top:1%; margin-left:1%; width:20vw;"
                        appearance="outline">
                        <mat-label>{{'OILGROUP' | translate}}</mat-label>
                        <mat-select [(value)]="selectedOilGroup">
                            <mat-option *ngFor="let os of oilGroups" [value]="os" (click)="selectOilGroup(os)">
                                {{os.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    style="display: flex; flex-direction: row; align-items: flex-start; justify-content: center; margin-bottom:15px;">
                    <div style="display:flex; flex-direction: column;">
                        <div style="border-radius: 10px;" class="mat-elevation-z2 table-responsive">
                            <table [hidden]="oilSheets.length <= 0" mat-table [dataSource]="oilSheets">
                                <!-- PROCENT ↓ -->
                                <ng-container matColumnDef="procent">
                                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>
                                        {{'PROCENT'|translate}}</th>
                                    <td style="text-align: center !important;" mat-cell *matCellDef="let oilSheet">
                                        <div *ngIf="oilSheet.editable">
                                            <input placeholder="Procent" type="number" matInput
                                                [(ngModel)]="oilSheet.procent">
                                        </div>
                                        <div *ngIf="!oilSheet.editable" style="width:159px;">
                                            <span>{{oilSheet.procent}}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                <!-- ERPCODE ↓ -->
                                <ng-container matColumnDef="erpCode">
                                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>
                                        {{'ERPCODE'|translate}}</th>
                                    <td style="text-align: center !important;" mat-cell *matCellDef="let oilSheet">
                                        <div *ngIf="oilSheet.editable">
                                            <input placeholder="Erp Kode" type="number" matInput
                                                [(ngModel)]="oilSheet.erpCode">
                                        </div>
                                        <div *ngIf="!oilSheet.editable" style="width:159px;">
                                            <span>{{oilSheet.erpCode}}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                <!-- DATE ↓ -->
                                <ng-container matColumnDef="date">
                                    <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>
                                        {{'DATE'|translate}}</th>
                                    <td style="text-align: center !important;" mat-cell *matCellDef="let oilSheet">
                                        <div *ngIf="oilSheet.editable">
                                            <input style="width:100px;" matInput [matDatepicker]="picker"
                                                placeholder="Dato" [(ngModel)]="oilSheet.date">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </div>
                                        <div *ngIf="!oilSheet.editable" style="width:159px;">
                                            <span>{{oilSheet.date | date: 'dd-MM-yyyy'}}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr style="background-color:var(--primary-blue);" mat-header-row
                                    *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
                                <tr class="table-row" mat-row
                                    *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div
                    style="display:flex; align-items: flex-end; justify-content: flex-end; margin-right:8%; padding-bottom: 4px;">
                    <button (click)="saveOilTab()"
                        style="background-color: var(--primary-blue); color:white; border-radius: 5px;"
                        mat-raised-button>{{'SAVE' | translate}}</button>
                </div>
            </div>
        </mat-tab>
        <mat-tab style="display:flex;" label="Prisark Opsætning">
            <div style="display:flex;">
                <!-- Row -->
                <div style="display:flex; flex-direction: row; flex-wrap:wrap; width:100%; min-height:400px;">
                    <!-- Standard Pricesheets here... -->
                    <div style="display:flex; flex-direction: column; flex-basis: 100%; flex: 1;">
                        <div *ngIf="selectedCustomer">
                            <div style="display:flex; justify-content: center; margin-top:2%;">
                                <span style="font-size:x-large; font-weight: 450;">Standard Ark</span>
                            </div>
                            <div *ngFor="let pricesheet of pricesheets" class="showPricesheet">
                                <button mat-raised-button (click)="togglePricesheet(pricesheet)"
                                    matTooltip="Prisarket kan ikke slettes/fjernes her"
                                    [matTooltipDisabled]="!pricesheet.checked"
                                    class="pricesheetBtn">{{pricesheet.name}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Customer Pricesheets here -->
                    <div style="display:flex; flex-direction: column; flex-basis: 100%; flex: 1;">
                        <div style="display:flex; justify-content: center; margin-top:2%;">
                            <span style="font-size:x-large; font-weight: 450;">Tilknyttet Kunden</span>
                        </div>
                        <div *ngIf="selectedCustomer" cdkDropList (cdkDropListDropped)="dropCust($event)">
                            <div *ngFor="let custSheet of customerPricesheets" cdkDrag class="showPricesheet">
                                <button mat-raised-button (click)="toggleCustomerPricesheet(custSheet)"
                                    class="pricesheetBtn">{{custSheet.pricesheet?.name}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Save Pricesheets Btn-->
            <div style="display:flex; flex-direction: row; justify-content: center;">
                <button mat-raised-button
                    style="background-color: var(--primary-blue); color:white; border-radius: 5px; width:200px; margin-bottom:1%;"
                    (click)="saveCustomerPricesheets()">{{'SAVE' | translate}}</button>
            </div>
        </mat-tab>
        <!-- Return Calc Method-->
        <mat-tab style="display:flex; justify-content: center;" label="Retur Beregning">
            <div style="display:flex; flex-direction: column; height:50vh;">
                <mat-form-field class="formField">
                    <mat-label style="color:black;">{{'REUTRNZONE'|translate}} </mat-label>
                    <input matInput
                        [disabled]="(calcPercentReturn != null && calcPercentReturn != '') || (calcPercentOutside != null && calcPercentOutside != '')"
                        [(ngModel)]="calcZone">
                </mat-form-field>
                <mat-form-field class="formField">
                    <mat-label style="color:black;">{{'REUTRNPERCENT'|translate}} </mat-label>
                    <input [disabled]="calcZone != null && calcZone != ''" matInput [(ngModel)]="calcPercentReturn">
                </mat-form-field>
                <mat-form-field class="formField">
                    <mat-label style="color:black;">{{'REUTRNPERCENTCUSTOMERTOCUSTOMER'|translate}} </mat-label>
                    <input [disabled]="calcZone != null && calcZone != ''" matInput [(ngModel)]="calcPercentOutside">
                </mat-form-field>
                <div class="saveBtn">
                    <button (click)="saveCustomerCalcRules()" mat-raised-button>{{'SAVE' | translate}}</button>
                </div>
            </div>

        </mat-tab>
        <mat-tab class="flexCenter" label="GodsType">
            <div>
                <app-colli-type-translate [selectedCustomer]="selectedCustomer"></app-colli-type-translate>
            </div>
        </mat-tab>
        <mat-tab class="flexCenter" label="GodsType Mål">
            <div>
                <app-colli-measurement [selectedCustomer]="selectedCustomer"></app-colli-measurement>
            </div>
        </mat-tab>
        <mat-tab class="flexCenter" label="Kunde opsætning">
            <div>
                <app-client-settings [selectedCustomer]="selectedCustomer"></app-client-settings>
            </div>
        </mat-tab>
        <mat-tab style="display:flex; justify-content: center;" label="Omregningsregeler">
            <div style="display:flex; flex-direction: column;height: 50vh;">
                <app-customerconversionrules [selectedCustomer]="selectedCustomer"></app-customerconversionrules>
            </div>
        </mat-tab>        
    </mat-tab-group>

    <mat-divider></mat-divider>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button style="visibility: hidden;">{{'CLOSE' | translate}}</button>
    <button mat-raised-button class="closeBtn" (click)="closeCustomerSettings()">Luk</button>
</mat-dialog-actions>
